import React, { useState, useRef ,useEffect} from "react";
import { useFormContext } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import WarningIcon from '@mui/icons-material/Warning';
import ListSubheader from '@mui/material/ListSubheader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

function Parent({ provinceData, expectationOption, birthdate, setBirthdate}) {
    const inputRef = useRef();
    const { register, formState: { errors } } = useFormContext();
    const [province, setProvince] = useState('');
    const [expectation, setExpectation] = useState('');
    const [anotherExpectation, setAnotherExpectation] = useState('');
    const [parentJob, setParentJob] = useState('');
    const [anotherJob, setAnotherJob] = useState('');
    const [initial, setInitial] = useState(false)
    const [TimestampPayload, setTimestampPayload] = useState(null)

    function getDate12YearsAgo() {
      const currentDate = new Date();
      const year = currentDate.getFullYear() - 12;
      const month = currentDate.getMonth(); 
      const day = currentDate.getDate();
      const twelveYearsAgo = new Date(year, month, day);
      const formattedDate = `${twelveYearsAgo.getFullYear()}-${String(twelveYearsAgo.getMonth() + 1).padStart(2, '0')}-${String(twelveYearsAgo.getDate()).padStart(2, '0')}`;
      return formattedDate;
    }

    const handleDateChange = (date) => {
      const convertToSQLDatetime = (date) => {
            if (!date) return null;
            const localDate = new Date(date.toISOString());
            const utcDate = new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000));
            setTimestampPayload(utcDate);
            return utcDate.toISOString();
        };
      const convertedDate = convertToSQLDatetime(date); 
      setBirthdate(convertedDate); 
    };

    useEffect(() => {
      document.getElementById("email").addEventListener("change", function() {
      setInitial(true)
      });
    }, []);

    return (
      <>
        <TextField
          {...register("fname", {
            required: { value: true, message: "โปรดกรอกชื่อของคุณ" },
          })}
          id="fname"
          label="ชื่อ"
          variant="outlined"
          size="small"
        />

        {errors.fname ? (
          <Typography
            wrap="true"
            sx={{
              fontSize: ".9rem",
              color: "red",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <WarningIcon sx={{ fontSize: "1rem" }} /> {errors.fname.message}
          </Typography>
        ) : null}

        <TextField
          {...register("lname", {
            required: "โปรดกรอกนามสกุลของคุณ",
          })}
          id="lname"
          label="นามสกุล"
          variant="outlined"
          size="small"
        />

        {errors.lname ? (
          <Typography
            wrap="true"
            sx={{
              fontSize: ".9rem",
              color: "red",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <WarningIcon sx={{ fontSize: "1rem" }} /> {errors.lname.message}
          </Typography>
        ) : null}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="วัน/เดือน/ปี ค.ศ.เกิด"
            onChange={(date) => handleDateChange(date)}
            dateFormat="yyyy-MM-dd"
            maxDate={dayjs(new Date(getDate12YearsAgo()))}
            selected={birthdate}
            name="birthdate"
            variant="outlined"
            size="small"
            sx={{
              "& .MuiInputBase-root": {
                padding: 0,
                "& .MuiButtonBase-root": {
                  padding: "1rem",
                },
                "& .MuiInputBase-input": {
                  padding: "8.5px 14px",
                  paddingLeft: 2,
                },
              },
            }}
          />
          <input value={birthdate} type="hidden" {...register("birthdate")} />
          {!birthdate & initial ? (
            <Typography
              wrap="true"
              sx={{
                fontSize: ".9rem",
                color: "red",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <WarningIcon sx={{ fontSize: "1rem" }} />{" "}
              {"โปรดกรอกวัน เดือน ปีเกิด"}
            </Typography>
          ) : null}
        </LocalizationProvider>

        <TextField
          {...register("email", {
            required: "โปรดกรอกอีเมลของคุณ",
          })}
          type="email"
          id="email"
          name="email"
          label="อีเมล"
          variant="outlined"
          size="small"
        />

        {errors.email ? (
          <Typography
            wrap="true"
            sx={{
              fontSize: ".9rem",
              color: "red",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <WarningIcon sx={{ fontSize: "1rem" }} /> {errors.email.message}
          </Typography>
        ) : null}

        <FormControl>
          <InputLabel>อาชีพ</InputLabel>
          <Select
            {...register("parentJob", {
              required: "โปรดเลือกอาชีพของคุณ",
            })}
            id="parentJob"
            label="อาชีพ"
            size="small"
            value={parentJob}
            onChange={(e) => {
              setParentJob(e.target.value);
            }}
          >
            <MenuItem value="พนักงานในหน่วยงานราชการ">พนักงานในหน่วยงานราชการ</MenuItem>
            <MenuItem value="ลูกจ้างในหน่วยงานราชการ">ลูกจ้างในหน่วยงานราชการ</MenuItem>
            <MenuItem value="พนักงานเอกชน">พนักงานเอกชน</MenuItem>
            <MenuItem value="ธุรกิจส่วนตัว">ธุรกิจส่วนตัว</MenuItem>
            <MenuItem value="ค้าขาย">ค้าขาย</MenuItem>
            <MenuItem value="อาชีพอิสระ">อาชีพอิสระ</MenuItem>
            <MenuItem value="รับราชการ">รับราชการ</MenuItem>
            <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
          </Select>
        </FormControl>

        {parentJob === "อื่นๆ" ? (
          <TextField
            {...register("parentJob", {
              required: "โปรดเลือกอาชีพของคุณ",
            })}
            id="parentJob"
            label="อาชีพ"
            variant="outlined"
            value={anotherJob}
            onChange={(e) => {
              setAnotherJob(e.target.value);
            }}
            size="small"
          />
        ) : null}

        {errors.parentJob ? (
          <Typography
            wrap="true"
            sx={{
              fontSize: ".9rem",
              color: "red",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <WarningIcon sx={{ fontSize: "1rem" }} /> {errors.parentJob.message}
          </Typography>
        ) : null}

        {/* <FormControl
            >
                <InputLabel>ระดับชั้นของบุตร</InputLabel>
                <Select
                    {...register('grade',
                        {
                            required: "โปรดเลือกระดับชั้นเรียนของบุตรคุณ"
                        }
                    )}
                    id="grade"
                    label='ระดับชั้นเรียนของบุตร'
                    size='small'
                    value={grade}
                    onChange={(e) => { setGrade(e.target.value) }}

                >
                    <ListSubheader>มัธยมศึกษาตอนปลาย</ListSubheader>
                    <MenuItem value='มัธยมศึกษาปีที่ 6'>มัธยมศึกษาปีที่ 6</MenuItem>
                    <MenuItem value='มัธยมศึกษาปีที่ 5'>มัธยมศึกษาปีที่ 5</MenuItem>
                    <MenuItem value='มัธยมศึกษาปีที่ 4'>มัธยมศึกษาปีที่ 4</MenuItem>
                    <ListSubheader>มัธยมศึกษาตอนต้น</ListSubheader>
                    <MenuItem value='มัธยมศึกษาปีที่ 3'>มัธยมศึกษาปีที่ 3</MenuItem>
                    <MenuItem value='มัธยมศึกษาปีที่ 2'>มัธยมศึกษาปีที่ 2</MenuItem>
                    <MenuItem value='มัธยมศึกษาปีที่ 1'>มัธยมศึกษาปีที่ 1</MenuItem>
                </Select>
            </FormControl> */}

        {/* {
                errors.grade ?
                    <Typography wrap="true" sx={{
                        fontSize: '.9rem',
                        color: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <WarningIcon sx={{ fontSize: '1rem' }} /> {errors.grade.message}
                    </Typography>
                    : null
            } */}

        {/* <TextField
                {...register('school',
                    {
                        required: 'โปรดกรอกชื่อโรงเรียนของบุตรคุณ'
                    })}
                id="school"
                variant="outlined"
                label="โรงเรียนของบุตรคุณ"
                size='small'
            />

            {
                errors.school ?
                    <Typography wrap="true" sx={{
                        fontSize: '.9rem',
                        color: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <WarningIcon sx={{ fontSize: '1rem' }} /> {errors.school.message}
                    </Typography>
                    : null
            } */}

        <FormControl>
          <InputLabel>จังหวัด</InputLabel>
          <Select
            {...register("province", {
              required: "โปรดเลือกจังหวัดที่คุณอยู่",
            })}
            id="province"
            label="จังหวัด"
            size="small"
            value={province}
            onChange={(e) => {
              setProvince(e.target.value);
            }}
          >
            {provinceData.map((val) => (
              <MenuItem value={val.name_th}>{val.name_th}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {errors.province ? (
          <Typography
            wrap="true"
            sx={{
              fontSize: ".9rem",
              color: "red",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <WarningIcon sx={{ fontSize: "1rem" }} /> {errors.province.message}
          </Typography>
        ) : null}

        <FormControl>
          <InputLabel>ความคาดหวัง</InputLabel>
          <Select
            {...register("expectation", {
              required: "โปรดเลือกความคาดหวังของคุณ",
            })}
            id="expectation"
            label="ความคาดหวัง"
            size="small"
            value={expectation}
            onChange={(e) => {
              setExpectation(e.target.value);
            }}
          >
            {expectationOption.map((val, key) => {
              return (
                <MenuItem key={key} value={val.expectation_value}>
                  {val.expectation_value}
                </MenuItem>
              );
            })}
            <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>

          </Select>
        </FormControl>
        {expectation === "อื่นๆ" ? (
          <TextField
            {...register("expectation", {
              required: "โปรดเลือกความคาดหวังของคุณ",
            })}
            id="expectation"
            label="ความคาดหวัง"
            variant="outlined"
            value={anotherExpectation}
            onChange={(e) => {
              setAnotherExpectation(e.target.value);
            }}
            size="small"
          />
        ) : null}

        {errors.expectation ? (
          <Typography
            wrap="true"
            sx={{
              fontSize: ".9rem",
              color: "red",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <WarningIcon sx={{ fontSize: "1rem" }} />{" "}
            {errors.expectation.message}
          </Typography>
        ) : null}
      </>
    );
}

export default Parent