import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Axios from 'axios'
import Appbar from '../components/AppbarRmBottom';
import MainScript from './MainScript';
import { Box } from '@mui/material'
import { useAuthState } from '../context/AuthContext'
import { makeStyles } from '@mui/styles';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";

function TrialTests() {
  const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
  ];
  
  // const { exam_id } = useParams();
  const { exam_slug } = useParams();

  const { user } = useAuthState();
  const [examDetail, setExamDetail] = useState()
  const [myExamList, setMyExamList] = useState([])
  const [announcement, setAnnouncement] = useState()
  const [uniround, setUniround] = useState()
  const [startTrial, setStartTrial] = useState(false);
  const [examSubmit, setExamSubmit] = useState(false);
  const [answers, setAnswers] = useState([])
  const [bluePrint, setBluePrint] = useState([])
  const [examInfo, setExamInfo] = useState([])
  const [examFullScore, setExamFullScore] = useState(0)
  const [examContent, setExamContent] = useState([])
  const [currentQuestions, setCurrentQuestions] = useState(0)
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (exam_slug) {
      Axios.get(`https://testapi.jkorpor.com/getExamTrial/${exam_slug}`
      ).then((res) => {
        setExamDetail(res.data[0])
        console.log(res.data[0]);
        // setUniround([res.data[0].round_id])
        // if cat id !=
      })
    }
  }, [user])

  useEffect(() => {
    if (examDetail) {
      const bluePrint = JSON.parse(examDetail.blueprint);
      setBluePrint(bluePrint);
      const examInfo = JSON.parse(examDetail.exam_info);
      console.log("examInfo", examInfo[0]);
      setExamInfo(examInfo[0]);
      const answers = JSON.parse(examDetail.exam_content).map((question) => ({
        id: question.id,
        choose: "",
        point: 0,
        fullScore: question.point,
        category: question.category,
      }));
      setAnswers(answers);
      console.log("answers", answers);
      if (examFullScore == 0) {
        let examFullScore = 0;
        const examFullScoreSum = answers.map((val) => {
          return (examFullScore += val.fullScore);
        });
        console.log(examFullScore);
        setExamFullScore(examFullScore);
      }
      const examContent = JSON.parse(examDetail.exam_content);
      console.log('examContent',examContent);
      setExamContent(examContent)
      setCurrentQuestions(examContent?.slice(step * 5, (step + 1) * 5));
    }
  }, [examDetail]);

  useEffect(() => {
    setCurrentQuestions(examContent?.slice(step * 5, (step + 1) * 5));
  }, [step]);
  

  if (examDetail) {
    return (
      <>
        <Appbar />
        <Box
          sx={{
            background:
              "linear-gradient(0deg, rgba(239,245,245,1) 0%, rgba(214,228,229,1) 100%)",
            minHeight: "100vh",
          }}
        >
          <MainScript
            user={user}
            examDetail={examDetail}
            examFullScore={examFullScore}
            examContent={examContent}
            currentQuestions={currentQuestions}
            examInfo={examInfo}
            myExamList={myExamList}
            answers={answers}
            setAnswers={setAnswers}
            bluePrint={bluePrint}
            startTrial={startTrial}
            setStartTrial={setStartTrial}
            step={step}
            setStep={setStep}
            examSubmit={examSubmit}
            setExamSubmit={setExamSubmit}
          />
        </Box>
      </>
    );
  }
}

export default TrialTests