import React, { useState, useEffect } from 'react'
import Appbar from '../components/Appbar'
import ProfileSidebar from '../components/profile-components/ProfileSidebar'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import ProfileHomepage from '../components/profile-components/ProfileHomepage'
import ProfileMyExam from '../components/profile-components/ProfileMyExam'
import ProfilePayment from '../components/profile-components/ProfilePayment'
import ProfileSetting from '../components/profile-components/ProfileSetting'
import ProfileAnalysis from '../components/profile-components/ProfileAnalysis'
import ProfileHistory from '../components/profile-components/ProfileHistory'
import DialogDream from '../components/DialogDream'
import { useAuthState } from '../context/AuthContext'
import Footer from '../components/home-components/Footer'
import Cookies from '../components/Cookies'

function Profile() {

    const { user } = useAuthState();

    const [activeContent, setActiveContent] = useState('profile-homepage')
    const [facDream, setFacDream] = useState(false)

    useEffect(() => {
        setActiveContent(localStorage.getItem("ActiveContent"))
        const popup = JSON.parse(localStorage.getItem('popup'))
        const popupGuid = JSON.parse(localStorage.getItem('popupGuid'))
        if (popupGuid === false) {
            setFacDream(popup)
        } 
    }, [])

    function getContent(activeContent) {
        switch (activeContent) {
            case 'profile-homepage':
                return <ProfileHomepage user={user}/>
            case 'profile-myexam':
                return <ProfileMyExam user={user} />
            case 'profile-payment':
                return <ProfilePayment user={user} />
            case 'profile-analysis':
                return <ProfileAnalysis user={user} />
            case 'profile-history':
                return <ProfileHistory user={user} />
            case 'profile-setting':
                return <ProfileSetting user={user} />

            default: <ProfileHomepage user={user} />
        }

    }

    return (
        <>
            <Appbar setActiveContent={setActiveContent} />
            <DialogDream open={facDream} setOpen={setFacDream} />
            {user ?
                <Container className='XY' sx={{padding:0}}>
                <Box>
                    <Stack direction='row' >
                    {/* sx={{ marginX: { xs:0 ,sm:10, md: 20 }}} */}
                        <Box flex={2} sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' }, marginX: { xs: 0, md: 2 }, padding: { xs: 0, md: 2 },background: 'linear-gradient(0deg, rgba(239,245,245,1) 0%, rgba(214,228,229,1) 100%)'}} >
                            <ProfileSidebar user={user} activeContent={activeContent} setActiveContent={setActiveContent} />
                        </Box>
                        <Box flex={10}
                            sx={{
                                background: 'linear-gradient(0deg, rgba(239,245,245,1) 0%, rgba(214,228,229,1) 100%)',
                                minHeight: '100vh'

                            }}>
                            {getContent(activeContent)}
                        </Box>
                    </Stack>
                </Box>
                </Container>

                : null
            }
            <Box sx={{display:{ xs: 'none',  sm: 'none', md: 'block' }}}>
            <Footer />
            </Box>
            <Cookies/>
        </>
    )
}

export default Profile