
import React, { useState, useEffect } from 'react'
// import { Box } from '@mui/system';
import { Modal,Typography ,Button ,Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import DialogDreamFac from '../DialogDream'

export default function SpeechBubbleModal() {
  const [open, setOpen] = useState(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [popupGuid, setPopupGuid] = useState(false)
  const [popupGuidStepTwo, setPopupGuidStepTwo] = useState(false)
  const [facDream, setFacDream] = useState(false)

  useEffect(() => {
    const popup = JSON.parse(localStorage.getItem('popupGuid'))
    setPopupGuid(popup)
}, [])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    localStorage.setItem('popupGuid', false);
    setPopupGuid(false)
    handleOpenStepTwo();
  };

  const handleOpenStepTwo = () => {
    setPopupGuidStepTwo(true);
  };

  const handleCloseStepTwo = () => {
    setPopupGuidStepTwo(false)
    localStorage.setItem('popup', true);
    setFacDream(true)
  };

  function SpeechStyle(item) {
      switch (item) {
          case 'StepOne':
            const styleOne = {
              position: 'absolute',
              top: {xs:'150px',sm:'155px',md:'160px',lg:'165px',xl:'170px'},
              right: {xs:'160px',sm:'255px',md:'340px',lg:'340px',xl:'500px'},
              transform: 'translate(50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius:2,
              boxShadow: 24,
              p: {xs:3,sm:4,md:5},
              width: {xs:`${"250px"}`,sm:`${"300px"}`,md:`${"350px"}`},
              outline: 'none'
              }
              return styleOne
  
          case 'StepTwo':
            const styleTwo = {
              position: 'absolute',
              ...(isMediumScreen
                ? { bottom: {xs:'0px',sm:'0px'} } 
                : { top: {md:'165px',lg:'165px'}}
              ),
              left: {xs:'40%',sm:'40%',md:'355px',lg:'375px',xl:'530px'},
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius:2,
              boxShadow: 24,
              p: {xs:3,sm:4,md:4},
              width: {xs:`${"250px"}`,sm:`${"300px"}`,md:`${"350px"}`},
              outline: 'none'
              }
              return styleTwo
          default:
              return null
      }
  }  
  const arrowStylesUpStepOne = {
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '40px solid white',
    
    ...(isMediumScreen
      ? { transform:'translateX(275px) translateY(-50px)'} 
      : { transform:'translateX(345px) translateY(-60px)', }
    ),
    ...(isSmallScreen
      ? { transform:'translateX(230px) translateY(-50px)'} 
      : null
    ),
    
  };

  const arrowStylesDownStepTwo = {
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '40px solid white',
    
    ...(isMediumScreen
      ? { transform:'translateX(65px) translateY(155px)'} 
      : { transform:'translateX(70px) translateY(110px)', }
    ),
    ...(isSmallScreen
      ? { transform:'translateX(60px) translateY(150px)'} 
      : null
    ),
  };

  const arrowStylesUpStepTwo= {
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '40px solid white',
    ...(isMediumScreen
      ? null 
      : { transform:'translateX(150px) translateY(-60px)', }
    )    
  };

  return (
    <div>
      <DialogDreamFac open={facDream} setOpen={setFacDream} />
      <Modal
        open={popupGuid}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={() => SpeechStyle("StepOne")}>
          <div style={{ position: 'absolute' }}>
            <div style={arrowStylesUpStepOne}></div>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <AutoAwesomeIcon sx={{verticalAlign:"middle",color:"#8bc34a"}}/>  
           {" "} 
            ทางลัด เมนูหลัก
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            เพื่อเข้าสู่หน้า โปรไฟล์ ข้อสอบ แก้ไขโปรไฟล์ และออกจากระบบ
          </Typography>
          <Button
                variant="contained"
                onClick={() => handleClose()}
                sx={{ borderRadius: 3, width: "125px",
                backgroundColor: "#00a0e9",
                color: "#ffffff",
                "&.MuiButtonBase-root.MuiButton-root:hover": {
                  backgroundColor: "#2c3639",
                }, }}
              >
                <Typography sx={{ fontSize: "1rem"}}>เข้าใจแล้ว</Typography>
            </Button>
        </Box>
      </Modal>

      <Modal
        open={popupGuidStepTwo}
        onClose={handleCloseStepTwo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '&:focus': {
            outline: "none",
          }}}
      >
        <Box sx={() => SpeechStyle("StepTwo")}>
          <div style={{ position: 'absolute' }}>
            <div style={isMediumScreen ?arrowStylesDownStepTwo :arrowStylesUpStepTwo}></div>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <AutoAwesomeIcon sx={{verticalAlign:"middle",color:"#ffba00"}}/>  
           {" "} เลือกซื้อข้อสอบ
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ได้จากการคลิก "สมัครสอบ" แล้วกดชำระเงิน
          </Typography>
          <Button
                variant="contained"
                onClick={() => handleCloseStepTwo()}
                sx={{ borderRadius: 3, width: "125px",marginTop:1,
                backgroundColor: "#00a0e9",
                color: "#ffffff",
                "&.MuiButtonBase-root.MuiButton-root:hover": {
                  backgroundColor: "#2c3639",
                },}}
              >
                <Typography sx={{ fontSize: "1rem"}}>เข้าใจแล้ว</Typography>
            </Button>
        </Box>
      </Modal>
    </div>
  );
}