import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Stack, Typography, Box, Tooltip, IconButton} from "@mui/material";
import Axios from "axios";
import { useAuthState } from "../context/AuthContext";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DialogDreamOne from "./DialogDreamOne";
import DialogDreamTwo from "./DialogDreamTwo";
import DialogDreamThree from "./DialogDreamThree";
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default function DialogDream({ open, setOpen }) {
  
  const { user } = useAuthState();

  const [valueUnivOne, setValueUnivOne] = useState('');
  const [valueUnivTwo, setValueUnivTwo] = useState();
  const [valueUnivThree, setValueUnivThree] = useState();


  const [valueFacOne, setValueFacOne] = useState();
  const [valueFacTwo, setValueFacTwo] = useState();
  const [valueFacThree, setValueFacThree] = useState();


  const [valueMajorOne, setValueMajorOne] = useState();
  const [valueMajorTwo, setValueMajorTwo] = useState();
  const [valueMajorThree, setValueMajorThree] = useState();

  const [dialogThree, setDialogThree] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddSelect = () => {
    setDialogThree(true)
  };


  const handleSubmit = () => {
    const accessToken = localStorage.getItem('accessToken')
    Axios.post("https://testapi.jkorpor.com/updateDreamFac", {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
            },
            univ1: valueUnivOne,
            univ2: valueUnivTwo,
            univ3: valueUnivThree,
            fac1: valueFacOne,
            fac2: valueFacTwo,
            fac3: valueFacThree,
            major1: valueMajorOne,
            major2: valueMajorTwo,
            major3: valueMajorThree,
            user_id: user.user_id,
        }).then(() => {
          localStorage.setItem("popup", false);
          handleClose();
    });
  };

  return (
    <>
     {/* open={open} */}
      <Dialog open={false} fullWidth="true" maxWidth="sm">
        <DialogContent sx={{paddingBottom: "0px !important" }}>
          <Typography mb={2} sx={{ fontSize: "1.3rem", textAlign: "center" }}>
            มหาวิทยาลัย คณะ และสาขาในฝัน
          </Typography>

          <Typography mt={2} sx={{ fontSize: "1rem", textAlign: "left" }}>
            อันดับที่ 1
          </Typography>
          <DialogDreamOne
            valueUnivOne={valueUnivOne}
            setValueUnivOne={setValueUnivOne}
            valueFacOne={valueFacOne}
            setValueFacOne={setValueFacOne}
            valueMajorOne={valueMajorOne}
            setValueMajorOne={setValueMajorOne}
          />
          <Typography mt={2} sx={{ fontSize: "1rem", textAlign: "left" }}>
            อันดับที่ 2
          </Typography>
          <DialogDreamTwo
            valueUnivTwo={valueUnivTwo}
            setValueUnivTwo={setValueUnivTwo}
            valueFacTwo={valueFacTwo}
            setValueFacTwo={setValueFacTwo}
            valueMajorTwo={valueMajorTwo}
            setValueMajorTwo={setValueMajorTwo}
          />
          <Box sx={{ display:"flex", justifyContent: "center",mt:"15px"}}>
            <Box sx={{ justifyContent: "center" }} display={dialogThree? "none":"inherit"}>
              <Stack direction="column" alignItems="center">
                <Tooltip title="เพิ่มอันดับที่ 3" display={"flex"}>
                <IconButton
                  color="success"
                  onClick={handleAddSelect}
                  sx={{
                    transition: "all 0.25s ease",
                    "&:hover": {
                      scale: "1.2",
                    },
                    color: "#2c3639",
                    "&.MuiButtonBase-root.MuiButton-root:hover": {
                      backgroundColor: "#2c3639",
                    },
                  }}
                >
                  <AddCircleIcon sx={{ fontSize: "1.8rem" }} />
                </IconButton>
                </Tooltip>
              <Typography sx={{fontSize:"0.8rem"}} display={"flex"}>เพิ่มอันดับที่ 3</Typography>
              </Stack>
            </Box>
          </Box>
          <Box display={dialogThree? "inherit":"none"}>
            <Typography mt={2} sx={{ fontSize: "1rem", textAlign: "left" }}>
              อันดับที่ 3
            </Typography>
            <DialogDreamThree
              valueUnivThree={valueUnivThree}
              setValueUnivThree={setValueUnivThree}
              valueFacThree={valueFacThree}
              setValueFacThree={setValueFacThree}
              valueMajorThree={valueMajorThree}
              setValueMajorThree={setValueMajorThree}
            />
          </Box>

        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button onClick={handleClose}>ภายหลัง</Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmit}
            sx={{
              borderRadius: 2,
              backgroundColor: "#00a0e9",
              color: "#ffffff",
              "&.MuiButtonBase-root.MuiButton-root:hover": {
                backgroundColor: "#2c3639",
              },
            }}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
