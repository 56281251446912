
import React, { useState, useEffect } from 'react'
// import { Box } from '@mui/system';
import { Modal,Typography ,Button ,Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DialogDreamFac from '../DialogDream'
import lineQR from '../../asset/addline.png';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { color } from 'framer-motion';
import Axios from "axios";


export default function LineModalPaid({openLine, user}) {
  const [open, setOpen] = useState(openLine);

  const handleClose = () => {
    const accessToken = localStorage.getItem('accessToken')
      Axios.post("https://testapi.jkorpor.com/updateLinePaid", {
      headers: {
        "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      },
      user_id: user.user_id,
      }).then((res) => {
            // console.log('updateLinePaid',res.data);
      })
    setOpen(false)
  };
  
  useEffect(() => {
    setOpen(openLine)
  }, [openLine])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'300px', sm:'320px', md:'450px', lg:'500px'},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius:"20px"
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} textAlign={"center"}>
          <Box display="flex" justifyContent={"flex-end"} marginTop={"-1rem"} 
          >
            <Box >
              <IconButton onClick={() => handleClose()}  sx={{ "&:hover": { color: "#a35055",backgroundColor:"#ffe2e4"} }} >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="h6">รับข้อมูลข่าวสาร ก.พ. และ ท้องถิ่น</Typography>
          <Typography sx={{ fontSize: "1rem" }}>
            เฉพาะสมาชิกที่ผ่านการชำระเงิน
          </Typography>

          <Box>
            <Box
              component="img"
              src={lineQR}
              width="250px"
              sx={{ borderRadius: "10px" }}
              // href={`https://line.me/`}
            />
          </Box>
          <Button
            variant="contained"
            // href={`https://lin.ee/MuRBD8q`}
            sx={{
              borderRadius: 3,
              width: "125px",
              backgroundColor: "#00a0e9",
              color: "#ffffff",
              "&.MuiButtonBase-root.MuiButton-root:hover": {
                backgroundColor: "#2c3639",
              },
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>คลิก</Typography>
          </Button>
        </Box>
      </Modal>
    </div>
  );
}