
import React, { useState, useEffect } from 'react'
// import { Box } from '@mui/system';
import { Modal,Typography ,Button ,Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DialogDreamFac from '../DialogDream'
import lineQR from '../../asset/addline.png';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { color } from 'framer-motion';

export default function LineModal() {
  const [open, setOpen] = useState(true);
  const [popupLineGuid, setPopupLineGuid] = useState(false)

  useEffect(() => {
    const popup = JSON.parse(localStorage.getItem('popupLineGuid'))
    setPopupLineGuid(popup)
}, [])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    localStorage.setItem('popupLineGuid', false);
    setPopupLineGuid(false)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'300px', sm:'320px', md:'450px', lg:'500px'},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius:"20px"
  };


  return (
    <div>
      {/* <Modal
        open={popupLineGuid}
        onClose={handleClose}
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <AutoAwesomeIcon sx={{verticalAlign:"middle",color:"#8bc34a"}}/>  
           {" "} 
            ทางลัด เมนูหลัก
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            เพื่อเข้าสู่หน้า โปรไฟล์ ข้อสอบ แก้ไขโปรไฟล์ และออกจากระบบ
          </Typography>
          <Button
                variant="contained"
                onClick={() => handleClose()}
                sx={{ borderRadius: 3, width: "125px" }}
              >
                <Typography sx={{ fontSize: "1rem"}}>เข้าใจแล้ว</Typography>
            </Button>
        </Box>
      </Modal> */}
      <Modal
        open={popupLineGuid}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} textAlign={"center"}>
          <Box display="flex" justifyContent={"flex-end"} marginTop={"-1rem"} 
          >
            <Box >
              <IconButton onClick={() => handleClose()}  sx={{ "&:hover": { color: "#a35055",backgroundColor:"#ffe2e4"} }} >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="h6">รับข้อมูลข่าวสาร และทดลองสอบฟรี</Typography>
          <Typography sx={{ fontSize: "1rem" }}>
             การสอบ ก.พ. และ ท้องถิ่น
          </Typography>

          <Box>
            <Box
              component="img"
              // src={lineQR}
              width="250px"
              sx={{ borderRadius: "10px" }}
              // href={`https://line.me/`}
            />
          </Box>
          <Button
            variant="contained"
            // href={`https://lin.ee/MuRBD8q`}
            sx={{
              borderRadius: 3,
              width: "125px",
              backgroundColor: "#00a0e9",
              color: "#ffffff",
              "&.MuiButtonBase-root.MuiButton-root:hover": {
                backgroundColor: "#2c3639",
              },
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>คลิก</Typography>
          </Button>
        </Box>
      </Modal>
    </div>
  );
}