import React from 'react'
import HomeBanner from '../components/home-components/HomeBanner'
import Appbar from '../components/Appbar'
import HomeContent from '../components/home-components/HomeContent'
import Footer from '../components/home-components/Footer'
import Cookies from '../components/Cookies'
import Box from '@mui/material/Box'

function Home() {
  return (
    <>
      <Appbar />
      <HomeBanner />
      <HomeContent/>
      <Box sx={{display:{ xs: 'none',  sm: 'none', md: 'block' }}}>
            <Footer />
        </Box>
      <Cookies/>
    </>
  )
}

export default Home