import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {
  CardActionArea,
  CardActions,
  Typography,
  Box,
  Button,
  Stack
} from '@mui/material'
import Axios from 'axios';
import { Link } from '@mui/material';
import { motion } from 'framer-motion'
import CampaignIcon from '@mui/icons-material/Campaign';
import Nevigatorguide from "./Navigatorguide";

function ProfileMyExam({ user }) {

  const [myExamList, setMyExamList] = useState([])

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    Axios.post("https://testapi.jkorpor.com/getUserExams", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      },
      user_id: user.user_id,
    }).then((res) => {
      setMyExamList(res.data);
    });
  }, [])

  return (
    <>
      <Box m={2}>
        <Typography sx={{ display: 'inline', fontSize: '1.5rem', borderBottom: '4px solid #ffba00', }}>
          ข้อสอบของฉัน
        </Typography>
      </Box>

      {/* <Box p={{ xs: 1, md: 2 }} m={{ xs: 1, md: 2 }} sx={{ borderRadius: 3, bgcolor: '#d6e8d4', boxShadow: 1 ,display: myExamList?.length === 0 ? 'none' : 'block',}}>
        <Box>
        <Stack direction="row" alignItems="center" gap={1} sx={{color:"#8BC34A"}}>
          <Typography sx={{fontSize:"1.2rem", fontWeight:"800"}}>ประกาศ</Typography>
          <CampaignIcon sx={{color:"#ff9800"}}/>
        </Stack>
        {announcement?.map((announce, key) => {
            return (
            <Typography key={key} sx={{ textAlign: 'left',marginLeft:'1rem',color:"#2c3639" }}>
                  {announce.text_announcement}
            </Typography>
            )
        })}
        </Box>
      </Box> */}

      <Nevigatorguide user={user} />

      <Box p={{ xs: 1, md: 2 }} m={{ xs: 1, md: 2 }} sx={{ borderRadius: 3, bgcolor: 'white', boxShadow: 1 }}>
        <Box sx={{
          display: myExamList?.length === 0 ? 'block' : 'none',
        }}>

          <Typography sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
            ยังไม่มีข้อสอบ
          </Typography>

          {/* <Typography sx={{ textAlign: 'center' }}>
            <Typography
              component={Link}
              href='/cart'>สมัครสอบ
            </Typography>
          </Typography> */}

        </Box>

        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: 1, md: 2 },
          marginBottom: '80px',
          marginTop: 2,
          justifyContent:"center"
        }}
          component={motion.div}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {myExamList?.map((val, key) => {
            return (
              <Card sx={{
                width: { xs: 150, md: 175, }, //ExamSize
                minHeight :"215px",
                borderRadius: 7
              }} key={key}>
                <CardActionArea href={`/introduction/${val.exam_slug}`}>
                  <CardMedia
                    component="img"
                    height="50px"
                    // height="150px" ExamSize
                    image={val.pic}
                    alt={val.name}
                  />
                  <CardContent sx={{paddingY:"0.3rem"}}>
                    <Typography noWrap sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {val.name}
                    </Typography>
                    <Typography color="text.secondary"
                      sx={{
                        height: '30px',  //ExamSize
                        fontSize: '.85rem'
                      }}>
                      {val.detail}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions sx={{ display: 'flex', justifyContent: 'center', paddingTop: "45px"}}>
                  <Stack spacing={1}>
                    <Button
                      variant='contained'
                      color='warning'
                      sx={{ borderRadius: 3}}
                      href={`/introduction/${val.exam_slug}`}
                    >
                      ทำข้อสอบ
                    </Button>
                  </Stack>
                </CardActions>
              </Card>
            )
          })}
        </Box>
      </Box>
    </>
  )
}

export default ProfileMyExam