import React, { useState, useEffect ,useRef} from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Axios from "axios";
import { Typography, Box, Stack, Button ,Tooltip} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function ProfilePictureList({ setNewPic, newPic}) {
  const [open, setOpen] = useState(false); 
  const [pictureList, setPictureList] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [newImage, setNewImage] = useState('');
  const [originalFileName, setOriginalFileName] = useState(""); 
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    Axios.get("https://testapi.jkorpor.com/getProfilePicture").then((res) => {
      setPictureList(res.data);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const onChange = (e) => {
    setOpen(false);
    setOpenUpload(true);
  
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const selectedFile = files[0];
    
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        alert("รองรับไฟล์ภาพขนาดสูงสุด 5 MB ");
        e.target.value = null;
        window.location.reload();
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setOriginalFileName(selectedFile.name);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const onCropperInit = (cropper) => {
    cropperRef.current = cropper;
  };

  const uploadCroppedImage = async () => {
    if (cropperRef.current) {
      setTimeout(async () => {
        const canvas = cropperRef.current.getCroppedCanvas();
        if (canvas) {
          canvas.toBlob(async (blob) => {
            if (blob) {
              const croppedDataURL = URL.createObjectURL(blob);
              setCropData(croppedDataURL);
              const formData = new FormData();
              formData.append("file", blob, originalFileName);

              try {
                const response = await Axios.post(
                  "https://testapi.jkorpor.com/uploadProfile",
                  formData
                );
                console.log(response.data);
                // setNewImage({ ...newImage, image_src: response.data.image_src });
                setNewPic(response.data.image_src)
                setOpenUpload(false);
              } catch (error) {
                console.error("Error uploading image: ", error);
              }
            }
          }, "image/webp");
        }
      }, 100);
    }
  };

  return (
    <>
      {image ? null : (
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          เลือกรูปโปรไฟล์
        </Button>
      )}
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: "1.5rem", textAlign: "center" }}>
            เลือกรูปโปรไฟล์
          </Typography>
        </DialogTitle>
        <DialogContent>
          <ImageList cols={3} m={2}>
            {pictureList?.map((val, key) => {
              return (
                <ImageListItem key={key}>
                  <Button>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={val.picture_path}
                        sx={{
                          width: "75px",
                          height: "75px",
                          borderRadius: 2,
                        }}
                        onClick={() => {
                          setNewPic(val.picture_path);
                        }}
                      />
                      <CheckCircleIcon
                        sx={{
                          color: "#ffba00",
                          display:
                            newPic === val.picture_path ? "flex" : "none",
                        }}
                      />
                    </Box>
                  </Button>
                </ImageListItem>
              );
            })}
            <ImageListItem>
              <Button>
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                  ref={fileInputRef}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={openFileInput}
                >
                  <Tooltip title="อัพโหลดภาพ">
                    <AddToPhotosIcon
                      sx={{
                        color: "#ffba00",
                        width: "50px",
                        height: "50px",
                        padding: "20px",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Button>
            </ImageListItem>
          </ImageList>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button onClick={handleClose} sx={{ fontSize: "1.1rem" }}>
            กลับ
          </Button>
          <Button onClick={handleClose} sx={{ fontSize: "1.1rem" }}>
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpload}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleCloseUpload}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: "1.5rem", textAlign: "center" }}>
            แก้ไขรูปโปรไฟล์
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box style={{ width: "100%" }}>
            {/* {image ? null : (
            <Button variant="contained" component="label">
              <ImageIcon /> เลือกรูปภาพ
              <input hidden type="file" accept="image/*" onChange={onChange} />
            </Button>
          )} */}
            {image && (
              <Cropper
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                zoomTo={0}
                aspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
                onInitialized={onCropperInit}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            onClick={handleCloseUpload}
            sx={{
              borderRadius: 2,
              backgroundColor: "#00a0e9",
              color: "#ffffff",
              "&.MuiButtonBase-root.MuiButton-root:hover": {
                backgroundColor: "#2c3639",
              },
            }}
          >
            กลับ
          </Button>
          {/* <Button onClick={handleCloseUpload} sx={{ fontSize: "1.1rem" }}>
            ยืนยัน
          </Button> */}
          {image ? (
            <Button
              variant="contained"
              onClick={() => uploadCroppedImage()}
              sx={{
                borderRadius: 2,
                backgroundColor: "#00a0e9",
                color: "#ffffff",
                "&.MuiButtonBase-root.MuiButton-root:hover": {
                  backgroundColor: "#2c3639",
                },
              }}
            >
              {"บันทึก"}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}
