import React, { useState } from 'react'
import Box from '@mui/material/Box'
import './HomeFlickity.css'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button'
import KORPORFlickity from './KORPORFlickity'
import LOCALFlickity from './LOCALFlickity'


function HomeFlickity() {

    const [category, setCategory] = useState('ก.พ.');

    function SwitchCategory(category) {
        switch (category) {
            case 'ก.พ.':
                return <KORPORFlickity />
            case 'ท้องถิ่น':
                return <LOCALFlickity />
            default:
                return <KORPORFlickity />

        }
    }

    return (
        <>
            <Stack>
                <Box>
                    <Typography color='white'
                        sx={{ fontSize: '2rem', textAlign: 'center' }}>
                        จำลองการสอบ ก.พ. และ ท้องถิ่น
                    </Typography>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '1em'}} >
                        <ButtonGroup variant="contained" color='secondary'>
                            <Button
                                sx={{
                                    background: category === 'ก.พ.' ? '#ffba00' : '#ffba00'
                                }}
                                onClick={() => { setCategory('ก.พ.') }}>
                                <Typography>ก.พ.</Typography>
                            </Button>
                            <Button sx={{ background: category === 'ท้องถิ่น' ? '#ffba00' : '#ffba00' }}
                                onClick={() => { setCategory('ท้องถิ่น') }}>
                                <Typography>ท้องถิ่น</Typography>
                            </Button>
                        </ButtonGroup>
                    </Box>

                    {SwitchCategory(category)}

                </Box>
            </Stack >
        </>

    )
}

export default HomeFlickity