import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GuardRoutes from "./utils/GuardRoutes";
import Home from "./pages/Home";
import Register from "./pages/Register";
import RegisterCampaign from "./pages/RegisterCampaign";
import Dashboard from "./pages/Dashboard";
import Logout from "./components/Logout";
import Profile from "./pages/Profile";
import Checkout from "./pages/Checkout";
import Message from "./pages/Message";
import ForgotPassword from "./pages/ForgotPassword";
import CartPage from "./pages/CartPage";
import { AuthProvider } from "./context/AuthContext";
import Faq from "./pages/Faq";
import Termandcondition from "./pages/TermAndCondition";
import Exams from "./pages/Exams";
import ExamIntroduction from "./pages/ExamIntroduction";
import Answer from "./pages/Answer";
import TrialTests from "./trial-page/TrialTests"
import TrialPageKORPOR from "./trial-page/TrialPageKORPOR"
import TrialPageLOCAL from "./trial-page/TrialPageLOCAL"
// import CampaignPage from "./campaign-page/CampaignPage";
import ProfileUpload from "./campaign-page/uploadProfile";



const CryptoJS = require("crypto-js");
const EncryptSecret = 'Jknow2023Korpor'

function App() {
  useEffect(() => {
    const cart = localStorage.getItem("cart");
    if (!cart) {
      const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify([]), EncryptSecret).toString();
      localStorage.setItem("cart", ciphertext_cart);
    }
  }, []);


  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<GuardRoutes />}>
            <Route path="/profile" element={<Profile />} exact />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/exam/:exam_slug" element={<Exams />} />
            <Route path="/answer/:exam_slug" element={<Answer />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/trial-tests/:exam_slug" element={<TrialTests />}/>
            <Route path="/trial-korpor" element={<TrialPageKORPOR />}/>
            <Route path="/trial-local" element={<TrialPageLOCAL />}/>

          </Route>

          <Route path="/" element={<Home />} />
          
          <Route
            path="/introduction/:exam_slug"
            element={<ExamIntroduction />}
          />
          
          <Route path="/register" element={<Register />} />
          <Route path="/register-campaign/:campaign" element={<RegisterCampaign />} />
          
          <Route path="/upload-profile" element={<ProfileUpload />} />
          
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/message" element={<Message />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/termandcondition" element={<Termandcondition />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
