import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Axios from 'axios';
import { Box, Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from "chart.js";

import { Radar } from "react-chartjs-2";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
);


const colorSet = ['#39B5E0', '#A31ACB', '#54B435', '#FF6D28', '#FB2576', '#FFFF00', '#28DF99', '#B983FF', '#810034', '#00AF91', '#C9F658']

const options = {
    scales: {
        r: {
            angleLines: {
                display: true
            },
            pointLabels: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 100
        },
    },
    plugins: {
        legend: {
            display: false
        },
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AnalysisDialogTrial({ answers }) {

    // const [allRecord, setAllRecord] = useState([])
    // const [answered, setAnswered] = useState([])
    // const [select, setSelect] = useState(0)

    // const handleChange = (event) => {
    //     setSelect(event.target.value);
    // };

    // useEffect(() => {
    //     const accessToken = localStorage.getItem('accessToken')
    //     Axios.post("https://testapi.jkorpor.com/getAnswered", {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //       },
    //       exam_id: exam_id,
    //       user_id: user_id
    //     }).then((res) => {
    //        setAllRecord(res.data)
    //         if (res.data.length !== 0) {
    //             console.log(res.data);
    //             setAnswered(JSON.parse(res.data[select].answer))
    //         }
    //     });
    // }, [user_id, exam_id, select])

    // const handleCloseAnalysis = () => {
    //     setSelectExamId()
    //     setAnswered([])
    //     setAllRecord([])
    //     setOpenAnalysis(false)
    // };

    // const formatDate = (dateString) => {
    //     const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', hour12: false, minute: '2-digit', second: '2-digit' }
    //     return new Date(dateString).toLocaleDateString(undefined, options)
    // }

    const pointByGroup = answers?.reduce((acc, item) => {
        const exists = acc.find(i => i.category === item.category)
        if (!exists) {
            acc.push({
                category: item.category,
                point: item.point,
                fullScore: item.fullScore,
            });
        } else {
            exists.point += item.point;
            exists.fullScore += item.fullScore;
        }
        return acc;
    }, []);

    const data = {
        labels: pointByGroup?.map(val => val.category),
        datasets: [
            {
                data: pointByGroup?.map(val => (val.point / val.fullScore) * 100),
                backgroundColor: "#ffba0070",
                borderColor: "black",
                borderWidth: 5,
                pointRadius: 7,
                pointBackgroundColor: colorSet,
                pointBorderColor: colorSet
            },
        ],
    };

    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-evenly",
          }}
        >
          <Box
            mt={2}
            sx={{ width: { xs: "200px", md: "300px" }, alignSelf: "center" }}
          >
            <Radar data={data} options={options} />
          </Box>

          <Box mt={2} mb={2}>
            <TableContainer component={Paper} sx={{borderRadius:5}}>
              <Table>
                <TableHead>
                  <TableRow sx={{ background: "#2c3639" }}>
                    <TableCell
                      sx={{ color: "#ffba00", fontSize: "1.1rem", p:1,minWidth:'150px'}}
                      align="center"
                    >
                      หัวข้อ
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#ffba00",
                        fontSize: "1.1rem",
                        width: "100px",
                        p:0
                      }}
                      align="center"
                    >
                      คะแนนเต็ม
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#ffba00",
                        fontSize: "1.1rem",
                        width: "100px",
                        p:0
                      }}
                      align="center"
                    >
                      คะแนนที่ได้
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#ffba00",
                        fontSize: "1.1rem",
                        width: "100px",
                        p:0
                      }}
                      align="center"
                    >
                      ร้อยละ(%)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pointByGroup.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ display: "flex", p:'5px'}}
                      >
                        <CircleIcon
                          sx={{ alignSelf: "center", color: colorSet[key] }}
                        />
                        <Typography
                          sx={{ fontSize: "1rem", color: colorSet[key] }}
                        >
                          {" "}
                          {row.category}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" p={0}>
                        <Typography sx={{ fontSize: "1rem" }}>
                          {row.fullScore}
                        </Typography>
                      </TableCell>
                      <TableCell align="center"  p={0}>
                        <Typography sx={{ fontSize: "1rem" }}>
                          {row.point}
                        </Typography>
                      </TableCell>
                      <TableCell align="center"  p={0}>
                        <Typography sx={{ fontSize: "1rem" }}>
                          {((row.point / row.fullScore) * 100).toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </>
    );
}

export default AnalysisDialogTrial