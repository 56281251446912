import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {
  CardActionArea,
  CardActions,
  Typography,
  Box,
  Button
} from '@mui/material'
import Axios from 'axios'
import { Link } from '@mui/material';
import { motion } from 'framer-motion'
import HistoryDialog from './HistoryDialog';
import Nevigatorguide from "./Navigatorguide";



function ProfileHistory({ user }) {

  const [myExamList, setMyExamList] = useState([])

  const [openHistory, setOpenHistory] = useState(false)
  const [examId, setExamId] = useState()
  const [ProductName, setProductName] = useState()

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
        Axios.post("https://testapi.jkorpor.com/getUserExams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          },
          user_id: user.user_id,
        }).then((res) => {
          setMyExamList(res.data);
        });


  }, [])

  const handleHistory = (exam_id, Product_Name) => {
    setExamId(exam_id)
    setProductName(Product_Name)
    setOpenHistory(true)
  }


  return (
    <>

      <HistoryDialog user_id={user.user_id} exam_id={examId} ProductName={ProductName} setExamId={setExamId} openHistory={openHistory} setOpenHistory={setOpenHistory} />

      <Box m={2}>
        <Typography sx={{ display: 'inline', fontSize: '1.5rem', borderBottom: '4px solid #ffba00', }}>
          ประวัติการทำข้อสอบ
        </Typography>
      </Box>

      <Nevigatorguide user={user} />

      <Box p={{ xs: 1, md: 2 }} m={{ xs: 1, md: 2 }} sx={{ borderRadius: 3, bgcolor: 'white', boxShadow: 1 }}>

        <Box sx={{
          display: myExamList?.length === 0 ? 'block' : 'none',
        }}>
          <Typography sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
            ยังไม่มีประวัติการทำข้อสอบ
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            <Typography
              component={Link}
              href='/profile' onClick={() => localStorage.setItem('ActiveContent', 'profile-myexam')}>
              ทำข้อสอบ
            </Typography>
          </Typography>
        </Box>

        <Box
          sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: 1, md: 2 }, marginBottom: '80px', marginTop: 2,justifyContent:"center" }}
          component={motion.div}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >

          {myExamList?.map((val, key) => {
            return (
              <Card sx={{ width: { xs: 150, md: 175,}, borderRadius: 7, minHeight :"215px"}} key={key} >
                <CardActionArea href={`/introduction/${val.exam_slug}`}>
                  <CardMedia
                    component="img"
                    height="50px"
                    image={val.pic}
                    alt={val.name}
                  />
                  <CardContent sx={{paddingY:"0.3rem"}}>
                    <Typography noWrap sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {val.name}
                    </Typography>
                    <Typography color="text.secondary"
                      sx={{
                        height: '30px',
                        fontSize: '.85rem'
                      }}>
                      {val.detail}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions sx={{ display: 'flex', height: '35px', justifyContent: 'center', paddingTop: "45px"}}>
                  <Button
                    variant='contained'
                    color='warning'
                    sx={{ borderRadius: 3, fontSize: { xs: '.8rem', md: '' }, padding:"0.3rem" }}
                    onClick={() => handleHistory(val.exam_id, val.name)}
                  >
                    ดูประวัติการทำข้อสอบ
                  </Button>
                </CardActions>
              </Card>
            )
          })}

        </Box>
      </Box>
    </>
  )
}

export default ProfileHistory