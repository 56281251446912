import React, { useState, useRef } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./profileUpload.css";
import ImageIcon from '@mui/icons-material/Image';

import axios from "axios";
const initialState = {
    image_src: '',
}

const ProfileUpload = () => {
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [newImage, setNewImage] = useState('');
  const [originalFileName, setOriginalFileName] = useState(""); 
  const cropperRef = useRef(null);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setOriginalFileName(files[0].name); 
    };
    reader.readAsDataURL(files[0]);
  };

  const onCropperInit = (cropper) => {
    cropperRef.current = cropper;
  };

  const uploadCroppedImage = async () => {
    if (cropperRef.current) {
      setTimeout(async () => {
        const canvas = cropperRef.current.getCroppedCanvas();
        if (canvas) {
          canvas.toBlob(async (blob) => {
            if (blob) {
              const croppedDataURL = URL.createObjectURL(blob);
              setCropData(croppedDataURL);
              const formData = new FormData();
              formData.append("file", blob, originalFileName);

              try {
                const response = await axios.post(
                  "https://testapi.jkorpor.com/uploadProfile",
                  formData
                );
                console.log(response.data);
                setNewImage({ ...newImage, image_src: response.data.image_src });
              } catch (error) {
                console.error("Error uploading image: ", error);
              }
            }
          }, "image/webp");
        }
      }, 100);
    }
  };


  return (
    <Box>
      <Box style={{ width: "100%" }}>
        {image ? null : (
          <Button variant="contained" component="label">
            <ImageIcon /> เลือกรูปภาพ
            <input hidden type="file" accept="image/*" onChange={onChange} />
          </Button>
        )}
        {image && (
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: "100%" }}
            zoomTo={0}
            aspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
            onInitialized={onCropperInit}
          />
        )}
      </Box>
      {image ? (
        // <button style={{ float: "right" }} onClick={uploadCroppedImage}>
        //   Upload
        // </button>
        <Button
          variant="contained"
          size="large"
          onClick={() => uploadCroppedImage()}
          sx={{
            borderRadius: 2,
            backgroundColor: "#00a0e9",
            color: "#ffffff",
            "&.MuiButtonBase-root.MuiButton-root:hover": {
              backgroundColor: "#2c3639",
            },
          }}
        >
          {"บันทึก"}
        </Button>
      ) : null}
      <Box>
        {/* <Box className="box" style={{ width: "50%", float: "right" }}>
          <h1>Preview</h1>
          <Box
            className="img-preview"
            style={{ width: "100%", float: "left", height: "300px" }}
          />
        </Box> */}
        {/* <Box
          className="box"
          style={{ width: "50%", float: "right", height: "300px" }}
        >
          <h1>
            <span>Crop</span>
            <button style={{ float: "right" }} onClick={uploadCroppedImage}>
              Upload and Crop Image
            </button>
          </h1>
          {cropData && (
            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
          )}
        </Box> */}
      </Box>
      <br style={{ clear: "both" }} />
    </Box>
  );
};

export default ProfileUpload;
