import React, { useState, useEffect } from "react";
import RegisterStepCampaign from "../components/register_components/RegisterStepCampaign";
import { useForm, FormProvider } from "react-hook-form";
import Box from "@mui/material/Box";
import ResponsiveAppBarStatic from "../components/Appbarstatic";
import { useParams } from 'react-router-dom';
import Axios from "axios";

function RegisterCampaign() {
  const methods = useForm();
  const { campaign } = useParams();
  const [AuthToken, setAuthToken] = useState(null);
  const [location, setLocation] = useState();

  useEffect(() => {
    let location = ''
    Axios.post("https://testapi.jkorpor.com/getCampaign", {
        campaign: campaign,
      }).then((res) => {
        localStorage.setItem("campaign", res.data[0].location);
        setLocation(res.data[0].location)
      });
  }, []);

  useEffect(() => {
    if (location) {
      const accessToken = localStorage.getItem("accessToken");
    Axios.post("https://testapi.jkorpor.com/authsignin", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      if (res.data.status === "ok") {
        setAuthToken(true);
        window.location.href = `${location}`;
      } else {
        setAuthToken(false);
      }
    });
    }
  }, [location]);




  return (
    <>
      {AuthToken === false ? (
        <>
      <ResponsiveAppBarStatic />
        <Box
          p={2}
          sx={{
            background:
              "linear-gradient(0deg, rgba(239,245,245,1) 0%, rgba(214,228,229,1) 100%)",
            minHeight: "100vh",
          }}
        >
          <FormProvider {...methods}>
            <RegisterStepCampaign method={methods} />
          </FormProvider>
        </Box>
        </>
      ) : null}
    </>
  );
}

export default RegisterCampaign;
