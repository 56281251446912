import React, { useState, useEffect } from 'react'
import Cart from '../components/Cart/Cart'
import Appbar from '../components/Appbar'
import Box from '@mui/material/Box'
import Axios from 'axios'
import { useAuthState } from "../context/AuthContext";


const CryptoJS = require("crypto-js");
const EncryptSecret = 'Jknow2023Korpor'

function CartPage() {
  const { user } = useAuthState();
  const [KORPORPATR1, setKORPORPATR1] = useState([])
  const [KORPORPATR2, setKORPORPATR2] = useState([])
  const [ALEVELR1, setALEVELR1] = useState([])
  const [ALEVELR2, setALEVELR2] = useState([])
  const [LOCALR1, setLOCALR1] = useState([])
  const [LOCALR2, setLOCALR2] = useState([])

  const [myExamList, setMyExamList] = useState([]);
  const [myExamLoading, setMyExamLoading] = useState(false);
  const [visitCounting, setVisitCounting] = useState(false);

  useEffect(() => {
    if (user) {
        const accessToken = localStorage.getItem('accessToken')
        Axios.post("https://testapi.jkorpor.com/getUserExams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          },
          user_id: user.user_id,
        }).then((res) => {
          // console.log('myExamList Res',res.data);
          const MyExamFilter = (res.data).map((item) => item.exam_id);
          setMyExamList(MyExamFilter);
          setMyExamLoading(true)
        });
    }
  }, [user]);

  useEffect(() => {
    if (!visitCounting) {
      console.log('Visit Count');
      const accessToken = localStorage.getItem('accessToken')
        Axios.post("https://testapi.jkorpor.com/addVisitCount", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          },
        }).then((res) => {
          console.log('Res',res.data);
        });
    }
    setVisitCounting(true)
  }, []);




    return (
      <>
        <Appbar 
        />
        <Box sx={{ marginBottom: "100px" ,minHeight:"800px"}}>
          <Cart myExamList={myExamList} 
          myExamLoading={myExamLoading}
          />
        </Box>
      </>
    );
  }
// }

export default CartPage