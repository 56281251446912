import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Stack, Typography } from "@mui/material";
import Axios from "axios";
import { useAuthState } from "../context/AuthContext";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

export default function DialogDreamThree({
  valueUnivThree,setValueUnivThree,valueFacThree,setValueFacThree,valueMajorThree,setValueMajorThree
}) {

  const { user } = useAuthState();
  const [faculty, setFaculty] = useState([]);

  const [dreamUnivOne, setDreamUnivOne] = useState(null);
  const [dreamUnivTwo, setDreamUnivTwo] = useState(null);
  const [dreamUnivThree, setDreamUnivThree] = useState(null);


  const [dreamFacOne, setDreamFacOne] = useState(null);
  const [dreamFacTwo, setDreamFacTwo] = useState(null);
  const [dreamFacThree, setDreamFacThree] = useState(null);


  const [dreamMajorOne, setDreamMajorOne] = useState(null);
  const [dreamMajorTwo, setDreamMajorTwo] = useState(null);
  const [dreamMajorThree, setDreamMajorThree] = useState(null);


  const [majorOne, setMajorOne] = useState([]);
  const [majorTwo, setMajorTwo] = useState([]);
  const [majorThree, setMajorThree] = useState([]);


  const [majorFacOne, setMajorFacOne] = useState(null);
  const [majorFacTwo, setMajorFacTwo] = useState(null);
  const [majorFacThree, setMajorFacThree] = useState(null);


  const [majorUnivOne, setMajorUnivOne] = useState(null);
  const [majorUnivTwo, setMajorUnivTwo] = useState(null);
  const [majorUnivThree, setMajorUnivThree] = useState(null);


  const [univSelect, setUnivSelect] = useState([]);
  const [facSelect, setFacSelect] = useState([]);
  const [departSelect, setDepartSelect] = useState([]);

  const [univSelected, setUnivSelected] = useState("");
  const [facSelected, setFacSelected] = useState("");
  const [departSelected, setDepartSelected] = useState("");

  useEffect(() => {
    Axios.post("https://testapi.jkorpor.com/getUniversity", {}).then((res) => {
      setUnivSelect(res.data);
    });
  }, []);

  useEffect(() => {
    if (univSelected) {
      Axios.post("https://testapi.jkorpor.com/getFaculty", {
        university_id: univSelected,
      }).then((res) => {
        setFacSelect(res.data);
      });
    }
  }, [univSelected]);

  useEffect(() => {
    if (facSelected) {
      Axios.post("https://testapi.jkorpor.com/getDepartment", {
        university_id: univSelected,
        faculty_id: facSelected,
      }).then((res) => {
        setDepartSelect(res.data);
      });
    }
  }, [facSelected]);

  return (
    <>
          <Stack spacing={2} sx={{ pl: 1, borderLeft: "5px solid #ffba00" }}>
            <FormControl sx={{ minWidth: 250 }} size="small">
              <Autocomplete
              size="small"
              fullWidth
              value={dreamUnivThree|| null}
              defaultValue={null}
              onChange={(event, newValue) => {
                setDreamUnivThree(newValue);
                setUnivSelected(newValue.university_id);
                setMajorUnivThree(null);
              }}
              inputValue={valueUnivThree}
              onInputChange={(event, newInputValue) => {
                setValueUnivThree(newInputValue);
              }}
              options={univSelect}
              getOptionLabel={(univSelect) => univSelect.university_name}
              renderInput={(params) => (
                <TextField {...params} label="มหาวิทยาลัยในฝันอันดับที่ 3" />
              )}
            />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
              <Autocomplete
              size="small"
              fullWidth
              value={dreamFacThree}
              defaultValue={null}
              onChange={(event, newValue) => {
                setDreamFacThree(newValue);
                setFacSelected(newValue.faculty_id);
                setMajorFacThree(null);
              }}
              inputValue={valueFacThree}
              onInputChange={(event, newInputValue) => {
                setValueFacThree(newInputValue);
              }}
              options={facSelect}
              getOptionLabel={(facSelect) => facSelect.faculty_name}
              renderInput={(params) => (
                <TextField {...params} label="คณะในฝันอันดับที่ 3" />
              )}
            />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
              <Autocomplete
                size="small"
                fullWidth
                freeSolo
                disableClearable
                value={dreamMajorThree}
                defaultValue={null}
                onChange={(event, newValue) => {
                  setMajorThree(newValue);
                  setDreamMajorThree(newValue);
                }}
                inputValue={valueMajorThree}
                onInputChange={(event, newInputValue) => {
                  setValueMajorThree(newInputValue);
                }}
                options={departSelect}
                getOptionLabel={(departSelect) => departSelect.department_name}
                renderInput={(params) => <TextField {...params} label="สาขาในฝันอันดับที่ 3" />}
              />
            </FormControl>
          </Stack>
    </>
  );
}
