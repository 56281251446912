import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Flickity from 'react-flickity-component'
import './ReviewFlickity.css'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Axios from 'axios'

import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { Divider, Link, Button, CardActions, CardContent, Container } from "@mui/material";
import userreview1 from "../../asset/reviews/userreview1.webp";
import userreview2 from "../../asset/reviews/userreview2.webp";
import userreview3 from "../../asset/reviews/userreview3.webp";
import userreview4 from "../../asset/reviews/userreview4.png";
import userreview5 from "../../asset/reviews/userreview5.webp";
import userreview6 from "../../asset/reviews/userreview6.webp";
import userreview7 from "../../asset/reviews/userreview7.png";
import userreview8 from "../../asset/reviews/userreview8.webp";
import userreview9 from "../../asset/reviews/userreview9.webp";
import userreview10 from "../../asset/reviews/userreview10.webp";



import Stack from "@mui/material/Stack";
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';

function LOCALFlickity() {

    const [loading, setLoading] = useState(true)
    const [LOCALList, setLOCALList] = useState([])
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken')
        Axios.post("https://testapi.jkorpor.com/getLOCAL", {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
            },
        }).then((res) => {
            setLOCALList(res.data)
            setLoading(false)
        });

    }, [])

    const flickityOptions = {
        initialIndex: 2,
        selectedAttraction: 0.01,
        friction: 0.15,
        contain: true,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
    }

    return (
        <Box
        className="container"
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        sx={{
          background:
            "linear-gradient(0deg, rgba(1,83,82,0), rgba(75,75,75,0.05))",
          paddingBottom: 5,
          display: "flex",
          flexDirection: "column",
          px: { xs: "1%", lg: "5%" },
        }}
        position="relative"
      >
        <Container>
        <Typography gutterBottom variant="h5" component="div" paddingTop={5}
        color='#688784'
        fontWeight={"800"}
        >
          การตอบรับจากผู้ใช้งานจริง
        </Typography>
        <Typography gutterBottom variant="p" component="div" padding={1}>
        กว่า 20 ปี ที่สถาบันกวดวิชา เจ โนว์เลจ ได้สร้างฝันให้น้อง ๆ นักเรียนระดับชั้นมัธยมศึกษาตอนปลายมากมาย
        ร่วมสร้างกิจกรรม พัฒนา ขยายโอกาส ยกระดับการเรียนรู้ บูรณาการศาสตร์ในทุกแขนง เพื่อตอบโจทย์ต่อการศึกษาในปัจจุบัน โดยเฉพาะยุคแห่งการพัฒนาอย่างไม่หยุดนิ่งในศตวรรษที่ 21
        </Typography>
        </Container>
        <Stack
          direction="row"
          justifyContent="center"
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "row",
          }}
        >
            <Flickity
            className={'flickity-carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            reloadOnUpdate // default false
            static // default false
                >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview1} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ เป็นการจำลองสนามสอบที่ดีมากครับ ” 
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                        Meenters 
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview2} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ ทำให้รู้ว่าอ่อน-เก่งเรื่องไหน
                      ช่วยลดเวลาอ่านหนังสือได้เยอะ” 
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Jbai jaruwan
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Stack>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview3} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ ข้อสอบไม่ยาก ไม่ง่ายเกินไป ใกล้เคียงกับข้อสอบจริงมาก  ” 
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Iam June
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview4} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ระบบใช้งานง่าย จับเวลาเหมือนจริง ดีมากครับ”
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Fah Daruwan
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Stack>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
            
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview5} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ ข้อสอบตรงตามแนวข้อสอบจริงปีล่าสุดเลย ” 
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Ss kie
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview6} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “สอบกี่ครั้งก็ได้ เห็นพัฒนาการของตนเองชัดเจนมากครับ”
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       ท็อป สุรศักดิ์
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Stack>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview7} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ชอบที่มีรายงานผล กับเฉลยละเอียดค่ะ”
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       นุ่น กาญจนา
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview8} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “สะดวกมาก ทำในโทรศัพท์ หรือไอแพดก็ได้”
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Eaon
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Stack>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview9} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                      “ใช้งานง่ายและสะดวก สุดยอดครับ”
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Cat Person 
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                p={1}
              >
                <Card 
                sx={{ display: "flex", width: { xs: 300, sm: 300, md:300 , lg: 300},justifyContent:"center"}}
                >
                  <Box 
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding:1,
                  }}
                  >
                  <Avatar alt="Remy Sharp" src={userreview10} 
                  sx={{ width: 56, height: 56 }}
                  />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", padding:1}}>
                      <Typography component="div" variant="p">
                        "แพลตฟอร์มนี้ช่วยสร้างความมั่นใจได้มากขึ้นเยอะ"
                      </Typography>
                      <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                      >
                      
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      <StarIcon 
                       sx={{ display: "flex", color: "#ffc107", paddingRight: 1 }}
                      />
                      </Box>
                      <Typography
                        fontSize={'0.8rem'}
                        color="text.secondary"
                        component="div"
                      >
                       Komet K.
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Stack>
          </Box>

          </Flickity>
        </Stack>
      </Box>
    )
}

export default LOCALFlickity