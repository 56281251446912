import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Axios from 'axios'
import Appbar from '../components/Appbar';
import Introduction from '../components/ExamsIntro/Introduction';
import { Box } from '@mui/material'
import { useAuthState } from '../context/AuthContext'

const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";


function ExamIntroduction() {

  // const { exam_id } = useParams();
  const { exam_slug } = useParams();
  const { user } = useAuthState();

  const [examDetail, setExamDetail] = useState()
  const [myExamList, setMyExamList] = useState([])
  const [announcement, setAnnouncement] = useState()
  const [uniround, setUniround] = useState()
  // const [cartItem, setCartItem] = useState(JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('cart'), EncryptSecret).toString(CryptoJS.enc.Utf8)));

  useEffect(() => {
    if (exam_slug) {
      Axios.get(`https://testapi.jkorpor.com/getExam/${exam_slug}`
      ).then((res) => {
        setExamDetail(res.data[0])
        setUniround([res.data[0].round_id])
      })
    }
    if (user) {
        const accessToken = localStorage.getItem('accessToken')
        Axios.post("https://testapi.jkorpor.com/getUserExams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          },
          user_id: user.user_id,
        }).then((res) => {
          setMyExamList(res.data);
        });
    }
    // if (user) {
    //   const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(cartItem), EncryptSecret).toString();
    //   localStorage.setItem("cart", ciphertext_cart)
    //   const accessToken = localStorage.getItem('accessToken')
    //         Axios.post("https://testapi.jkorpor.com/updateCart", {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + accessToken,
    //           },
    //           user_id: user.user_id,
    //           updateCart: JSON.stringify(cartItem)
    //         });
    // }
  // }, [user, cartItem])

  }, [user])

  useEffect(() => {
    if (uniround) {
      Axios.post("https://testapi.jkorpor.com/getRound", {
        headers: {
          "Content-Type": "application/json"
        },
        uniround: uniround,
      }).then((res) => {
        setAnnouncement(res.data)
      });
    }
  }, [uniround]);

  if (examDetail) {
    return (
      <>
        {/* <Appbar cartItem={cartItem} /> */}
        <Appbar />
        <Box sx={{
          background: 'linear-gradient(0deg, rgba(239,245,245,1) 0%, rgba(214,228,229,1) 100%)',
          minHeight: '100vh',
        }}>
          {/* <Introduction user={user} examDetail={examDetail} myExamList={myExamList} cartItem={cartItem} setCartItem={setCartItem} announcement={announcement} /> */}
          <Introduction user={user} examDetail={examDetail} myExamList={myExamList} announcement={announcement} />
          
        </Box>
      </>

    )
  }
}

export default ExamIntroduction