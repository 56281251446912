import React, { useEffect } from 'react'
import { Typography, Box, } from '@mui/material'

function ExamTimeClock({ timeControl, setTimeControl, timeSpend, setTimeSpend, duration, handleExamSubmit }) {

    const timeSpending = new Date((duration - timeSpend) * 1000).toISOString().substring(14, 19)

    useEffect(() => {
        if (timeControl === true) {
            const intervalId = setInterval(() => {
                setTimeSpend(timeSpend + 1);
            }, 1000);

            if (timeSpend === duration) {
                setTimeControl(false);
                handleExamSubmit()
            }
            return () => clearInterval(intervalId);
        }
    }, [timeSpend, duration, setTimeControl, setTimeSpend, timeControl]);

    return (
        <>
            {timeSpend === duration ? <Typography sx={{ fontSize: '1.5rem' }}>
                หมดเวลา
            </Typography> :
                <>
                    <Box 
                    sx={{ 
                    // display: { xs: 'flex', sm: 'flex' }, 
                    position: "fixed",
                    left: '50%',
                    zIndex: '100000',
                    transform: 'translateX(-50%)',
                    zIndex:"10",
                    top: {xs:"47.5px", sm:"47.5px", md:"57.5px", lg:"60px"},
                    marginLeft:'auto',
                    marginRight:'auto',
                    scrollMargin: '90px', 
                    justifyContent:"center",
                    backgroundColor:"#ffb124",
                    borderRadius:"15px",
                    paddingX:"10px",
                    paddingY:'5px'
                }} 
                    id={"ExamTimeClock"} >
                        <Typography sx={{ fontSize: {sm:'1rem', md:'1.1rem', lg:'1.2rem'}, fontWeight:'600',color:'#2c3639'}}>
                            เหลือเวลา {timeSpending} นาที
                        </Typography>
                    </Box>
                </>
            }
        </>
    )
}

export default ExamTimeClock