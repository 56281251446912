// import React from 'react'
// import Box from '@mui/material/Box'
// import Typography from '@mui/material/Typography'

// function Footer() {
//     return (

//         <Box sx={{
//             height: '100px',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             background: 'linear-gradient(180deg, rgba(1,83,82,1) 0%, rgba(0,12,38,1) 100%)',
//             mb: { xs: '60px', md: '0px' }
//         }}>
//             <Typography sx={{ color: '#fff' }}>
//                 Copyright 2023 © J Knowledge Tutor 2023
//             </Typography>
//         </Box>
//     )
// }

// export default Footer

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from "@mui/material/Stack";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from "@mui/material/IconButton";


function Copyright() {
    return (
        <>
        <Typography variant="body2" fontSize={"1.1rem"} color="text.secondary" sx={{ color: 'white', justifyContent: 'center' }}>
            {'Copyright © '}
            <Link color="inherit" href="https://jknowledgetutor.com/">
                Jknowledgetutor
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>

        </>
    );
}

export default function Footer() {
    return (
      <Box
        component="footer"
        sx={{
          py: 2,
          px: 2,
          paddingBottom: { xs: 5, sm: 10 },
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? "#242424" : "#242424",
        }}
        display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
      >
        <Container maxWidth="lg" sx={{ textAlign: "right", color: "white" }}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ mx: { sx: 4, md: 2, lg: 1 },display:'flex', justifyContent: "space-between", width:"100%",alignItems:"flex-end"}} >
              {/* <Stack direction="row"> */}
                <Box flex={9} >
                  <Typography
                    fontSize={"1.1rem"}
                    color="text.secondary"
                    sx={{
                      color: "white",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    บริษัท ดีพร้อมวิทยา จำกัด (J Knowledge)
                  </Typography>
                  <Typography
                    fontSize={"1rem"}
                    color="text.secondary"
                    sx={{
                      color: "white",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    สถาบันวิชาการ เจ โนว์เลจ
                  </Typography>
                  <Typography
                    fontSize={"1rem"}
                    color="text.secondary"
                    sx={{
                      color: "white",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    E-mail: jknowledgetutor@hotmail.com
                  </Typography>
                </Box>
                <Box flex={6}>
                  <IconButton
                    size="small"
                    href="https://www.facebook.com/Jknowledges"
                  >
                    <FacebookIcon sx={{ color: "white" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    href="https://www.instagram.com/jknowledgetutor/"
                  >
                    <InstagramIcon sx={{ color: "white" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    href="https://www.youtube.com/@JknowledgeTutor"
                  >
                    <YouTubeIcon sx={{ color: "white" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    href="https://www.tiktok.com/@j_knowledge"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-tiktok"
                      viewBox="0 0 16 16"
                      color="white"
                    >
                      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                    </svg>
                  </IconButton>
                  <Typography fontSize={"1.1rem"}>
                    J knowledge ติว ติด พิชิตฝัน
                  </Typography>
                  <Copyright fontSize={"1.1rem"} />
                </Box>
              {/* </Stack> */}
            </Box>
          </Stack>
        </Container>
      </Box>
    );
}