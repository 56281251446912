import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import CookieIcon from "@mui/icons-material/Cookie";

const Cookies = () => {
  const [open, setOpen] = React.useState(false); //localStorage.getItem("cookies") === "false"
  const [messageInfo, setMessageInfo] = React.useState();

  const handleClose = (event, reason) => {
    localStorage.setItem("cookies", true);
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const cookies = localStorage.getItem("cookies");
    if (!cookies) {
      localStorage.setItem("cookies", false);
      setOpen(true);
    }
    if (cookies === "false") {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          marginBottom:{xs:7, sm:5, md:0},
          maxWidth: "900px",
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white", //your custom color here
            color: "black",
          },
        }}
        action={
          <React.Fragment>
            <Box>
              <Box>
                <Typography
                  fontSize={"1.2 rem"}
                  display={"inline"}
                  component="div"
                >
                  เว็บไซต์นี้ใช้ Cookie
                </Typography>
                <CookieIcon
                  sx={{ lineHeight: 1.2, color: "#2c3639",marginBottom:"-5px"}}
                  padding={1.5}
                  display={"inline"}
                />
              </Box>
              <Typography fontSize={"0.9rem"} component="div">
                เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ
                และประสบการณ์ที่ดีในการใช้งานเว็บไซต์
              </Typography>
              <Typography
                component={Link}
                href="/termandcondition"
                sx={{ textDecoration: "none" }}
                fontSize={"0.9rem"}
              >
                "นโยบายคุ้มครองข้อมูลส่วนบุคคล"
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: 3,
                backgroundColor: "#ffba00",
                m: 2,
                color:"white"
              }}
              color="secondary"
              size="small"
              onClick={handleClose}
            >
              รับทราบ
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
};
export default Cookies;
