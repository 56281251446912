import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Footer from "../components/home-components/Footer";
import Appbar from "../components/Appbar";

export default function Termandcondition() {
  return (
    <>
      <Appbar />

      <Container maxWidth="lg" sx={{ textAlign: "left", color: "black" }}>
        <Stack direction="row" alignItems="left" justifyContent="left">
          <Box sx={{ mx: { sx: 4, md: 5 }, my: 3, pb:5 }}>
            <Typography
              flex={1}
              variant="h5"
              display="flex"
              justifyContent="center"
              marginBottom={2}
            >
              นโยบายคุ้มครองข้อมูลส่วนบุคคล
            </Typography>
            <Typography
              fontSize={"1.1rem"}
              sx={{
                color: "rgba(0, 0, 0, .9)",
                justifyContent: "left",
                textAlign: "left",
                marginLeft: 2,
              }}
            >
              บริษัท ดีพร้อมวิทยา จำกัด (“บริษัท”)
              ตระหนักถึงความสำคัญและหน้าที่ภายใต้
              พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              โดยให้ความสำคัญในการเคารพสิทธิความเป็นส่วนตัวของลูกค้า
              รวมถึงบุคคลธรรมดาที่ดำเนินการในนามนิติบุคคลซึ่งเป็นเจ้าของข้อมูลส่วนบุคคล
              (ต่อไปนี้เรียกว่า “ท่าน” )
              และมุ่งมั่นเป็นอย่างยิ่งที่จะคุ้มครองข้อมูลส่วนบุคคลของท่านให้มีความปลอดภัย
              และเพื่อให้เกิดความมั่นใจว่าข้อมูลส่วนบุคคลของท่านจะได้รับความคุ้มครองตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลและกฎหมายอื่นที่เกี่ยวข้องกำหนด
              บริษัทจึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น
              เพื่อแจ้งให้ทราบถึงรายละเอียดที่เกี่ยวข้องกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคล
              รวมถึงสิทธิตามกฎหมายในฐานะเจ้าของข้อมูลส่วนบุคคลของท่าน
              โดยมีรายละเอียดดังต่อไปนี้
            </Typography>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                ข้อ 1. ความเป็นส่วนตัวและความปลอดภัยของข้อมูล
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                1.1
                เราให้ความสำคัญกับความเป็นส่วนตัวของข้อมูลส่วนบุคคลที่ผู้ใช้งานให้ไว้กับเรา
                และเราจะใช้มาตรการทางเทคนิคและทางกฎหมายเพื่อรักษาความปลอดภัยของข้อมูลเหล่านี้
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                1.2 เราจะไม่เผยแพร่ ขาย
                หรือแจกจ่ายข้อมูลส่วนบุคคลของผู้ใช้งานให้แก่บุคคลภายนอก
                ยกเว้นกรณีที่มีความจำเป็นเพื่อปฏิบัติตามกฎหมายหรือเพื่อการดำเนินงานของเว็บไซต์นี้เท่านั้น
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                ข้อ 2. วัตถุประสงค์ในการใช้ข้อมูล
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                2.1 เราจะใช้ข้อมูลส่วนบุคคลของผู้ใช้งานเพื่อการดำเนินการแบบทดสอบออนไลน์ และเพื่อการวิเคราะห์และปรับปรุงคุณภาพของบริการของเรา
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                2.2 เราอาจใช้ข้อมูลส่วนบุคคลเพื่อการติดต่อและสื่อสารกับผู้ใช้งาน เช่น การแจ้งเตือนเกี่ยวกับผลการทดสอบหรือข้อมูลที่เกี่ยวข้องกับบริการของเรา
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                ข้อ 3. การเก็บรักษาข้อมูล
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                3.1 เราจะเก็บข้อมูลส่วนบุคคลของผู้ใช้งานเพียงเท่าที่จำเป็นและเหมาะสมในการดำเนินงานของเว็บไซต์
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                3.2 เราจะเก็บข้อมูลส่วนบุคคลของผู้ใช้งานเป็นเวลาไม่เกินระยะเวลาที่จำเป็นตามวัตถุประสงค์ที่ระบุในข้อ 2
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                ข้อ 4. การให้ความยินยอม
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                4.1 เราจะขอความยินยอมจากผู้ใช้งานก่อนเก็บรวบรวมหรือใช้ข้อมูลส่วนบุคคล เว้นแต่ในกรณีที่มีกฎหมายอื่นที่อนุญาตให้เก็บหรือใช้ข้อมูลโดยไม่ต้องขอความยินยอม
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                5. สิทธิของผู้ใช้งาน
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                5.1 ผู้ใช้งานมีสิทธิแก้ไข อัพเดต หรือลบข้อมูลส่วนบุคคลของตนเองในระบบของเรา
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                5.2 ผู้ใช้งานมีสิทธิถอนความยินยอมในการใช้ข้อมูลส่วนบุคคลได้ตลอดเวลา แต่อาจทำให้ไม่สามารถใช้บริการบางส่วนหรือทั้งหมดได้
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                6. คุ้มครองข้อมูลเด็กและเยาวชน
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                6.1 เว้นแต่จะมีการระบุไว้เป็นอย่างอื่น เราไม่ได้เก็บข้อมูลส่วนบุคคลของเด็กอายุต่ำกว่า 13 ปี
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                7. การปฏิบัติตามกฎหมาย
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                7.1 เราจะปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลและข้อกำหนดอื่นที่เกี่ยวข้องในการจัดการข้อมูลส่วนบุคคลของผู้ใช้งาน
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"1.3rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
               8. การแก้ไขนโยบายคุ้มครองข้อมูล
              </Typography>
              <Typography
                fontSize={"1rem"}
                sx={{
                  color: "rgba(0, 0, 0, .9)",
                  justifyContent: "left",
                  textAlign: "left",
                  marginLeft: 2,
                }}
              >
                8.1 เราอาจทำการปรับปรุงหรือเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลเป็นครั้งคราว
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Container>
      <Footer />
    </>
  );
}
