import React from 'react'
import Box from '@mui/material/Box';
import { List, ListItemIcon, ListItemText, ListItemButton, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HistoryIcon from '@mui/icons-material/History';
import Avatar from '@mui/material/Avatar';
import { Link } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

function ProfileSidebar({ user, activeContent, setActiveContent }) {

  const handleSetActiveContent = (activeContent) => {
    setActiveContent(activeContent)
    localStorage.setItem('ActiveContent', activeContent)
  }

  return (
    <>
      {user ? (
        <List>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: "20px",
              width: "230px",
            }}
          >
            <Avatar
              sx={{
                width: "125px",
                height: "125px",
                borderRadius: 50,
                border: 4,
                borderColor: "#ffba00",
                mb: 2,
              }}
              src={user.user_pic}
            />

            <Typography mx={2} sx={{ fontSize: "1.3rem" }}>
              {user.fname} {user.lname}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          <ListItemButton
            sx={{
              borderLeft:
                activeContent === "profile-homepage"
                  ? "5px solid #ffba00"
                  : null,
              background:
                activeContent === "profile-homepage" ? "#ffba0025" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-homepage");
            }}
          >
            <ListItemIcon>
              <HomeIcon
                sx={{
                  color:
                    activeContent === "profile-homepage" ? "#ffba00" : null,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="หน้าแรก" />
          </ListItemButton>

          <ListItemButton
            sx={{
              borderLeft:
                activeContent === "profile-myexam" ? "5px solid #ffba00" : null,
              background:
                activeContent === "profile-myexam" ? "#ffba0025" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-myexam");
            }}
          >
            <ListItemIcon>
              <AutoStoriesIcon
                sx={{
                  color: activeContent === "profile-myexam" ? "#ffba00" : null,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="ข้อสอบของฉัน" />
          </ListItemButton>

          <ListItemButton
            sx={{
              borderLeft:
                activeContent === "profile-analysis"
                  ? "5px solid #ffba00"
                  : null,
              background:
                activeContent === "profile-analysis" ? "#ffba0025" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-analysis");
            }}
          >
            <ListItemIcon>
              <AssessmentIcon
                sx={{
                  color:
                    activeContent === "profile-analysis" ? "#ffba00" : null,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="วิเคราะห์คะแนน" />
          </ListItemButton>

          <ListItemButton
            sx={{
              borderLeft:
                activeContent === "profile-history"
                  ? "5px solid #ffba00"
                  : null,
              background:
                activeContent === "profile-history" ? "#ffba0025" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-history");
            }}
          >
            <ListItemIcon>
              <HistoryIcon
                sx={{
                  color: activeContent === "profile-history" ? "#ffba00" : null,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="ประวัติการทำข้อสอบ" />
          </ListItemButton>

          <Divider sx={{ my: 1 }} />

          <ListItemButton
            sx={{
              borderLeft:
                activeContent === "profile-payment"
                  ? "5px solid #ffba00"
                  : null,
              background:
                activeContent === "profile-payment" ? "#ffba0025" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-payment");
            }}
          >
            <ListItemIcon>
              <CreditCardIcon
                sx={{
                  color: activeContent === "profile-payment" ? "#ffba00" : null,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="การชำระเงิน" />
          </ListItemButton>

          <ListItemButton
            sx={{
              borderLeft:
                activeContent === "profile-setting"
                  ? "5px solid #ffba00"
                  : null,
              background:
                activeContent === "profile-setting" ? "#ffba0025" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-setting");
            }}
          >
            <ListItemIcon>
              <SettingsIcon
                sx={{
                  color: activeContent === "profile-setting" ? "#ffba00" : null,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="แก้ไขโปรไฟล์" />
          </ListItemButton>

          <Divider />

          <ListItemButton component={Link} href="/logout">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItemButton>
        </List>
      ) : null}
    </>
  );
}

export default ProfileSidebar