import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Flickity from "react-flickity-component";
import "./ShopFlickity.css";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { Divider, Link, Button, CardActions, CardContent, Container } from "@mui/material";
import answerkey from "../../asset/answerkey.png";
import analysis from "../../asset/analysis.png";
import testblueprint from "../../asset/testblueprint.png";
import countdowntimer from "../../asset/countdowntimer.png";
import successful from "../../asset/successful.png";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BallotIcon from "@mui/icons-material/Ballot";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ReviewFlickity from "./ReviewFlickity"
import { motion } from "framer-motion";

function HomeContent() {
  const flickityOptions = {
    initialIndex: 1,
    selectedAttraction: 0.01,
    friction: 0.15,
    contain: true,
    pageDots: false,
    autoPlay: true,
    wrapAround: true,
  };

  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;
    const scrollPosition = window.scrollY;

    const newScrollPercentage =
      (scrollPosition / (documentHeight - windowHeight)) * 100;
    setScrollPercentage(newScrollPercentage);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        className="container"
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        sx={{
          background:
            "linear-gradient(0deg, rgba(1,83,82,0), rgba(75,75,75,0.05))",
        }}
      >
         <Container>
        <Box
        component={motion.div}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.8 }}
        
        variants={{
          visible: { y: 0, opacity: 1, scale: 1 },
          hidden:  { y: 0, opacity: 0.5, scale: 0.95 }
        }}
      >
       
        
        <Typography gutterBottom variant="h4" component="div" 
        paddingTop={5} 
        paddingX={2}
        color='#2c6b55'
        fontWeight={"800"}
        >
          สนามจำลองการสอบออนไลน์ 
          {/* <Box component="br" /> */}
          {' '} By J Knowledge
        </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="center"
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "row",
          }}
          alignItems="flex-start"
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8, delay: 0 }}
            
            variants={{
              visible: { y: 0, opacity: 1, scale: 1 },
              hidden:  { y: 0, opacity: 0.5, scale: 0.9 }
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
              paddingBottom={5}
            >
              <Box
                className="content-t"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
              >
                <Box
                  component="img"
                  src={testblueprint}
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    maxWidth: { xs: "250px", sm: "300px", md: "200px" },
                    right: { md: "2%", lg: "10%" },
                  }}
                />
              </Box>
              <Box
                className="content-b"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                padding={2}
              >
                <Typography gutterBottom variant="h5" component="div">
                  โครงสร้างข้อสอบและความยาก-ง่ายข้อสอบ ใกล้เคียงกับข้อสอบจริง 
                </Typography>
                <Typography gutterBottom component="div">
                  ข้อสอบ ออกโดยผู้เชี่ยวชาญ ด้วยประสบการณ์การสอน 
                  และออกข้อสอบมากกว่า 20 ปี
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8, delay: 0.15}}
            
            variants={{
              visible: { y: 0, opacity: 1, scale: 1 },
              hidden:  { y: 0, opacity: 0.5, scale: 0.9 }
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
              paddingBottom={5}
            >
              <Box
                className="content-t"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
              >
                <Box
                  component="img"
                  src={countdowntimer}
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    maxWidth: { xs: "250px", sm: "300px", md: "200px" },
                    right: { md: "2%", lg: "10%" },
                  }}
                />
              </Box>
              <Box
                className="content-b"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                padding={2}
              >
                <Typography gutterBottom variant="h5" component="div">
                  ระบบจับเวลา ส่งคำตอบอัตโนมัติเมื่อหมดเวลา 
                </Typography>
                <Typography gutterBottom component="div">
                  นับถอยหลังแบบ Real-Time และส่งคำตอบอัตโนมัติเมื่อหมดเวลา เวลาตรงตามโครงสร้างข้อสอบจริง ทุกวิชา
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8, delay: 0.3}}
            
            variants={{
              visible: { y: 0, opacity: 1, scale: 1 },
              hidden:  { y: 0, opacity: 0.5, scale: 0.9 }
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
              paddingBottom={5}
            >
              <Box
                className="content-t"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
              >
                <Box
                  component="img"
                  src={answerkey}
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    maxWidth: { xs: "250px", sm: "300px", md: "200px" },
                    right: { md: "2%", lg: "10%" },
                  }}
                />
              </Box>
              <Box
                className="content-b"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                padding={2}
              >
                <Typography gutterBottom variant="h5" component="div">
                  ระบบตรวจคำตอบ แสดงผลสอบพร้อมเฉลย 
                </Typography>
                <Typography gutterBottom component="div">
                  ตรวจคำตอบทันทีหลังส่งข้อสอบ สรุปคะแนนที่ได้ พร้อมแสดงผลการทำข้อสอบ เฉลยรายข้อ และวิดิโอเฉลยอย่างละเอียด
                </Typography>
              </Box>
            </Stack>
          </Box>
            
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={3}
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8, delay: 0.3}}
            
            variants={{
              visible: { y: 0, opacity: 1, scale: 1 },
              hidden:  { y: 0, opacity: 0.5, scale: 0.9 }
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{ xs: "column", sm: "column", md: "column" }}
              paddingBottom={5}
            >
              <Box
                className="content-t"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
              >
                <Box
                  component="img"
                  src={analysis}
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    maxWidth: { xs: "250px", sm: "300px", md: "200px" },
                    right: { md: "2%", lg: "10%" },
                  }}
                />
              </Box>
              <Box
                className="content-b"
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
                padding={2}
              >
                <Typography gutterBottom variant="h5" component="div">
                  การวิเคราะห์ผลคะแนน ลบจุดอ่อน เสริมจุดแข็ง
                </Typography>
                <Typography gutterBottom component="div">
                  ด้วยกราฟเรดาร์ (Radar Chart) แสดงผลคะแนนแยกด้าน รู้จุดอ่อน จุดเด่นของการสอบในแต่ละครั้ง
                </Typography>
              </Box>
            </Stack>
          </Box>
          
        </Stack>
        </Container>
      </Box>

      <Box
        position="relative"
        sx={{
          display: "flex",
          flexDirection: "column",
          px: { xs: "5%", sm: "7%", lg: "15%" },
        }}
      >
        <Container>
        <Stack
          direction="row"
          justifyContent="center"
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "row",
          }}
          paddingBottom={5}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={6}
          >
            <Box className="content-l"
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            // animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { x: 16, opacity: 1, scale: 1 },
              hidden:  { x: 0,opacity: 0.5, scale: 1 }
            }}
            >
              <Box
                component="img"
                src={successful}
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex" },
                  maxWidth: { xs: "250px", sm: "300px", md: "350px" },
                  right: { md: "2%", lg: "10%" },
                  
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            flex={6}
          >
            <Box className="content-r" >
              <Typography gutterBottom 
              variant="h4" 
              component="div"
              color='#688784'
              fontWeight={"800"}
              >
                ช่วยเพิ่มโอกาส…สอบติด
              </Typography>
              <Typography gutterBottom component="div">
                สอบเสมือนจริง เตรียมความพร้อมก่อนลงสนามสอบทดสอบได้ ไม่จำกัดจำนวนครั้ง ในช่วงเวลาที่กำหนด
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                flexDirection={{ xs: "column", sm: "column", md: "column" }}
                paddingBottom={5}
              >
                <Box marginLeft={3}>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"left"}
                  alignItems={"left"}
                  flexDirection={"row"}
                  flex={6}
                  sx={{
                    backgroundColor: "#00968838",
                    borderRadius: 2,
                    padding: 1,
                    margin: 1
                  }}
                  component={motion.div}
                  initial="hidden"
                  whileInView="visible"
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 , delay: 0}}
                  variants={{
                    visible: { y: 16, opacity: 1, scale: 1 },
                    hidden:  { x: -16,opacity: 0.5, scale: 1 }
                  }}
                >
                  <ApartmentIcon
                    sx={{ display: "flex", color: "#000000", paddingRight: 1 }}
                  />
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    sx={{ marginBottom: 0 }}
                  >
                    ช่วยวิเคราะห์ผลคะแนนได้ตรงและแม่นยำ 
                  </Typography>
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"left"}
                  flexDirection={"row"}
                  alignItems={"left"}
                  flex={6}
                  sx={{
                    backgroundColor: "#00968838",
                    borderRadius: 2,
                    padding: 1,
                    margin: 1,
                  }}
                  component={motion.div}
                  initial="hidden"
                  whileInView="visible"
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 , delay: 0.1}}
                  variants={{
                    visible: { y: 16, opacity: 1, scale: 1 },
                    hidden:  { x: -16,opacity: 0.5, scale: 1 }
                  }}
                >
                  <AccessAlarmIcon
                    sx={{ display: "flex", color: "#000000", paddingRight: 1 }}
                  />
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    sx={{ marginBottom: 0 }}
                  >
                    สามารถนำผลสอบ เทียบกับคะแนนสอบปีล่าสุดกับคณะ สาขาที่สนใจ วิเคราะห์แนวโน้มคะแนนและโอกาสสอบติด
                  </Typography>
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"left"}
                  flexDirection={"row"}
                  alignItems={"left"}
                  flex={6}
                  sx={{
                    backgroundColor: "#00968838",
                    borderRadius: 2,
                    padding: 1,
                    margin: 1,
                  }}
                  component={motion.div}
                  initial="hidden"
                  whileInView="visible"
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 , delay: 0.2}}
                  variants={{
                    visible: { y: 16, opacity: 1, scale: 1 },
                    hidden:  { x: -16,opacity: 0.5, scale: 1 }
                  }}
                >
                  <BallotIcon
                    sx={{ display: "flex", color: "#000000", paddingRight: 1 }}
                  />
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    sx={{ marginBottom: 0 }}
                  >
                    วางแผนการเตรียมตัว อ่านหนังสือได้ตรงจุด
                  </Typography>
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"left"}
                  flexDirection={"row"}
                  alignItems={"left"}
                  flex={6}
                  sx={{
                    backgroundColor: "#00968838",
                    borderRadius: 2,
                    padding: 1,
                    margin: 1,
                  }}
                  component={motion.div}
                  initial="hidden"
                  whileInView="visible"
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 , delay: 0.3}}
                  variants={{
                    visible: { y: 16, opacity: 1, scale: 1 },
                    hidden:  { x: -16,opacity: 0.5, scale: 1 }
                  }}
                >
                  <ListAltIcon
                    sx={{ display: "flex", color: "#000000", paddingRight: 1 }}
                  />
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    sx={{ marginBottom: 0 }}
                  >
                    เพิ่มประสบการณ์ เพิ่มความมั่นใจ ในการทำข้อสอบมากยิ่งขึ้น
                  </Typography>
                </Box>
                </Box>

              </Stack>
            </Box>
          </Box>
        </Stack>
        </Container>
      </Box>
      <ReviewFlickity/>
      
      <Box
        className="container"
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        sx={{
          background:
            "linear-gradient(0deg, rgba(1,83,82,0), rgba(75,75,75,0.05))",
          paddingBottom: 10,
          display: "flex",
          flexDirection: "column",
          px: { xs: "5%", sm: "10%", lg: "15%" },
        }}
        position="relative"
      >
        <Container>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          paddingTop={5}
          paddingBottom={2}
          color='#404b4a'
          fontWeight={"800"}
        >
          คำถามที่พบบ่อย FAQ
        </Typography>
        <Box
          justifyContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          sx={{ width: "100%" }}
          p={1}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "66%", flexShrink: 0 }}>
                จำลองการสอบ TCAS ออนไลน์คืออะไร
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                การสอบเสมือนจริงเตรียมความพร้อมก่อนลงสนามสอบ
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#606061" }}>
                ให้น้องๆ ได้ฝึกการสอบเสมือนจริงเตรียมความพร้อมก่อนลงสนามสอบ 
                ด้วยการสอบแบบ Computer-based
                โครงสร้างข้อสอบและความยาก-ง่ายข้อสอบ ใกล้เคียงกับข้อสอบจริง 
                มีระบบจับเวลา ส่งคำตอบอัตโนมัติเมื่อหมดเวลา ตรวจคำตอบ ดูผลสอบพร้อมเฉลยข้อสอบ 
                และการวิเคราะห์ผลคะแนน จุดอ่อน จุดเด่นของผู้สอบ
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          justifyContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          sx={{ width: "100%" }}
          p={1}
        >
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "66%", flexShrink: 0 }}>
                การสมัครสมาชิก
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                สามารถทำได้ง่ายๆ แค่เพียง 4 ขั้นตอน
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ color: "#606061" }}>
              <Typography>
                การสมัครสมาชิกเป็นขั้นตอนเริ่มต้นการใช้งานระบบ
                ซึ่งสามารถทำได้ง่ายๆ แค่เพียง 3 ขั้นตอน
              </Typography>
              <Typography>1.เข้าสู่ Website: เลือก 'สมัครสมาชิก'</Typography>
              <Typography>2.กรอกข้อมูลของคุณ จากนั้นเลือก 'ตกลง'</Typography>
              <Typography>
                3.กรอก หมายเลข OTP 6 หลักที่ได้รับจาก SMS จากนั้นเลือก 'ตกลง'
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          justifyContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          sx={{ width: "100%" }}
          p={1}
        >
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: "66%", flexShrink: 0 }}>
                การชำระเงิน
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                5 วิธีง่ายๆ
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#606061" }}>
                การชำระเงิน มี 5 วิธีง่ายๆ ดังนี้ บัตรเครดิต - บัตรเดบิต (ATM)
                บริการพร้อมเพย์ (Prompt pay) Internet Banking วอลเล็ท(Wallet)
                ชำระเงินผ่าน บิลอื่นๆ
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          justifyContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          sx={{ width: "100%" }}
          p={1}
        >
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: "66%", flexShrink: 0 }}>
                หลังจากที่ชำระเงินแล้ว นานแค่ไหนกว่าจะเข้าทำแบบทดสอบได้
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                ทำข้อสอบได้ ในช่วงเวลาที่กำหนด
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#606061" }}>
                สามารถทำข้อสอบได้ภายในช่วงเวลาที่กำหนด ตามประกาศรอบสอบครั้งที่ 1 หรือ ครั้งที่ 2 ของข้อสอบแต่ละชุด
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          justifyContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          sx={{ width: "100%" }}
          p={1}
        >
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "66%", flexShrink: 0 }}>
                ออกใบเสร็จรับเงิน/ใบกำกับภาษีได้หรือไม่
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>ได้ หลังจากชำระเงินแล้ว</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#606061" }}>
                ใบเสร็จ/ใบกำกับภาษีจะถูกส่งเป็น E-Tax Reciept โดยจะส่งให้ทันที
                ทาง E-Mail หลังจากชำระเงินแล้ว
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          justifyContent={"left"}
          alignItems={"left"}
          textAlign={"left"}
          sx={{ width: "100%" }}
          p={1}
        >
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "66%", flexShrink: 0 }}>
                สิทธิเข้าทำข้อสอบ
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                ตลอดอายุการใช้งาน{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: "#606061" }}>
                สิทธิเข้าทำข้อสอบ 
                สามารถเข้ามาทดสอบกี่ครั้งก็ได้ ในช่วงเวลาที่กำหนด
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      
        </Container>
      </Box>
      
    </>
  );
}

export default HomeContent;
