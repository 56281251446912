import React, { useState, useEffect } from "react";
import RegisterStep from "../components/register_components/RegisterStep";
import { useForm, FormProvider } from "react-hook-form";
import Box from "@mui/material/Box";
import Appbar from "../components/Appbar";
import Axios from "axios";

function Register() {
  const methods = useForm();
  const [AuthToken, setAuthToken] = useState(null);


  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    Axios.post("https://testapi.jkorpor.com/authsignin", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      if (res.data.status === "ok") {
        setAuthToken(true);
        window.location = "/profile";
      } else {
        setAuthToken(false);
      }
    });
  }, []);

  return (
    <>
      <Appbar />
      {AuthToken === false ? (
        <Box
          p={2}
          sx={{
            background:
              "linear-gradient(0deg, rgba(239,245,245,1) 0%, rgba(214,228,229,1) 100%)",
            minHeight: "100vh",
          }}
        >
          <FormProvider {...methods}>
            <RegisterStep method={methods} />
          </FormProvider>
        </Box>
      ) : null}
    </>
  );
}

export default Register;
