import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Axios from "axios";
import { Typography, Box, Stack, Button } from "@mui/material";
// import QRCode from "qrcode.react";
import GuidelineDialog from "./GuidelineDialog";
import { useAuthState } from "../../context/AuthContext";
import CheckoutCartQR from "./CheckoutCartQRCode";
const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";

// const generatePayload = require("promptpay-qr");

function CheckoutCart({ checkOutItem, allDiscount, amount, netAmount }) {
  const { user } = useAuthState();
  const [openGuideline, setOpenGuideline] = useState(true);
  const amountFormat = new Intl.NumberFormat("th").format(amount);
  const netAmountFormat = new Intl.NumberFormat("th").format(netAmount);
  const [selectedItems, setSelectedItems] = useState();



  useEffect(() => {
    const selectedItem = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("selectItem"),
          EncryptSecret
        ).toString(CryptoJS.enc.Utf8)
      );
      setSelectedItems(selectedItem);

  }, [checkOutItem, allDiscount, amount, netAmount]);

  const handleDelete = (itemToDelete) => {
    // const updateItemInCart = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('cart'), EncryptSecret).toString(CryptoJS.enc.Utf8)).filter((item) => !itemToDelete.find(i => i.exam_id === item.exam_id));
    // const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(updateItemInCart), EncryptSecret).toString();
        // localStorage.setItem("cart", ciphertext_cart)

    // const accessToken = localStorage.getItem('accessToken')
    //         Axios.post("https://testapi.jkorpor.com/updateCart", {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + accessToken,
    //           },
    //           user_id: user.user_id,
    //           updateCart: JSON.stringify(updateItemInCart)
    //         });
};


  const PurchaseOrder = () => {
    if (user) {
      const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify([]), EncryptSecret).toString();
      localStorage.setItem("dataSelected", ciphertext_cart);
      localStorage.setItem('ActiveContent', 'profile-payment')
      const accessToken = localStorage.getItem('accessToken')
      Axios.post('https://testapi.jkorpor.com/create-subscript', {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
              },
            user_id: user.user_id,
            exams: selectedItems,
            amount: amount,
            net_amount: netAmount,
        }).then((res) => {
            if (res.data.message == "Success") {
              localStorage.setItem('ActiveContent', 'profile-payment')
              handleDelete(selectedItems)
              localStorage.setItem('selectItem', JSON.stringify([]))
              localStorage.setItem('discount', JSON.stringify([]))

              const reference = ((res.data.reference).split("trxn-"))[1];
              const productDetail = (res.data.productDetail);

              const form = document.createElement('form');
              form.method = 'post';
              form.action = 'https://www.thaiepay.com/epaylink/payment.aspx';
          
              const refnoInput = document.createElement('input');
              refnoInput.type = 'hidden';
              refnoInput.name = 'refno';
              refnoInput.value = reference;
              form.appendChild(refnoInput);
          
              const merchantIdInput = document.createElement('input');
              merchantIdInput.type = 'hidden';
              merchantIdInput.name = 'merchantid';
              merchantIdInput.value = '08342459';
              form.appendChild(merchantIdInput);
          
              const customerEmailInput = document.createElement('input');
              customerEmailInput.type = 'hidden';
              customerEmailInput.name = 'customeremail';
              customerEmailInput.value = user.email;
              form.appendChild(customerEmailInput);
          
              const ccInput = document.createElement('input');
              ccInput.type = 'hidden';
              ccInput.name = 'cc';
              ccInput.value = '00';
              form.appendChild(ccInput);
          
              const productDetailInput = document.createElement('input');
              productDetailInput.type = 'hidden';
              productDetailInput.name = 'productdetail';
              productDetailInput.value = productDetail;
              form.appendChild(productDetailInput);
          
              const totalInput = document.createElement('input');
              totalInput.type = 'hidden';
              totalInput.name = 'total';
              totalInput.value = netAmount;
              form.appendChild(totalInput);
              document.body.appendChild(form);
              form.submit();
            }
        })
    }
    }

  if (checkOutItem && allDiscount) {
    return (
      <>
        <GuidelineDialog
          openGuideline={openGuideline}
          setOpenGuideline={setOpenGuideline}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginX: { md: 20 } }}
        >
          <Box
            sx={{
              mx: 2,
              width: { xs: "800px", md: "900px" },
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            {/* <Typography
              sx={{
                display: { xs: "none", md: "grid" },
                m: 3,
                fontSize: "1.2rem",
              }}
              align="left"
            >
              รายการชำระเงิน
            </Typography> */}
            <TableContainer
              sx={{
                maxWidth: 700,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 3,
              }}
            >
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: "1.2rem" }}>รายการ</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.1rem" }}>
                      ราคา
                    </TableCell>
                    {/* <TableCell colSpan={2} align="right" sx={{ width: "50px" }}></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checkOutItem.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell sx={{ fontSize: "0.9rem", paddingY:"0.5rem" }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "0.9rem" , paddingY:"0.5rem"}}>
                        {row.amount} {"บาท"}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <QRCode
                value={qrCode}
                size={240}
                imageSettings={{
                  src: "logo-on-qrcode.jpeg",
                  height: 50,
                  width: 64,
                  excavate: true,
                }}
              /> */}
                  {/* <TableRow> */}
                  {/* <TableCell rowSpan={4} sx={{ width: { xxs: "", md: "50%" } }}>
                <Box
                  width="100%;"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={LabelPromtpayQR}
                    width="200px"
                    height="70px"
                    sx={{ zIndex: 2, position: "absolute" }}
                  />
                  <Box
                    component="img"
                    src={generatePromptPayQRSrc}
                    width="250px"
                    height="250px"
                    sx={{ zIndex: 1, paddingTop: "50px" }}
                  />
                </Box>
                <Typography
                  sx={{
                    display: { xs: "none", md: "grid" },
                    m: 1,
                    marginTop: "-20px",
                    position: "relative",
                    zIndex: 3,
                    fontSize: "1.2rem",
                  }}
                  align="center"
                >
                  {EMVcoAmountBaht} THB
                </Typography>
                <Typography
                  sx={{
                    display: { xs: "none", md: "grid" },
                    m: 1,
                    marginTop: "-10px",
                    position: "relative",
                    zIndex: 3,
                  }}
                  align="center"
                >
                  บริษัท ดีพร้อมวิทยา จำกัด
                  <br />
                  0-4055-58000-72-1
                </Typography>
              </TableCell> */}
                  <TableRow>
                    <TableCell sx={{ fontSize: "0.9rem" , paddingY:"0.5rem" }}>ราคารวมภาษีมูลค่าเพิ่ม</TableCell>
                    <TableCell align="right" sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        {amountFormat} {"บาท"}
                      </Typography>
                    </TableCell>

                    {/* <TableCell align="right">
                      <Typography sx={{ fontSize: "1.1rem" }}>บาท</Typography>
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontSize: "0.9rem" , paddingY:"0.5rem", paddingLeft:"30px" , color:'#696969'}}>
                      ภาษีมูลค่าเพิ่ม 7.00 %
                    </TableCell>
                    <TableCell align="right" sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "0.9rem" , color:'#696969'}}>
                        {(amount * 7) / 100} {"บาท"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "0.9rem", color:'#F49D1A' }}>
                        โปรโมชั่น{" "}
                        {allDiscount[0].promoDiscount === 0
                          ? ""
                          : `(-${allDiscount[0].promoDiscount}%)`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "0.9rem" , color:'#F49D1A'}}>
                        {`- ${allDiscount[0].discount}`} {"บาท"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="right">
                      <Typography sx={{ fontSize: "1.1rem" }}>บาท</Typography>
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "0.9rem", color:'#ffba00' }}>
                        โค้ดส่วนลด{" "}
                        {allDiscount[0].codeDis === 0
                          ? ""
                          : `(${allDiscount[0].code})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "0.9rem" , color:'#ffba00'}}>
                        {`- ${allDiscount[0].codeDis}`} {"บาท"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="right">
                      <Typography sx={{ fontSize: "1.1rem" }}>บาท</Typography>
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ paddingY:"0.5rem" }}>
                      <Typography sx={{ fontSize: "1rem" }}>
                        ราคาสุทธิ
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ paddingY:"0.5rem" }}>
                      <Typography
                        sx={{
                          fontWeight: 900,
                          fontSize: "1rem",
                          color: "green",
                        }}
                      >
                        {netAmountFormat} {"บาท"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="right">
                      <Typography sx={{ fontSize: "1.3rem" }}>บาท</Typography>
                    </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginX: { md: 20 } }}
        >
          {/* <Box
            sx={{
              m: 2,
              width: { xs: "800px", md: "900px" },
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                display: { md: "grid" },
                m: 3,
                fontSize: "1.2rem",
              }}
              align="left"
            >
              ช่องทางชำระเงินออนไลน์
            </Typography>
            

            <Box
              href="https://www.paysolutions.asia"
              target="_blank"
              rel="www.paysolutions.asia"
              sx={{ textAlign: "center" }}
            >
              <Box
                component="img"
                src={
                  "https://s3-payso-images.s3.ap-southeast-1.amazonaws.com/image-logocode/all-2.png"
                }
                sx={{ maxWidth: { xs: "300px", sm: "400px", md: "600px" } }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                m: 1,
                marginBottom: 2,
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => PurchaseOrder()}
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#00a0e9",
                  color: "#ffffff",
                  "&.MuiButtonBase-root.MuiButton-root:hover": {
                    backgroundColor: "#2c3639",
                  },
                }}
              >
                {"ชำระเงิน"}
              </Button>
            </Box>
          </Box> */}
        </Stack>
      </>
    );
  }
}

export default CheckoutCart;
