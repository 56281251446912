import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Appbar from "../components/Appbar";
import { Box } from "@mui/material";
import AnswerComponent from "../components/Answer/AnswerComponent";
import Axios from "axios";
import { useAuthState, useAuthDispatch } from "../context/AuthContext";
import Calendar from '../asset/calendar.png'
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";

const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";

function Answer() {
    const { exam_slug } = useParams();

    const { user } = useAuthState();
    const dispatch = useAuthDispatch();

    const [loading, setLoading] = useState(false);
    const [exam, setExam] = useState([]);
    const [exam_id, setExamId] = useState();
    const [permissionRound, setPermissionRound] = useState(false);
    const [permissionAnnouncement, setPermissionAnnouncement] = useState("");
     
  const handleClose = () => {
    window.location.href = `/profile`
  };


    useEffect(() => {
        const ciphertext = JSON.parse(localStorage.getItem("users"));
        if (ciphertext) {
            const bytes = CryptoJS.AES.decrypt(ciphertext, EncryptSecret);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            dispatch({
                status: "loggedIn",
                user: decryptedData,
                error: null,
            });
        }
    }, []);

    useEffect(() => {
        if (user) {
            const accessToken = localStorage.getItem('accessToken')
            Axios.post("https://testapi.jkorpor.com/UserExam", {
            headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
            },
            user_id: user.user_id,
            exam_slug: exam_slug,
            }).then((res) => {
                if (res.data.message === "ok") {
                    setLoading(true);
                    setExam(res.data.result);
                    setExamId(res.data.result[0].exam_id);
                    if (res.data.result[0].action === "on") {
                        setPermissionRound(true);
                      } else{
                        setPermissionAnnouncement(res.data.result[0].text_announcement)
                      }
                } else {
                    alert("ไม่มีข้อสอบ");
                }
            });
        }
    }, [user]);

    return (
        <>
            {loading === true ? (
            <>
            {permissionRound === true ? (
                <>
                    <Appbar />
                    <Box
                        sx={{
                            background: "#f9fbe7",
                            minHeight: "100vh",
                        }}
                    >
                        {exam_id ? (
                            <AnswerComponent exam={exam} exam_id={exam_id} user={user} />
                        ) : null}
                    </Box>
                </>
                ) : 
                <>
                    <Appbar />
                    <Dialog open={true} fullWidth={true} maxWidth={"md"} 
                        sx={{
                        "& .MuiDialog-paper": {
                        borderRadius:5,
                        paddingY:0.5
                        }}}
                        >
                        <DialogContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            
                        }}
                        >
                        <Box component="img" src={Calendar} width='200px' />
                        <Typography
                            mt={2}
                            align="center"
                            variant="h5"
                            component="div"
                            paddingTop={2}
                            color='#404b4a'
                            fontWeight={"800"}
                        >
                            {permissionAnnouncement}
                        </Typography>
                        <Typography
                            fontWeight={"500"}
                            sx={{
                            textAlign: "center",
                            fontSize:"1.1rem",
                            // variant:"h5",
                            color: "#404b4a",
                            }}
                        >
                            สามารถเข้าสอบผ่านอุปกรณ์ มือถือ แท็บเล็ต แล็ปท็อป หรือ คอมพิวเตอร์ ได้ทุกที่และทุกเวลา
                        </Typography>
                        </DialogContent>

                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            color="success"
                            sx={{ borderRadius: 3, 
                            width: "125px", 
                            backgroundColor:"#dba80dd9" ,
                            '&:hover': {
                                backgroundColor: "#db760dd9",
                            },
                        }}
                        >
                            <Typography sx={{ fontSize: "1rem" }}>ย้อนกลับ</Typography>
                        </Button>
                        </DialogActions>
                    </Dialog>
                </>
                }
            </>
            ) : null}
        </>
    );
}

export default Answer;