import React, { useState, useEffect } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Box, Typography, Button, Paper, Stack } from "@mui/material";
import BluePrint from "./BluePrintBU";
import Login from "../components/Login-Dialog";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Axios from "axios";
import CampaignIcon from '@mui/icons-material/Campaign';
import ExamOperationTrial from "./ExamOperationTrial";
import ExamTimeClock from './ExamTimeClock';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnswerContentTrial from "./AnswerContentTrial";
import AnalysisDialogTrial from "./AnalysisDialogTrial";

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Grid from "@mui/material/Grid";



const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";
     
function MainScript({ user, 
          examDetail, 
          examFullScore,
          examContent,
          currentQuestions,
          examInfo,
          startTrial,
          setStartTrial,
          answers,
          setAnswers,
          bluePrint,
          step,
          setStep,
          examSubmit,
          setExamSubmit
  }) {
  const [myFavExam, setMyFavExam] = useState([]);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [snackBarColor, setSnackBarColor] = useState();
  const [totalScore, setTotalScore] = useState(0);
  const [duration, setDuration] = useState(0);
  const [timeSpend, setTimeSpend] = useState(0);
  const [timeControl, setTimeControl] = useState(false);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [goToQuestionId, setGoToQuestionId] = useState(null);
  const [speedDialAction, setSpeedDialAction] = useState(false);

  

  const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
  ];

  const handleStartTrial = () => {
    setStartTrial(true);
    setDuration(examInfo.Duration * 60);
    setTimeControl(true);
    {
      isMediumScreen
        ? window.scrollTo({
            top: 500,
            behavior: "smooth",
          })
        : window.scrollTo({
            top: 400,
            behavior: "smooth",
          });
    }
  };

  useEffect(() => {
    if (user) {
      const accessToken = localStorage.getItem("accessToken");
      Axios.post("https://testapi.jkorpor.com/getFavExams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        user_id: user.user_id,
      }).then((res) => {
        if (res.data[0].fav_exam === null) {
          //
        } else {
          setMyFavExam(JSON.parse(res.data[0].fav_exam));
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const accessToken = localStorage.getItem("accessToken");
      Axios.post("https://testapi.jkorpor.com/updateFavExams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        user_id: user.user_id,
        myFavExam: JSON.stringify(myFavExam),
      });
    }
  }, [myFavExam]);

  const onClickFavExam = (exam_id) => {
    if (!user) {
      setOpenLogin(true);
    } else {
      const check_index = myFavExam.findIndex(
        (item) => item.exam_id === exam_id
      );
      if (check_index !== -1) {
        //
      } else {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post("https://testapi.jkorpor.com/increaseFavExams", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          exam_id: exam_id,
        }).then((res) => {
          if (res.data.message === "success") {
            window.location.reload();
          }
        });
        setMyFavExam((myFavExam) => [...myFavExam, { exam_id: exam_id }]);
      }
    }
  };

  const onUnFavExam = (exam_id) => {
    if (!user) {
      setOpenLogin(true);
    } else {
      const accessToken = localStorage.getItem("accessToken");
      Axios.post("https://testapi.jkorpor.com/decreaseFavExams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        exam_id: exam_id,
      }).then((res) => {
        if (res.data.message === "success") {
          window.location.reload();
        }
      });
      setMyFavExam((favExams) =>
        favExams.filter((favExam) => favExam.exam_id !== exam_id)
      );
    }
  };

  const handleGoToQuestion = (questionId) => {
    const questionIndex = examContent.findIndex(
      (question) => question.id === questionId
    );
    const newStep = Math.floor(questionIndex / 5);
    setStep(newStep);
    setGoToQuestionId(questionId);
  };

  const handleAnswerChange = (questionId) => (event) => {
    const newAnswer = answers.map((answer) => {
      if (answer.id === questionId) {
        return {
          id: answer.id,
          choose: event.target.value,
          point: examContent
            .find((question) => question.id === answer.id)
            .choice.find((choice) => choice.choicevalue === event.target.value)
            .point,
          fullScore: examContent.find((question) => question.id === answer.id)
            .point,
          category: examContent.find((question) => question.id === answer.id)
            .category,

        };
      }
      return answer;
    });
    console.log('newAnswer',newAnswer);
    setAnswers(newAnswer);
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
    // document.getElementById('ExamTimeClock').scrollIntoView({
    //   behavior: 'smooth'
    // });
    {
      isMediumScreen
        ? window.scrollTo({
            top: 490,
            behavior: "smooth",
          })
        : window.scrollTo({
            top: 400,
            behavior: "smooth",
          });
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
    // document.getElementById('ExamTimeClock').scrollIntoView({
    //   behavior: 'smooth'
    // });
    {
      isMediumScreen
        ? window.scrollTo({
            top: 490,
            behavior: "smooth",
          })
        : window.scrollTo({
            top: 400,
            behavior: "smooth",
          });
    }
  };

  const handleExamSubmit = () => {
    setTimeControl(false);
    let score = 0;
    let fullScore = 0;
    answers.map((val) => {
      return (score += val.point, fullScore += val.fullScore);
    });
    console.log('score',score);
    console.log('fullScore',fullScore);
    setTotalScore(score);
    setExamSubmit(true)
    {
      isMediumScreen
        ? window.scrollTo({
            top: 490,
            behavior: "smooth",
          })
        : window.scrollTo({
            top: 400,
            behavior: "smooth",
          });
    }
  };

  useEffect(() => {
    const question = document.getElementById(`question-${goToQuestionId}`);
    if (question) {
      question.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [goToQuestionId]);

  useEffect(() => {
    console.log('speedDialAction',speedDialAction);
  }, [speedDialAction]);

  const speedDialStyles = {
    position: 'absolute',
    bottom: 5,
    right: 5,
    marginTop: '-5px',
    alignItems: 'flex-end',
    "div#SpeedDialBasic-actions": {
      borderRadius: "20px",
    },
    "button.MuiButtonBase-root": {
      flexDirection: "row",
    },
    "&.MuiSpeedDial-actions": {
      paddingBottom: "20px",
    },
    "button.MuiFab-circular.MuiFab-primary":{
      backgroundColor: "#2c3639"
    },
    ...(speedDialAction
      ? {
          '#SpeedDialBasic-actions': {
            flexDirection: 'row',
            width: '200px',
            flexWrap: 'wrap',
            backgroundColor: '#555758',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '20px',
            transition: 'background-color 1.1s ease'
          },
        }
      : 
      {
        '#SpeedDialBasic-actions': {
          flexDirection: 'row',
          width: '200px',
          flexWrap: 'wrap',
          backgroundColor: 'unset',
          transition: 'background-color 1.1s ease',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '20px',
        },
      }
      ),
  };


  return (
    <>
      <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <Box
        className="MainScript"
        sx={{
          py: 5,
          mx: { xs: 2, md: 15 },
          pb: 10,
        }}
      >
        <Stack spacing={2} sx={{ marginX: { xs: 0, sm: 5, md: 10 } }}>
          <Paper
            elevation={2}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              borderRadius: 5,
              p: 2,
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                flex={6}
              >
                <Box
                  component="img"
                  src={`${examDetail.pic}`}
                  sx={{
                    width: { xs: "175px", sm: "200px", lg: "225px" },
                    borderRadius: 3,
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"left"}
                flexDirection={"column"}
                alignItems={"left"}
                flex={6}
              >
                <Stack
                  spacing={2}
                  minWidth={{ lg: "400px" }}
                  paddingX={{ lg: 3 }}
                >
                  <Box>
                    <Typography
                      sx={{
                        display: "inline",
                        borderBottom: "4px solid #ffba00",
                        fontSize: "1.3rem",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {examDetail.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "1.1rem" }}>
                      ชื่อวิชา : {examDetail.name}
                      <br />
                      รายละเอียด : {examDetail.detail}
                      <br />
                      จำนวน : {answers.length} ข้อ
                      <br />
                      ระยะเวลา : {examInfo?.Duration} นาที
                      <br />
                      คะแนนเต็ม : {examFullScore} คะแนน
                      <br />
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "flex-start",
                        },
                      }}
                    >
                      {myFavExam?.findIndex(
                        (fav) => fav.exam_id === examDetail.exam_id
                      ) === -1 ? (
                        <Tooltip title="ชื่นชอบ">
                          <IconButton
                            onClick={() => onClickFavExam(examDetail.exam_id)}
                          >
                            <FavoriteIcon
                              sx={{
                                color:
                                  myFavExam?.findIndex(
                                    (fav) => fav.exam_id === examDetail.exam_id
                                  ) === -1
                                    ? "grey"
                                    : "#E90064",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="ยกเลิกการชื่นชอบ">
                          <IconButton
                            onClick={() => onUnFavExam(examDetail.exam_id)}
                          >
                            <FavoriteIcon
                              sx={{
                                color:
                                  myFavExam?.findIndex(
                                    (fav) => fav.exam_id === examDetail.exam_id
                                  ) === -1
                                    ? "grey"
                                    : "#E90064",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip title="จำนวนคนชื่นชอบ">
                        <Typography alignSelf="center" fontSize={"1.1rem"}>
                          {examDetail.favorite} ครั้ง
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                      },
                    }}
                  >
                    {!startTrial ? (
                      <Button
                        variant="contained"
                        color="warning"
                        sx={{ borderRadius: 5, width: "200px" }}
                        onClick={handleStartTrial}
                      >
                        <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                          ทดลองสอบฟรี
                        </Typography>
                      </Button>
                    ) : null}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Paper>

          {!startTrial ? (
            <Box >
              {bluePrint ? (
                <>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline",
                        borderBottom: "4px solid #ffba00",
                        fontSize: "1.5rem",
                        fontWeight: 600,
                      }}
                    >
                      โครงสร้างข้อสอบจริง (EXAM BLUEPRINT)
                    </Typography>
                  </Box>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: 5,
                      p: 2,
                    }}
                  >
                    <Box px={{ xs: 0, md: 2 }}>
                      {/* <Typography
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          color: "#019267",
                          mb: 2,
                        }}
                      >
                        {examDetail.detail}
                      </Typography> */}
                      <BluePrint bluePrint={bluePrint} />
                    </Box>
                  </Paper>
                </>
              ) : (
                <>
                  <Box>
                    <Typography
                      sx={{
                        display: "inline",
                        borderBottom: "4px solid #ffba00",
                        fontSize: "1.5rem",
                        fontWeight: 600,
                      }}
                    >
                      โครงสร้างข้อสอบ (EXAM BLUEPRINT)
                    </Typography>
                  </Box>
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: 5,
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.3rem",
                        fontWeight: 600,
                        color: "#019267",
                        mb: 2,
                      }}
                    >
                      ยังไม่มีโครงสร้างข้อสอบ
                    </Typography>
                  </Paper>
                </>
              )}
            </Box>
          ) : (
            <>
              {!examSubmit ? (
                <>
                    <ExamTimeClock
                      timeControl={timeControl}
                      setTimeControl={setTimeControl}
                      timeSpend={timeSpend}
                      setTimeSpend={setTimeSpend}
                      duration={duration}
                      handleExamSubmit={handleExamSubmit}
                    />
                    <Box
                      my={0}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        mx: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          position: "fixed",
                          zIndex:"10",
                          bottom: "20px",
                          right: "20px",
                        }}
                      >
                        
                        <SpeedDial
                          ariaLabel="SpeedDialBasic"
                          // sx={{
                          //   position: "absolute",
                          //   bottom: 5,
                          //   right: 5,
                          //   marginTop: "-5px",
                          //   alignItems: "flex-end",
                          
                          //   // "#SpeedDialBasic-actions": {
                          //   //   flexDirection: "row",
                          //   //   width: "200px",
                          //   //   flexWrap: "wrap",
                          //   //   backgroundColor: "#a9d964",
                          //   //   display: "flex",
                          //   //   justifyContent: "center",
                          //   //   paddingBottom: "20px",
                          //   // },
                          //   "div#SpeedDialBasic-actions": {
                          //     borderRadius: "20px",
                          //   },
                          //   "button.MuiButtonBase-root": {
                          //     flexDirection: "row",
                          //   },
                          //   "&.MuiSpeedDial-actions": {
                          //     paddingBottom: "20px",
                          //   },
                          // }}
                          sx={speedDialStyles} 
                          icon={ speedDialAction ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon />   }
                          onClose={() => {
                            setSpeedDialAction(false);
                          }}
                          onOpen={() => {
                            setSpeedDialAction(true);
                          }}
                        >
                          {examContent.map((val, key) => (
                            <SpeedDialAction
                              onClick={() => handleGoToQuestion(val.id)}
                              key={val.id}
                              icon={
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                >
                                  {val.id}
                                </Typography>
                              }
                              sx={{
                                borderRadius: 3,
                                background: `${
                                  answers.findIndex(
                                    (answer) =>
                                      answer.id === val.id &&
                                      answer.choose === ""
                                  ) === -1
                                    ? "#2c3639"
                                    : "#ffb124"
                                }`,
                                "&.MuiSpeedDialAction-fab:hover": {
                                  backgroundColor: "#e53935",
                                },
                                minWidth: "45px",
                                minHeight: "10px",
                                "&.MuiSpeedDial-actions .MuiSpeedDial-actionsClosed .MuiSpeedDial-actions.MuiSpeedDial-actionsClosed":
                                  { flexDirection: "row !important" },
                              }}
                            />
                          ))}
                          
                          <Box 
                          mt={1} 
                          sx={{ justifyContent: "center"}}
                          paddingBottom={speedDialAction? '10px': '105px'}
                          >
                            <Button
                              variant="contained"
                              color="error"
                              sx={{
                              borderRadius: 3, width: "125px",
                              display:`${speedDialAction? 'inline-flex': 'none'}`,
                              transition: 'display 1.1s ease',
                              }}
                              onClick={
                                () => handleExamSubmit() 
                              }
                            >
                              <Typography sx={{ fontSize: "1.2rem" }}>ส่งคำตอบ</Typography>
                            </Button>
                            
                          </Box>
                          
                          {/* </Grid>  */}

                          {/* <SpeedDialAction>
                     {examContent?.map((val, key) => {
                    return (
                      <Button
                        key={key}
                        variant="contained"
                        onClick={() => handleGoToQuestion(val.id)}
                        color={
                          answers.findIndex((answer) => answer.id === val.id && answer.choose === '') === -1
                            ? "success"
                            : "warning"
                        }
                        sx={{
                          borderRadius: 3,
                          background: `${answers.findIndex((answer) => answer.id === val.id && answer.choose === '') === -1
                          ? "#2c3639"
                          : "#ffb124"}`,
                          "&.MuiButtonBase-root.MuiButton-root:hover": {
                            backgroundColor: "#e53935",
                          },
                          minWidth: "45px",
                          minHeight: "10px",
                        }}
                      >
                        <Typography sx={{ fontSize: "0.75rem", fontWeight: 700 }}>
                          {val.id}
                        </Typography>
                      </Button>
                    );
                      })}
                    </SpeedDialAction> */}
                        </SpeedDial>
                      </Box>
                    </Box>
                  <Paper
                    elevation={2}
                    sx={{
                      py: 4,
                      px: { xs: 0, sm: 2 },
                      mx: { xs: 2, sm: 10, md: 30, lg: 40 },
                      background: "#fff",
                      borderRadius: 5,
                    }}
                  >
                    <ExamOperationTrial
                      currentQuestions={currentQuestions}
                      answers={answers}
                      handleAnswerChange={handleAnswerChange}
                    />

                    <Box
                      p={2}
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <Button
                        variant="contained"
                        disabled={step === 0 ? true : false}
                        onClick={() => handleBack()}
                        sx={{
                          width: "125px",
                          borderRadius: 2,
                          backgroundColor: "#00a0e9",
                          color: "#ffffff",
                          "&.MuiButtonBase-root.MuiButton-root:hover": {
                            backgroundColor: "#2c3639",
                            color: "#ffffff",
                          },
                        }}
                      >
                        <Typography sx={{ fontSize: "1.2rem" }}>
                          ย้อนกลับ
                        </Typography>
                      </Button>

                      {step === Math.ceil(examContent.length / 5 - 1) ? (
                        <Button
                          variant="contained"
                          onClick={
                            () => handleExamSubmit()
                            // answers.filter((ans) => ans.choose !== "").length ===
                            //   examContent.length
                            //   ? handleExamSubmit()
                            //   : setOpenSubmitDialog(true)
                          }
                          color="error"
                          sx={{ borderRadius: 3, width: "125px" }}
                        >
                          <Typography sx={{ fontSize: "1.2rem" }}>
                            ส่งคำตอบ
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleNext()}
                          sx={{
                            width: "125px",
                            borderRadius: 2,
                            backgroundColor: "#00a0e9",
                            color: "#ffffff",
                            "&.MuiButtonBase-root.MuiButton-root:hover": {
                              backgroundColor: "#2c3639",
                            },
                          }}
                        >
                          <Typography sx={{ fontSize: "1.2rem" }}>
                            ต่อไป
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Paper>
                </>
              ) : (
                <>
                  <Paper
                    sx={{
                      py: 1,
                      px: { xs: 0, sm: 2 },
                      mx: { xs: 2, sm: 10, md: 30, lg: 40 },
                      backgroundColor: "white",
                      color: "white",
                      borderRadius: 5,
                      textAlign: "center",
                    }}
                  >
                    <Box textAlign={"left"} color={"black"}>
                      <Typography
                        sx={{
                          display: "inline",
                          borderBottom: "4px solid #ffba00",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          textAlign: "center",
                          mx: 3,
                        }}
                      >
                        คะแนนที่ได้
                      </Typography>
                    </Box>
                    <AnalysisDialogTrial answers={answers} />
                  </Paper>
                  <Paper
                    elevation={2}
                    sx={{
                      px: { xs: 0, sm: 2 },
                      mx: { xs: 2, sm: 10, md: 30, lg: 40 },
                      background: "#fff",
                      borderRadius: 5,
                    }}
                  >
                    <Typography
                      sx={{
                        display: "inline",
                        borderBottom: "4px solid #ffba00",
                        fontSize: "1.2rem",
                        fontWeight: 600,
                        textAlign: "center",
                        mx: 3,
                      }}
                    >
                      เฉลยอย่างละเอียด
                    </Typography>
                    <AnswerContentTrial
                      examContent={examContent}
                      answers={answers}
                    />
                  </Paper>
                </>
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default MainScript;
