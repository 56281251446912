import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Footer from '../components/home-components/Footer'
import Appbar from '../components/Appbar'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom:"1px solid  rgba(0, 0, 0, .15)",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:"white",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1-1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Appbar />

      <Container maxWidth="lg" sx={{ textAlign: "left", color: "black" }}>
        <Stack direction="row" alignItems="left" justifyContent="left">
          <Box sx={{ mx: { sx: 4, md: 5 }, my: 3 }}>
            <Typography
              flex={1}
              variant="h5"
              display="flex"
              justifyContent="left"
              marginBottom={2}
            >
              FAQ คำถามที่พบบ่อย
            </Typography>
            <Stack direction="row">
              <Box flex={4}>
                <Typography
                  fontSize={"1.3rem"}
                  sx={{
                    color: "rgba(0, 0, 0, .9)",
                    justifyContent: "left",
                    textAlign: "left",
                    marginLeft:2
                  }}
                >
                  วิธีสมัครสมาชิก
                </Typography>
              </Box>
              <Box flex={8}>
                <Accordion
                  expanded={expanded === "panel1-1"}
                  onChange={handleChange("panel1-1")}
                >
                  <AccordionSummary
                    aria-controls="panel1-1d-content"
                    id="panel1-1d-header"
                  >
                    <Typography>วิธีสมัครสมาชิก</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                  <Typography>
                    การสมัครสมาชิกเป็นขั้นตอนเริ่มต้นการใช้งานระบบ
                    ซึ่งสามารถทำได้ง่ายๆ แค่เพียง 3 ขั้นตอน
                  </Typography>
                  <Typography>1.เข้าสู่ Website: เลือก 'สมัครสมาชิก'</Typography>
                  <Typography>2.กรอกข้อมูลของคุณ จากนั้นเลือก 'ตกลง'</Typography>
                  <Typography>
                    3.กรอก หมายเลข OTP 6 หลักที่ได้รับจาก SMS จากนั้นเลือก 'ตกลง'
                  </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel1-2"}
                  onChange={handleChange("panel1-2")}
                >
                  <AccordionSummary
                    aria-controls="panel1-2d-content"
                    id="panel1-2d-header"
                  >
                    <Typography>การชำระเงิน</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                    การชำระเงิน วิธีง่ายๆ ผ่านช่องทาง
                    </Typography>
                    <Typography>บัตรเครดิต - บัตรเดบิต (ATM)</Typography>
                    <Typography>บริการพร้อมเพย์ (Prompt pay)</Typography>
                    <Typography>Internet Banking </Typography>
                    <Typography>วอลเล็ท(Wallet)</Typography>
                    <Typography>ชำระเงินผ่าน บิลอื่นๆ</Typography>

                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel1-3"}
                  onChange={handleChange("panel1-3")}
                >
                  <AccordionSummary
                    aria-controls="panel1-3d-content"
                    id="panel1-3d-header"
                  >
                    <Typography>นานแค่ไหนกว่าจะเข้าทำแบบทดสอบได้</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                        สามารถทำข้อสอบได้ในช่วงระยะเวลาที่กำหนดในแต่ละรอบการสอบ
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel1-4"}
                  onChange={handleChange("panel1-4")}
                >
                  <AccordionSummary
                    aria-controls="panel1-4d-content"
                    id="panel1-4d-header"
                  >
                    <Typography>ออกใบเสร็จรับเงิน/ใบกำกับภาษีได้หรือไม่</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                      ใบเสร็จ/ใบกำกับภาษีจะถูกส่งเป็น E-Tax Reciept โดยจะส่งให้ทันที
                      ทาง E-Mail หลังจากชำระเงินแล้ว
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel1-5"}
                  onChange={handleChange("panel1-5")}
                >
                  <AccordionSummary
                    aria-controls="panel1-5d-content"
                    id="panel1-5d-header"
                  >
                    <Typography>ใช้คูปองส่วนลดอย่างไร</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                    หากท่านได้รับคูปองส่วนลด ท่านสามารถกรอกคูปองส่วนลดก่อนกดสั่งซื้อ
                    </Typography>
                    <Typography>เลือกสินค้าในตารางที่ปรากฏ </Typography>
                    <Typography>กรอกโค้ดส่วนลด มุมซ้ายล่าง</Typography>
                    <Typography>หากคูปองถูกต้อง ท่านจะเห็นราคาลดลงอัตโนมัติ</Typography>
                    <Typography>กดชำระเงิน คลิก "สั่งซื้อ" </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
            <Stack direction="row">
              <Box flex={4}>
                <Typography
                  fontSize={"1.3rem"}
                  color="text.secondary"
                  sx={{
                    color: "rgba(0, 0, 0, .9)",
                    justifyContent: "left",
                    textAlign: "left",
                    marginLeft:2
                  }}
                >
                  วิธีทำข้อสอบ
                </Typography>
              </Box>
              <Box flex={8}>
                <Accordion
                  expanded={expanded === "panel2-1"}
                  onChange={handleChange("panel2-1")}
                >
                  <AccordionSummary
                    aria-controls="panel2-1d-content"
                    id="panel2-1d-header"
                  >
                    <Typography>วิธีเข้าทำข้อสอบ</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                    หลังจากที่ท่านชำระเงินแล้ว ท่านสามารถเข้าทำข้อสอบด้วยวิธีต่อไปนี้
                    </Typography>
                    <Typography>1. ไปที่ หน้าแรก</Typography>
                    <Typography>2. กด "เริ่มทำข้อสอบ" </Typography>
                    <Typography>3. กด "ทำข้อสอบ" </Typography>
                    <Typography>4. กด "เริ่มทำข้อสอบ" </Typography>
                    <Typography>5. กด "ตกลง" </Typography>
                    <Typography>หรือ </Typography>
                    <Typography>1. ไปที่ หน้าแรก</Typography>
                    <Typography>2. กด "เปิดตัวเลือก" จากไอคอนรูปโปรไฟล์</Typography>
                    <Typography>3. กด "ข้อสอบของฉัน" ระบบแสดงรายการข้อสอบที่ชำระเงินแล้ว</Typography>
                    <Typography>4. กด "ทำข้อสอบ" ระบบจะแสดงโครงสร้างข้อสอบ</Typography>
                    <Typography>5. กด "เริ่มทำข้อสอบ" เพื่อเริ่มทำข้อสอบ</Typography>
                    <Typography>6. กด "ตกลง" เมื่อกดตกลงจะแสดงข้อสอบและเริ่มจับเวลาทันที หากหมดเวลาจะไม่สามารถทำข้อสอบได้และส่งคำตอบอัตโนมัติ</Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2-2"}
                  onChange={handleChange("panel2-2")}
                >
                  <AccordionSummary
                    aria-controls="panel2-2d-content"
                    id="panel2-2d-header"
                  >
                    <Typography>อุปกรณ์ใดที่สามารถทำแบบทดสอบได้บ้าง</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                      ได้ทุกอุปกรณ์ที่เชื่อมต่อกับอินเตอร์เน็ต ผ่าน Web browsers ไม่ว่าจะเป็น Windows Mac Android หรือ iOS 
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2-3"}
                  onChange={handleChange("panel2-3")}
                >
                  <AccordionSummary
                    aria-controls="panel2-3d-content"
                    id="panel2-3d-header"
                  >
                    <Typography>ข้อสอบมีอายุการใช้งานหรือไม่ เรียนได้นานแค่ไหน</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                      สิทธิเข้าทำข้อสอบ สามารถเข้าทดสอบกี่ครั้งก็ได้ เมื่อไรก็ได้ ในช่วงเวลาที่กำหนด
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
            <Stack direction="row">
              <Box flex={4}>
                <Typography
                  fontSize={"1.3rem"}
                  color="text.secondary"
                  sx={{
                    color: "rgba(0, 0, 0, .9)",
                    justifyContent: "left",
                    textAlign: "left",
                    marginLeft:2
                  }}
                >
                  เกี่ยวกับ JTCAS TEST
                </Typography>
              </Box>
              <Box flex={8}>
                <Accordion
                  expanded={expanded === "panel3-1"}
                  onChange={handleChange("panel3-1")}
                >
                  <AccordionSummary
                    aria-controls="panel3-1d-content"
                    id="panel3-1d-header"
                  >
                    <Typography>JTCAS TEST คืออะไร</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                    สนามจำลองการสอบ TCAS ออนไลน์ ที่ครบและรอบด้านที่สุดในประเทศไทย ให้น้องๆ ได้ฝึกการสอบเสมือนจริงเตรียมความพร้อมก่อนลงสนามสอบ
                    By J Knowledge
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3-2"}
                  onChange={handleChange("panel3-2")}
                >
                  <AccordionSummary
                    aria-controls="panel3-2d-content"
                    id="panel3-2d-header"
                  >
                    <Typography>ทำไมต้อง JTCAS TEST</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{marginLeft:5}}>
                    <Typography>
                      ให้น้องๆ ได้ฝึกการสอบเสมือนจริงเตรียมความพร้อมก่อนลงสนามสอบ
                      โครงสร้างข้อสอบและความยาก-ง่ายข้อสอบ ใกล้เคียงกับข้อสอบจริง 
                      มีระบบจับเวลา ส่งคำตอบอัตโนมัติเมื่อหมดเวลา ตรวจคำตอบ สรุปคะแนนพร้อมเฉลยข้อสอบ 
                      และวิเคราะห์ผลคะแนน จุดอ่อน จุดเด่นของผู้สอบ 
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <Footer />
    </>
  );
}
