import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import CartBottomBar from "./CartBottomBar";
import { useAuthState } from "../../context/AuthContext";
import Axios from "axios";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";

export default function Cart({myExamList, myExamLoading}) {
  const { user } = useAuthState();
  const [isTrueKORPORPATR1, setIsTrueKORPORPATR1] = React.useState(true);
  const [isTrueKORPORPATR2, setIsTrueKORPORPATR2] = React.useState(false);
  const [isTrueLOCALR1, setIsTrueLOCALR1] = React.useState(true);
  const [isTrueLOCALR2, setIsTrueLOCALR2] = React.useState(false);
  
  const [itemInCartKORPORPATR1, setItemInCartKORPORPATR1] = useState();
  const [itemInCartKORPORPATR2, setItemInCartKORPORPATR2] = useState();
  const [itemInCartLOCALR1, setItemInCartLOCALR1] = useState();
  const [itemInCartLOCALR2, setItemInCartLOCALR2] = useState();

  const [itemInCartKORPORPATR1STR, setItemInCartKORPORPATR1STR] = useState();
  const [itemInCartKORPORPATR2STR, setItemInCartKORPORPATR2STR] = useState();
  const [itemInCartLOCALR1STR, setItemInCartLOCALR1STR] = useState();
  const [itemInCartLOCALR2STR, setItemInCartLOCALR2STR] = useState();

  const [itemInCartMerge, setItemInCartMerge] = useState([]);
  const [examIdsMerge, setExamIdsMerge] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selecteTabledData, setSelectedTableData] = useState([]);


  const [codeList, setCodeList] = useState();
  const [promotion, setPromotion] = useState();

  const checkedIcon = (
    <IconButton
    size="small"
  >
    <CheckCircleIcon sx={{ color: "#ffba00" }} />
  </IconButton>
  )
  const unCheckedIcon = (
    <IconButton
      size="small"
    >
      <RadioButtonUncheckedIcon sx={{ color: "#ffba00" }} />
    </IconButton>
  )
  const Indeterminate = (
    <IconButton
      size="small"
    >
      <RemoveCircleIcon sx={{ color: "#ffba00" }} />
    </IconButton>
  )
  const checkedIconEven = (
    <IconButton
    size="small"
  >
    <CheckCircleIcon sx={{ color: "#40938b" }} />
  </IconButton>
  )
  const unCheckedIconEven = (
    <IconButton
      size="small"
    >
      <RadioButtonUncheckedIcon sx={{ color: "#40938b" }} />
    </IconButton>
  )
  const IndeterminateEven = (
    <IconButton
      size="small"
    >
      <RemoveCircleIcon sx={{ color: "#40938b" }} />
    </IconButton>
  )

  useEffect(() => {
    if (isTrueKORPORPATR1) {
      setIsTrueKORPORPATR2(false);
    }
    if (isTrueKORPORPATR2) {
      setIsTrueKORPORPATR1(false);
    }
  }, [isTrueKORPORPATR1, isTrueKORPORPATR2]);


  useEffect(() => {
    if (isTrueLOCALR1) {
      setIsTrueLOCALR2(false);
    }
    if (isTrueLOCALR2) {
      setIsTrueLOCALR1(false);
    }
  }, [isTrueLOCALR1, isTrueLOCALR2]);

  const columns = [
    { field: "name", headerName: "เลือกทั้งหมด", minWidth: 200, flex: 1 },
  ];

  useEffect(() => {
    if (myExamLoading) {
      Axios.post("https://testapi.jkorpor.com/getExamAllInCart", {
      }).then((res) => {
        setItemInCartMerge((itemInCartMerge) => [
          ...itemInCartMerge,
          ...res.data,
        ]);
  
        const KORPORPATR1 = res.data.filter(
          (exam) => (exam.category_id == 1 ) 
        );
        const updatedKORPORPATR1 = KORPORPATR1?.map(item => {
          if (item.name.includes("สอบครั้งที่ 1") || item.name.includes("สอบครั้งที่ 2")) {
            if (myExamList?.includes(item.exam_id)) {
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: true };
            } else{
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: false };
            }
          }else{
            if (myExamList.includes(item.exam_id)) {
              return { ...item, disableSelect: true };
            } else{
              return { ...item, disableSelect: false };
            }
          }
          });
        if (updatedKORPORPATR1.length > 0) {
          setItemInCartKORPORPATR1STR(updatedKORPORPATR1);
          setItemInCartKORPORPATR1(KORPORPATR1);
        }
  
        const KORPORPATR2 = res.data.filter(
          (exam) => (exam.category_id == 3)
        );
        const updatedKORPORPATR2 = KORPORPATR2?.map(item => {
          if (item.name.includes("สอบครั้งที่ 1") || item.name.includes("สอบครั้งที่ 2")) {
            if (myExamList?.includes(item.exam_id)) {
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: true };
            } else{
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: false };
            }
          }else{
            if (myExamList.includes(item.exam_id)) {
              return { ...item, disableSelect: true };
            } else{
              return { ...item, disableSelect: false };
            }
          }
          });
        if (updatedKORPORPATR2.length > 0) {
          setItemInCartKORPORPATR2STR(updatedKORPORPATR2);
          setItemInCartKORPORPATR2(KORPORPATR2);
        }
  
        const LOCALR1 = res.data.filter(
          (exam) => (exam.category_id == 2)
        );
        const updatedLOCALR1 = LOCALR1?.map(item => {
          if (item.name.includes("สอบครั้งที่ 1") || item.name.includes("สอบครั้งที่ 2")) {
            if (myExamList?.includes(item.exam_id)) {
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: true };
            } else{
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: false };
            }
          }else{
            if (myExamList.includes(item.exam_id)) {
              return { ...item, disableSelect: true };
            } else{
              return { ...item, disableSelect: false };
            }
          }
          });
        
        if (updatedLOCALR1.length > 0) {
          setItemInCartLOCALR1STR(updatedLOCALR1);
        setItemInCartLOCALR1(LOCALR1);
        }
  
        const LOCALR2 = res.data.filter(
          (exam) => (exam.category_id == 4)
        );
        const updatedLOCALR2 = LOCALR2?.map(item => {
          if (item.name.includes("สอบครั้งที่ 1") || item.name.includes("สอบครั้งที่ 2")) {
            if (myExamList?.includes(item.exam_id)) {
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: true };
            } else{
              return { ...item, name: item.name.replace(/สอบครั้งที่ 1|สอบครั้งที่ 2/g, ""), disableSelect: false };
            }
          }else{
            if (myExamList.includes(item.exam_id)) {
              return { ...item, disableSelect: true };
            } else{
              return { ...item, disableSelect: false };
            }
          }
          });
        if (updatedLOCALR2.length > 0) {
          setItemInCartLOCALR2STR(updatedLOCALR2);
          setItemInCartLOCALR2(LOCALR2);
        }
        
        setDataLoading(true)
      });
    }
  }, [myExamLoading]);
 

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    Axios.post("https://testapi.jkorpor.com/getCode", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      setCodeList(res.data);
    });

    Axios.post("https://testapi.jkorpor.com/getPromotion", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      setPromotion(res.data);
    });

    localStorage.setItem("selectItem", JSON.stringify([]));
    localStorage.setItem("discount", JSON.stringify([]));
  }, []);

  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [promoDiscount, setPromoDiscount] = useState(0);

  const onRowsSelectionHandlerKORPORPATR1 = (examIds) => {
    const updatedExamIdsMerge = examIds.filter(
      (examId) => !examIdsMerge.includes(examId)
    );
    const selectedRowsDataMergeComplement = itemInCartKORPORPATR1.filter(
      (exam) => !examIds.includes(exam.exam_id)
    );
    const selectedRowsDataMergeComplementIds =
      selectedRowsDataMergeComplement.map((exam) => exam.exam_id);
    const updatedExamIdsMergeSum = [
      ...examIdsMerge,
      ...updatedExamIdsMerge,
    ].filter((examId) => !selectedRowsDataMergeComplementIds.includes(examId));
    setExamIdsMerge(updatedExamIdsMergeSum);

    const selectedRowsData = updatedExamIdsMergeSum.map((exam_id) =>
      itemInCartMerge?.find((row) => row.exam_id === exam_id)
    );
    const tableDataselected = selectedRowsData.map((item) => item.exam_id);
    const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
    localStorage.setItem("dataSelected", ciphertext_cart)
    setSelectedTableData(tableDataselected)
    setSelectedItem(selectedRowsData);
    onSelectedAmount(selectedRowsData);
    handlePromoDiscount(selectedRowsData, promotion);
  };

  const onRowsSelectionHandlerKORPORPATR2 = (examIds) => {
    const updatedExamIdsMerge = examIds.filter(
      (examId) => !examIdsMerge.includes(examId)
    );
    const selectedRowsDataMergeComplement = itemInCartKORPORPATR2.filter(
      (exam) => !examIds.includes(exam.exam_id)
    );
    const selectedRowsDataMergeComplementIds =
      selectedRowsDataMergeComplement.map((exam) => exam.exam_id);
    const updatedExamIdsMergeSum = [
      ...examIdsMerge,
      ...updatedExamIdsMerge,
    ].filter((examId) => !selectedRowsDataMergeComplementIds.includes(examId));
    setExamIdsMerge(updatedExamIdsMergeSum);

    const selectedRowsData = updatedExamIdsMergeSum.map((exam_id) =>
      itemInCartMerge?.find((row) => row.exam_id === exam_id)
    );
    const tableDataselected = selectedRowsData.map((item) => item.exam_id);
    const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
    localStorage.setItem("dataSelected", ciphertext_cart)
    setSelectedTableData(tableDataselected)
    setSelectedItem(selectedRowsData);
    onSelectedAmount(selectedRowsData);
    handlePromoDiscount(selectedRowsData, promotion);
  };

  const onRowsSelectionHandlerLOCALR1 = (examIds) => {
    const updatedExamIdsMerge = examIds.filter(
      (examId) => !examIdsMerge.includes(examId)
    );
    const selectedRowsDataMergeComplement = itemInCartLOCALR1.filter(
      (exam) => !examIds.includes(exam.exam_id)
    );
    const selectedRowsDataMergeComplementIds =
      selectedRowsDataMergeComplement.map((exam) => exam.exam_id);
    const updatedExamIdsMergeSum = [
      ...examIdsMerge,
      ...updatedExamIdsMerge,
    ].filter((examId) => !selectedRowsDataMergeComplementIds.includes(examId));
    setExamIdsMerge(updatedExamIdsMergeSum);

    const selectedRowsData = updatedExamIdsMergeSum.map((exam_id) =>
      itemInCartMerge?.find((row) => row.exam_id === exam_id)
    );
    const tableDataselected = selectedRowsData.map((item) => item.exam_id);
    const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
    localStorage.setItem("dataSelected", ciphertext_cart)
    setSelectedTableData(tableDataselected)
    setSelectedItem(selectedRowsData);
    onSelectedAmount(selectedRowsData);
    handlePromoDiscount(selectedRowsData, promotion);
  };

  const onRowsSelectionHandlerLOCALR2 = (examIds) => {
    const updatedExamIdsMerge = examIds.filter(
      (examId) => !examIdsMerge.includes(examId)
    );
    const selectedRowsDataMergeComplement = itemInCartLOCALR2.filter(
      (exam) => !examIds.includes(exam.exam_id)
    );
    const selectedRowsDataMergeComplementIds =
      selectedRowsDataMergeComplement.map((exam) => exam.exam_id);
    const updatedExamIdsMergeSum = [
      ...examIdsMerge,
      ...updatedExamIdsMerge,
    ].filter((examId) => !selectedRowsDataMergeComplementIds.includes(examId));
    setExamIdsMerge(updatedExamIdsMergeSum);

    const selectedRowsData = updatedExamIdsMergeSum.map((exam_id) =>
      itemInCartMerge?.find((row) => row.exam_id === exam_id)
    );
    const tableDataselected = selectedRowsData.map((item) => item.exam_id);
    const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
    localStorage.setItem("dataSelected", ciphertext_cart)
    setSelectedTableData(tableDataselected)
    setSelectedItem(selectedRowsData);
    onSelectedAmount(selectedRowsData);
    handlePromoDiscount(selectedRowsData, promotion);
  };

  const onSelectedAmount = (selectedRowsData) => {
    let total = 0;
    selectedRowsData.map((val) => {
      return (total += val.amount);
    });
    setSelectedAmount(total);
  };

  const handlePromoDiscount = (selectedRowsData, promotions) => {
    const selected = selectedRowsData?.length;
    const matchedPromotions = promotions.filter(
      (promo) => promo.promotion_min <= selected
    );
    const discount = matchedPromotions.length
      ? Math.max(...matchedPromotions.map((promo) => promo.promotion_discount))
      : 0;
    setPromoDiscount(discount);
  };

  useEffect(() => {
    if (dataLoading) {
      const dataSelected = localStorage.getItem("dataSelected");
        if (!dataSelected) {
          const ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify([]), EncryptSecret).toString();
          localStorage.setItem("dataSelected", ciphertext_cart);
        }else{
          if (promotion) {
            const dataSelectedLocal = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('dataSelected'), EncryptSecret).toString(CryptoJS.enc.Utf8))
            setSelectedTableData(dataSelectedLocal)
            let selectedRowsData = itemInCartMerge?.filter((exam) =>
            dataSelectedLocal.includes(exam.exam_id)
            );
              onSelectedAmount(selectedRowsData);
              handlePromoDiscount(selectedRowsData, promotion);
              setSelectedItem(selectedRowsData);
            }
        }
    }
  }, [dataLoading,promotion]);

  // const handleClickChip = (data) => {
  //   let tableDataselected = []
  //   let selectedRowsData = []
  //   let ciphertext_cart =""
  //   switch (data.key) {
  //     case 1:
  //       const HealthScience = [13, 20, 21, 22, 23, 24, 25, 26];
  //        selectedRowsData = itemInCartMerge?.filter((exam) =>
  //         HealthScience.includes(exam.exam_id) & (!myExamList.includes(exam.exam_id))
  //       );
  //       tableDataselected = selectedRowsData.map((item) => item.exam_id);
  //       ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
  //       localStorage.setItem("dataSelected", ciphertext_cart)
  //       setSelectedTableData(tableDataselected);
  //       setSelectedItem(selectedRowsData);
  //       onSelectedAmount(selectedRowsData);
  //       handlePromoDiscount(selectedRowsData, promotion);
  //       break;
  //     case 2:
  //       const Engineering = [17, 25, 24, 21, 26];
  //         selectedRowsData = itemInCartMerge?.filter((exam) =>
  //         Engineering.includes(exam.exam_id) & (!myExamList.includes(exam.exam_id))
  //       );
  //         tableDataselected = selectedRowsData.map((item) => item.exam_id);
  //         ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
  //         localStorage.setItem("dataSelected", ciphertext_cart)
  //       setSelectedTableData(tableDataselected);
  //       setSelectedItem(selectedRowsData);
  //       onSelectedAmount(selectedRowsData);
  //       handlePromoDiscount(selectedRowsData, promotion);
  //       break;
  //     case 3:
  //       const Human = [10, 24, 22, 23, 25];
  //       selectedRowsData = itemInCartMerge?.filter((exam) =>
  //       Human.includes(exam.exam_id) & (!myExamList.includes(exam.exam_id))
  //       );
  //         tableDataselected = selectedRowsData.map((item) => item.exam_id);
  //         ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
  //       localStorage.setItem("dataSelected", ciphertext_cart)
  //       setSelectedTableData(tableDataselected);
  //       setSelectedItem(selectedRowsData);
  //       onSelectedAmount(selectedRowsData);
  //       handlePromoDiscount(selectedRowsData, promotion);
  //       break;
  //     case 4:
  //       const Nursing = [10, 24, 25, 20, 26];
  //       selectedRowsData = itemInCartMerge?.filter((exam) =>
  //       Nursing.includes(exam.exam_id) & (!myExamList.includes(exam.exam_id))
  //       );
  //         tableDataselected = selectedRowsData.map((item) => item.exam_id);
  //         ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
  //       localStorage.setItem("dataSelected", ciphertext_cart)
  //       setSelectedTableData(tableDataselected);
  //       setSelectedItem(selectedRowsData);
  //       onSelectedAmount(selectedRowsData);
  //       handlePromoDiscount(selectedRowsData, promotion);
  //       break;
  //     case 5:
  //       const Teacher = [10, 19, 22, 23, 25];
  //       selectedRowsData = itemInCartMerge?.filter((exam) =>
  //       Teacher.includes(exam.exam_id) & (!myExamList.includes(exam.exam_id))
  //       );
  //         tableDataselected = selectedRowsData.map((item) => item.exam_id);
  //         ciphertext_cart = CryptoJS.AES.encrypt(JSON.stringify(tableDataselected), EncryptSecret).toString();
  //       localStorage.setItem("dataSelected", ciphertext_cart)
  //       setSelectedTableData(tableDataselected);
  //       setSelectedItem(selectedRowsData);
  //       onSelectedAmount(selectedRowsData);
  //       handlePromoDiscount(selectedRowsData, promotion);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <Box mx={1}>
        <Container>
          <Box
            sx={{
              paddingX: { xs: "10px", sm: "20px", md: "100px", lg: "150px" },
              paddingY:"10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.6rem",
                display: "inline",
                borderBottom: 5,
                borderColor: "#ffba00",
              }}
            >
              สมัครสอบ
            </Typography>
          </Box>
        </Container>
      </Box>
      

      {myExamLoading ? (
      <Container>
      {itemInCartKORPORPATR1 || itemInCartKORPORPATR2 ? (
        <Box
          sx={{
            paddingX: { xs: "0px", sm: "20px", md: "100px", lg: "150px" },
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            flexDirection={{
              xs: "row",
              sm: "row",
              md: "row",
            }}
            alignItems="center"
            color="white"
            sx={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={4}
              initial="hidden"
              backgroundColor={itemInCartKORPORPATR1 ? "#40938b" : "unset"}
              sx={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              {itemInCartKORPORPATR1 ? (
                <Box>
                  <FormControlLabel
                    variant="outlined"
                    size="small"
                    label="สอบครั้งที่ 1"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.9rem" },
                      "svg.MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={isTrueKORPORPATR1}
                        onChange={(e) => {
                          setIsTrueKORPORPATR1(e.target.checked);
                          setIsTrueKORPORPATR2(
                            e.target.checked == false ? true : false
                          );
                        }}
                        color="primary"
                        name={"ครั้งที่ 1 7-21 พ.ย. 66"}
                      />
                    }
                  />
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    textAlign={"center"}
                  >
                    <Typography fontSize={"0.7rem"}>7-21 พ.ย. 66</Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flex={6}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                paddingX={2}
                color="#2c3639"
                fontWeight={"800"}
              >
                ก.พ.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={4}
              initial="hidden"
              backgroundColor={itemInCartKORPORPATR2 ? "#8fc859" : "unset"}
              sx={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              {itemInCartKORPORPATR2 ? (
                <Box>
                  <FormControlLabel
                    variant="outlined"
                    size="small"
                    label="สอบครั้งที่ 2"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.9rem" },
                      "svg.MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={isTrueKORPORPATR2}
                        onChange={(e) => {
                          setIsTrueKORPORPATR2(e.target.checked);
                          setIsTrueKORPORPATR1(
                            e.target.checked == false ? true : false
                          );
                        }}
                        color="primary"
                        name={"ครั้งที่ 2"}
                      />
                    }
                  />
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    textAlign={"center"}
                  >
                    <Typography fontSize={"0.7rem"}><br/></Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            flexDirection={{
              xs: "row",
              sm: "row",
              md: "row",
            }}
            alignItems="center"
          >
            {itemInCartKORPORPATR1? (
              <Box
                sx={{
                  display: isTrueKORPORPATR1 ? "block" : "none",
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={itemInCartKORPORPATR1STR}
                  getRowId={(row) => row?.exam_id}
                  columns={columns}
                  sortable={true}
                  filterable={true}
                  disableColumnMenu={true}
                  hideFooter={true}
                  rowHeight={"1.75rem"}
                  checkboxSelection
                  onSelectionModelChange={(ids) =>
                    onRowsSelectionHandlerKORPORPATR1(ids)
                  }
                  selectionModel={selecteTabledData}
                  isRowSelectable={(params) =>
                    params.row.disableSelect === false
                  }
                  getRowClassName={(params) => {
                    return params.row.disableSelect === true ? "Disableselecthighlight" : "";
                  }}
                  sx={{
                    fontSize: "1rem",
                    boxShadow: 0,
                    border: "unset",
                    minHeight: "175px",
                    borderColor: "unset",
                    "&>.MuiDataGrid-main": {
                      "&>.MuiDataGrid-columnHeaders": {
                        borderBottom: "none",
                        maxHeight: "35px!important",
                        lineHeight: "35px!important",
                        minHeight: "35px!important",
                      },
                      "& div div div div >.MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& div div div div >.MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: `${
                          itemInCartKORPORPATR1 ? "#40938b" : "#ffffff"
                        }`,
                        color: "white",
                        fontSize: "0.8rem",
                        "span.MuiCheckbox-colorPrimary": {
                          color: "#ffffff !important"
                        }
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff",
                        fontSize:"0.9rem"
                      },
                      "& .MuiDataGrid-row.Mui-selected": {
                        backgroundColor: "#40938b",
                        borderRadius: "10px !important",
                        color: "#ffffff !important"
                      },
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      marginTop: "36px !important",
                    },
                    "span.MuiCheckbox-colorPrimary": {
                      color: "#40938b !important"
                    },
                    "& .Disableselecthighlight.MuiDataGrid-row": {
                      color: '#40938b',
                      backgroundColor: "#fff16e",
                      borderRadius: "10px !important",
                    },
                  }}

                  components={{
                    BaseCheckbox: (props) => (
                      <Checkbox {...props} checkedIcon={checkedIcon} icon={unCheckedIcon} indeterminateIcon={Indeterminate}  />
                    ),
                  }}
                />
              </Box>
            ) : isTrueKORPORPATR2 ? null : (
              setIsTrueKORPORPATR2(true)
            )}

            {itemInCartKORPORPATR2 ? (
              <Box
                sx={{
                  display: isTrueKORPORPATR2 ? "block" : "none",
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={itemInCartKORPORPATR2STR}
                  getRowId={(row) => row?.exam_id}
                  columns={columns}
                  sortable={true}
                  filterable={true}
                  disableColumnMenu={true}
                  hideFooter={true}
                  rowHeight={"1.75rem"}
                  checkboxSelection
                  onSelectionModelChange={(ids) =>
                    onRowsSelectionHandlerKORPORPATR2(ids)
                  }
                  selectionModel={selecteTabledData}
                  isRowSelectable={(params) =>
                    params.row.disableSelect === false
                  }
                  getRowClassName={(params) => {
                    return params.row.disableSelect === true ? "Disableselecthighlight" : "";
                  }}

                  sx={{
                    fontSize: "1rem",
                    boxShadow: 0,
                    border: "unset",
                    minHeight: "175px",
                    borderColor: "unset",
                    "&>.MuiDataGrid-main": {
                      "&>.MuiDataGrid-columnHeaders": {
                        borderBottom: "none",
                        maxHeight: "35px!important",
                        lineHeight: "35px!important",
                        minHeight: "35px!important",
                      },
                      "& div div div div >.MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& div div div div >.MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: `${
                          itemInCartKORPORPATR2 ? "#8fc859" : "#ffffff"
                        }`,
                        color: "white",
                        fontSize: "0.8rem",
                        "span.MuiCheckbox-colorPrimary": {
                          color: "#ffffff !important"
                        }
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff",
                        fontSize:"0.9rem"
                      },
                      "& .MuiDataGrid-row.Mui-selected": {
                        backgroundColor: "#8fc85970",
                        borderRadius: "10px !important",
                      },
                      
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      marginTop: "36px !important",
                    },
                    "span.MuiCheckbox-colorPrimary": {
                      color: "#8fc859 !important"
                    },
                    "& .Disableselecthighlight.MuiDataGrid-row": {
                      color: '#40938b',
                      backgroundColor: "#fff16e",
                      borderRadius: "10px !important",
                    },

                  }}
                  
                  components={{
                    BaseCheckbox: (props) => (
                      <Checkbox {...props} checkedIcon={checkedIconEven} icon={unCheckedIconEven} indeterminateIcon={IndeterminateEven}  />
                    ),
                  }}
                />
              </Box>
            ) : null}
          </Stack>
        </Box>
      ) : null}
      
      {itemInCartLOCALR1 || itemInCartLOCALR2 ? (
        <Box
          sx={{
            paddingX: { xs: "0px", sm: "20px", md: "100px", lg: "150px" },
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            flexDirection={{
              xs: "row",
              sm: "row",
              md: "row",
            }}
            alignItems="center"
            color="white"
            sx={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={4}
              initial="hidden"
              backgroundColor={itemInCartLOCALR1 ? "#40938b" : "unset"}
              sx={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              
              {itemInCartLOCALR1 ? (
                <Box>
                  <FormControlLabel
                    variant="outlined"
                    size="small"
                    label="สอบครั้งที่ 1"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.9rem" },
                      "svg.MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={isTrueLOCALR1}
                        onChange={(e) => {
                          setIsTrueLOCALR1(e.target.checked);
                          setIsTrueLOCALR2(
                            e.target.checked == false ? true : false
                          );
                        }}
                        color="primary"
                        name={"ครั้งที่ 1"}
                      />
                    }
                  />
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    textAlign={"center"}
                  >
                    <Typography fontSize={"0.7rem"}>1-14 ธ.ค. 66</Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flex={6}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                paddingX={2}
                color="#2c3639"
                fontWeight={"800"}
              >
                ท้องถิ่น
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={4}
              initial="hidden"
              backgroundColor={itemInCartLOCALR2 ? "#8fc859" : "unset"}
              sx={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              {itemInCartLOCALR2 ? (
                <Box>
                  <FormControlLabel
                    variant="outlined"
                    size="small"
                    label="สอบครั้งที่ 2"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "0.9rem" },
                      "svg.MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={isTrueLOCALR2}
                        onChange={(e) => {
                          setIsTrueLOCALR2(e.target.checked);
                          setIsTrueLOCALR1(
                            e.target.checked == false ? true : false
                          );
                        }}
                        color="primary"
                        name={"ครั้งที่ 2"}
                      />
                    }
                  />
                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    textAlign={"center"}
                  >
                    <Typography fontSize={"0.7rem"}>12-25 ม.ค. 67</Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            flexDirection={{
              xs: "row",
              sm: "row",
              md: "row",
            }}
            alignItems="center"
          >
            {itemInCartLOCALR1 ? (
              <Box
                sx={{
                  display: isTrueLOCALR1 ? "block" : "none",
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={itemInCartLOCALR1STR}
                  getRowId={(row) => row?.exam_id}
                  columns={columns}
                  sortable={true}
                  filterable={true}
                  disableColumnMenu={true}
                  hideFooter={true}
                  rowHeight={"1.75rem"}
                  checkboxSelection
                  onSelectionModelChange={(ids) =>
                    onRowsSelectionHandlerLOCALR1(ids)
                  }
                  selectionModel={selecteTabledData}
                  isRowSelectable={(params) =>
                    params.row.disableSelect === false
                  }
                  getRowClassName={(params) => {
                    return params.row.disableSelect === true ? "Disableselecthighlight" : "";
                  }}

                  sx={{
                    fontSize: "1rem",
                    boxShadow: 0,
                    border: "unset",
                    minHeight: "175px",
                    borderColor: "unset",
                    "&>.MuiDataGrid-main": {
                      "&>.MuiDataGrid-columnHeaders": {
                        borderBottom: "none",
                        maxHeight: "35px!important",
                        lineHeight: "35px!important",
                        minHeight: "35px!important",
                      },
                      "& div div div div >.MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& div div div div >.MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: `${
                          itemInCartLOCALR1 ? "#40938b" : "#ffffff"
                        }`,
                        color: "white",
                        fontSize: "0.8rem",
                        "span.MuiCheckbox-colorPrimary": {
                          color: "#ffffff !important"
                        }
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff",
                        fontSize:"0.9rem"
                      },
                      "& .MuiDataGrid-row.Mui-selected": {
                        backgroundColor: "#40938b",
                        borderRadius: "10px !important",
                        color: "#ffffff !important"
                      },
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      marginTop: "36px !important",
                    },
                    "span.MuiCheckbox-colorPrimary": {
                      color: "#40938b !important"
                    },
                    "& .Disableselecthighlight.MuiDataGrid-row": {
                      color: '#40938b',
                      backgroundColor: "#fff16e",
                      borderRadius: "10px !important",
                    },
                  }}

                  components={{
                    BaseCheckbox: (props) => (
                      <Checkbox {...props} checkedIcon={checkedIcon} icon={unCheckedIcon} indeterminateIcon={Indeterminate}  />
                    ),
                  }}
                />
              </Box>
            ) : isTrueLOCALR2 ? null : (
              setIsTrueLOCALR2(true)
            )}

            {itemInCartLOCALR2 ? (
              <Box
                sx={{
                  display: isTrueLOCALR2 ? "block" : "none",
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={itemInCartLOCALR2STR}
                  getRowId={(row) => row?.exam_id}
                  columns={columns}
                  sortable={true}
                  filterable={true}
                  disableColumnMenu={true}
                  hideFooter={true}
                  rowHeight={"1.75rem"}
                  checkboxSelection
                  onSelectionModelChange={(ids) =>
                    onRowsSelectionHandlerLOCALR2(ids)
                  }
                  selectionModel={selecteTabledData}
                  isRowSelectable={(params) =>
                    params.row.disableSelect === false
                  }
                  getRowClassName={(params) => {
                    return params.row.disableSelect === true ? "Disableselecthighlight" : "";
                  }}

                  sx={{
                    fontSize: "1rem",
                    boxShadow: 0,
                    border: "unset",
                    minHeight: "200px",
                    borderColor: "unset",
                    "&>.MuiDataGrid-main": {
                      "&>.MuiDataGrid-columnHeaders": {
                        borderBottom: "none",
                        maxHeight: "35px!important",
                        lineHeight: "35px!important",
                        minHeight: "35px!important",
                      },
                      "& div div div div >.MuiDataGrid-cell": {
                        borderBottom: "none",
                        padding: "5px"
                      },
                      "& div div div div >.MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: `${
                          itemInCartLOCALR2 ? "#8fc859" : "#ffffff"
                        }`,
                        color: "white",
                        fontSize: "0.8rem",
                        "span.MuiCheckbox-colorPrimary": {
                          color: "#ffffff !important"
                        }
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-cellCheckbox": {
                        maxWidth: "25px !important",
                        minWidth: "25px !important"
                      }
                    },
                    "& .MuiDataGrid-row": {
                      backgroundColor: "#ffffff",
                      display: "inline-flex",
                      width:"50%",
                      overflow: "hidden",
                      fontSize:"0.9rem"
                    },
                    "& .MuiDataGrid-row.Mui-selected": {
                      backgroundColor: "#8fc859d6",
                      borderRadius: "10px !important",
                      color: "#ffffff !important"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      marginTop: "36px !important",
                    },
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                      display: "inline"
                    },
                    "span.MuiCheckbox-colorPrimary": {
                      color: "#ffffff !important",
                      paddingLeft: "12px"
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#ffffff !important" 
                    },
                    "& .Mui-selected:hover": {
                      backgroundColor: "#8fc859d6 !important" 
                    },
                    "& .MuiDataGrid-checkboxInput":{
                      borderRadius: "50% !important"
                    },
                    "& .Disableselecthighlight.MuiDataGrid-row": {
                      color: '#40938b',
                      backgroundColor: "#fff16e",
                      borderRadius: "10px !important",
                    },

                  }}
                  components={{
                    BaseCheckbox: (props) => (
                      <Checkbox {...props} checkedIcon={checkedIconEven} icon={unCheckedIconEven} indeterminateIcon={IndeterminateEven}  />
                    ),
                  }}
                />
              </Box>
            ) : null}
          </Stack>
        </Box>
      ) : null}
      </Container>
      ) : null}
      

      {user ? (
        <Box
          mx={{ xs: 0, md: 2 }}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CartBottomBar
            selectedItem={selectedItem}
            selectedAmount={selectedAmount}
            promoDiscount={promoDiscount}
            user_id={user.user_id}
            promotion={promotion}
            codeList={codeList}
          />
        </Box>
      ) : null}
    </>
  );
}
