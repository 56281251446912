import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import { Box, Hidden } from "@mui/material";
import ExamComponent from "../components/Exam-component/ExamComponent";
import Axios from "axios";
import ExamAlertDialog from "../components/Exam-component/ExamAlertDialog";
import Calendar from '../asset/calendar.png'
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Slide
} from "@mui/material";
import Appbar from '../components/Appbar'

const CryptoJS = require("crypto-js");
const EncryptSecret = "Jknow2023Korpor";

function Exams() {
  const { exam_slug } = useParams();
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const [exam, setExam] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [permissionRound, setPermissionRound] = useState(false);
  const [permissionAnnouncement, setPermissionAnnouncement] = useState("");


  
  const handleClose = () => {
    window.location.href = `/introduction/${exam_slug}`
  };

  useEffect(() => {
    const ciphertext = JSON.parse(localStorage.getItem("users"));
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, EncryptSecret);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      dispatch({
        status: "loggedIn",
        user: decryptedData,
        error: null,
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
          const accessToken = localStorage.getItem('accessToken')
            Axios.post("https://testapi.jkorpor.com/UserExam", {
            headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
            },
              user_id: user.user_id,
              exam_slug: exam_slug
            }).then((res) => {
              if (res.data.message === "ok") {
                setLoading(true);
                setExam(res.data.result);
                if (res.data.result[0].action === "on") {
                  if (res.data.result[0].round_id === 99) {
                    setPermissionRound(false);
                    setPermissionAnnouncement(res.data.result[0].text_announcement)
                  }else{
                    setPermissionRound(true);
                  }
                } else{
                  setPermissionAnnouncement(res.data.result[0].text_announcement)
                }
              } else {
                setOpenDialog(true);
                setMessage(
                  "ไม่มีสิทธิ์ในการเข้าถึงข้อสอบ เนื่องจากยังไม่ได้ซื้อข้อสอบชุดนี้"
                );
              }
            });

    }
  }, [user]);

  return (
    <>
      <ExamAlertDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        message={message}
        exam_slug={exam_slug}
      />

      <Box
        sx={{
          background: "#f9fbe7",
          minHeight: "100vh",
        }}
      >
        {loading === true ? (
          <>
            {permissionRound === true ? (
              <>
                <ExamComponent 
                exam={exam} 
                user={user} 
                // exam_id={exam_id} 
                exam_slug={exam_slug}
                />
              </>
              ) : 
                <>
                  <Appbar/>
                  <Dialog open={true} fullWidth={true} maxWidth={"md"} 
                    sx={{
                      "& .MuiDialog-paper": {
                      borderRadius:5,
                      paddingY:0.5
                    }}}
                    >
                    <DialogContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        
                      }}
                    >
                      <Box component="img" src={Calendar} width='200px' />
                      <Typography
                        mt={2}
                        align="center"
                        variant="h5"
                        component="div"
                        paddingTop={2}
                        color='#404b4a'
                        fontWeight={"800"}
                      >
                        {permissionAnnouncement}
                      </Typography>
                      <Typography
                        fontWeight={"500"}
                        sx={{
                          textAlign: "center",
                          fontSize:"1.1rem",
                          // variant:"h5",
                          color: "#404b4a",
                        }}
                      >
                        สามารถเข้าสอบผ่านอุปกรณ์ มือถือ แท็บเล็ต แล็ปท็อป หรือ คอมพิวเตอร์ ได้ทุกที่และทุกเวลา
                      </Typography>
                    </DialogContent>

                    <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        onClick={handleClose}
                        color="success"
                        sx={{ borderRadius: 3, 
                          width: "125px", 
                          backgroundColor:"#dba80dd9" ,
                          '&:hover': {
                            backgroundColor: "#db760dd9",
                          },
                      }}
                      >
                        <Typography sx={{ fontSize: "1rem" }}>ย้อนกลับ</Typography>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
            }
          </>
        ) : null}
      </Box>
    </>
  );
}

export default Exams;
