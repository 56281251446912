import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {
  CardActionArea,
  CardActions,
  Typography,
  Box,
  Button,
  Stack
} from '@mui/material'
import Axios from 'axios';
import { Link } from '@mui/material';
import { motion } from 'framer-motion'
import CampaignIcon from '@mui/icons-material/Campaign';

function Nevigatorguide({ user }) {

  const [myExamList, setMyExamList] = useState([])
  const [uniround, setUniround] = useState()
  const [announcement, setAnnouncement] = useState()

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    Axios.post("https://testapi.jkorpor.com/getUserExams", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      },
      user_id: user.user_id,
    }).then((res) => {
      setMyExamList(res.data);
      const roundArr =(res.data).map((exam) => exam.round_id);
      const uniround = Array.from(new Set(roundArr));
      const filteredUniround = uniround.filter((round) => round !== 1); // Developing
      setUniround(filteredUniround)
    });
  }, [])
  useEffect(() => {
    if (uniround) {
      const accessToken = localStorage.getItem("accessToken");
      Axios.post("https://testapi.jkorpor.com/getRound", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        uniround: uniround,
      }).then((res) => {
        setAnnouncement(res.data)
      });
    }
  }, [uniround]);

  return (
    <>
      <Box p={{ xs: 1, md: 2 }} m={{ xs: 1, md: 2 }} sx={{ borderRadius: 3, bgcolor: '#d6e8d4', boxShadow: 1 ,display: myExamList?.length === 0 ? 'none' : 'block',}}>
        <Box>
        <Stack direction="row" alignItems="center" gap={1} sx={{color:"#8BC34A"}}>
          <Typography sx={{fontSize:"1.2rem", fontWeight:"800"}}>ประกาศ</Typography>
          <CampaignIcon sx={{color:"#ff9800"}}/>
        </Stack>
        {announcement?.map((announce, key) => {
            return (
            <Typography key={key} sx={{ textAlign: 'left',marginLeft:'1rem',color:"#2c3639" }}>
                  {announce.text_announcement}
            </Typography>
            )
        })}
        </Box>
      </Box>
    </>
  )
}

export default Nevigatorguide