import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop';

function CircleLoading() {
    return (
        <Backdrop
        sx={{ color: '#fff', zIndex: 100000 ,backdropFilter:'blur(3px)'}}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={60} thickness={4} sx={{ p: 2 }} />
        </Box>
        </Backdrop>
    )
}

export default CircleLoading