import React from 'react'
import HomeBanner from '../components/home-components/HomeBanner'
import Appbar from '../components/AppbarRmBottom'
import TrialPageContentLOCAL from './TrialPageContentLOCAL'
import Footer from '../components/home-components/Footer'
import Box from '@mui/material/Box'

function TrialPageLOCAL() {
  return (
    <>
      <Appbar 
      />
      <TrialPageContentLOCAL/>
      <Box sx={{display:{ xs: 'none',  sm: 'none', md: 'block' }}}>
            <Footer />
      </Box>
    </>
  )
}

export default TrialPageLOCAL