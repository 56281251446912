import React, { useState, useEffect } from "react";
import Axios from "axios";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { motion } from "framer-motion";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import { Typography, Box, Stack, Button } from "@mui/material";
import LineModalPaid from './LineModalPaid';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={1} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#ffba0025",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ProfilePayment({ user }) {
  const [paymentHistory, setPaymentHistory] = useState();
  const [slipDetail, setSlipDetail] = useState();
  const [examList, setExamList] = useState();

  const [openLine, setOpenLine] = useState(false);

  const [linePaidData, setLinePaidData] = useState(false);
  const [userHasExam, setUserHasExam] = useState(false);

  const handleCloseLine = () => {
    setOpenLine(false);
  };

  useEffect(() => {
    // console.log('userHasExam,linePaidData',userHasExam,linePaidData);
    if (userHasExam && linePaidData) {
      setOpenLine(true)
    }
  }, [userHasExam,linePaidData]);
  

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
        // Axios.post("https://testapi.jkorpor.com/paymentHistory", {
        // headers: {
        //     "Content-Type": "application/json",
        //     Authorization: "Bearer " + accessToken,
        //     },
        //     user_id: user.user_id
        // }).then((res) => {
        //     setPaymentHistory(res.data);
        // });
        Axios.get(`https://testapi.jkorpor.com/getAllExam/`
        ).then((res) => {
          setExamList(res.data)
          console.log(res.data);
        })

        Axios.post("https://testapi.jkorpor.com/getSlip", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          user_id: user.user_id,
        }).then((res) => {
          setSlipDetail(res.data);
          console.log('res.data',res.data);
        });
  }, []);

  useEffect(() => {
    const userHasExam = false
    const accessToken = localStorage.getItem('accessToken')
    Axios.post("https://testapi.jkorpor.com/getUserExamsPaid", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      },
      user_id: user.user_id,
    }).then((res) => {
      // setMyExamList(res.data);
      if (res.data.message == 'pass') {
        setUserHasExam(true)
      }
    });

    Axios.post("https://testapi.jkorpor.com/getLinePaid", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      },
      user_id: user.user_id,
    }).then((res) => {
      if (res.data.message == 'pass') {
        setLinePaidData(true)
      }
    });
  }, [])

  
  const getNameExam = (val) => {
    return (examList.filter((exam) => exam.exam_id === val))[0].name
  };


  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const BGColor = (value) => {
    switch (value) {
      case "รออนุมัติ":
        return "#7abfe3";
      case "อนุมัติแล้ว":
        return "#66bb6a";
      case "active":
        return "#66bb6a";
      case "ไม่อนุมัติ":
        return "#f44336";
      case "canceled":
        return "#9f9f9f";
      default:
        return "#ffffff";
    }
  };
  const BackGroundColor = (value) => {
    switch (value) {
      case "รออนุมัติ":
        return "#a0deff";
      case "อนุมัติแล้ว":
        return "#c8e9de";
      case "active":
        return "#bad9b8";
      case "expired":
        return "#ffbeb0";
      case "canceled":
        return "#ffffff";
      default:
        return "#ffffff";
    }
  };
  const rePaymentForm = (reference, productdetail, netAmount) => {
    if (user) {
      const productDetail = "ข้อสอบ " + JSON.parse(productdetail).join(", ");
      const form = document.createElement("form");
      form.method = "post";
      form.action = "https://www.thaiepay.com/epaylink/payment.aspx";

      const refnoInput = document.createElement("input");
      refnoInput.type = "hidden";
      refnoInput.name = "refno";
      refnoInput.value = reference.split("trxn-")[1];
      form.appendChild(refnoInput);

      const merchantIdInput = document.createElement("input");
      merchantIdInput.type = "hidden";
      merchantIdInput.name = "merchantid";
      merchantIdInput.value = "08342459";
      form.appendChild(merchantIdInput);

      const customerEmailInput = document.createElement("input");
      customerEmailInput.type = "hidden";
      customerEmailInput.name = "customeremail";
      customerEmailInput.value = user.email;
      form.appendChild(customerEmailInput);

      const ccInput = document.createElement("input");
      ccInput.type = "hidden";
      ccInput.name = "cc";
      ccInput.value = "00";
      form.appendChild(ccInput);

      const productDetailInput = document.createElement("input");
      productDetailInput.type = "hidden";
      productDetailInput.name = "productdetail";
      productDetailInput.value = productDetail;
      form.appendChild(productDetailInput);

      const totalInput = document.createElement("input");
      totalInput.type = "hidden";
      totalInput.name = "total";
      totalInput.value = netAmount;
      form.appendChild(totalInput);
      document.body.appendChild(form);
      form.submit();
    }
  };
  useEffect(() => {
    if (paymentHistory) {
      const check_index = paymentHistory.findIndex(
        (item) => item.status === "pending"
      );
      if (check_index !== -1) {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post("https://testapi.jkorpor.com/request-subscription", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          user_id: user.user_id,
          reference_no: paymentHistory[check_index]["transaction"].split("trxn-")[1],
          payment_id: paymentHistory[check_index]["payment_id"],
          exams_data : JSON.parse(paymentHistory[check_index]["exams_data"])

        }).then((res) => {
          if (res.data.message === "reload") {
            window.location.reload();
          }
        });
      }
    }
  }, [paymentHistory]);

  return (
    <>
      <LineModalPaid openLine={openLine} user={user}/>
      <Box m={2}>
        <Typography
          sx={{
            display: "inline",
            fontSize: "1.5rem",
            borderBottom: "4px solid #ffba00",
          }}
        >
          การชำระเงิน
        </Typography>
      </Box>

      <Box
        p={2}
        m={2}
        sx={{ borderRadius: 3, bgcolor: "white", boxShadow: 1, minHeight: 300 }}
      >
        {paymentHistory?.length === 0 ? (
          <Typography sx={{ textAlign: "center", fontSize: "1.3rem" }}>
            ไม่พบประวัติการชำระเงิน
          </Typography>
        ) : null}
           <Container maxWidth={false} disableGutters >
           <Stack paddingBottom={5} 
          >
        <Box
          component={motion.div}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          sx={{borderRadius: 3}}
        >
          {/* {paymentHistory?.map((val, key) => {
            // val.status == "pending" ?
            return (
              <Accordion
                expanded={expanded === `panel${key}`}
                onChange={handleChange(`panel${key}`)}
                sx={{borderRadius: 3}}
              >
                <AccordionSummary
                  sx={{ backgroundColor: `${BackGroundColor(val.status)}`,borderRadius: 3}}
                >
                  <Box sx={{flexDirection:{xs:"column",sm:"column" , md:"row", lg:"row"}}}>
                  <Chip
                    label={`รหัสอ้างอิง ${val.transaction}`}
                    sx={{
                      backgroundColor: "unset",
                      borderRadius: 3,
                      textTransform: "capitalize",
                      color: "black",
                      fontSize:"1rem",
                      paddingRight:{xs:"10px", sm:"50px", md:"90px", lg:"375px"},
                    }}
                  />
                  <Box
                    sx={{
                      textAlign: "Right",
                      marginLeft: "auto",
                    }}
                  >
                    <Chip
                      label={`วันที่ ${formatDate(val.paid_at)}`}
                      sx={{
                        backgroundColor: "unset",
                        borderRadius: 3,
                        textTransform: "capitalize",
                        color: "black",
                        fontSize:"1rem",
                      }}
                    />
                    <Chip
                      label={"สถานะ"}
                      sx={{
                        backgroundColor: "unset",
                        borderRadius: 3,
                        textTransform: "capitalize",
                        color: "black",
                        fontSize:"1rem"
                      }}
                    />
                    <Chip
                      label={val.status=='success'?' ชำระเงินสำเร็จ':'รอการชำระเงิน'}
                      sx={{
                        backgroundColor: `${BGColor(val.status)}`,
                        borderRadius: 3,
                        textTransform: "capitalize",
                        color: "white",
                        fontSize:"1rem"
                      }}
                    />
                    {val.status == "pending" ? (
                      <Button
                        onClick={() => {
                          rePaymentForm(
                            val.transaction,
                            val.exams,
                            val.net_amount
                          );
                        }}
                      >
                        <Chip
                      label={'ชำระเงินคลิก'}
                      sx={{
                        backgroundColor: '#ff9800',
                        borderRadius: 3,
                        textTransform: "capitalize",
                        color: "white",
                        fontSize:"1rem"
                      }}
                      />
                      </Button>
                    ) : null}
                  </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography align="center">รายการสินค้า</Typography>
                    <Box>
                      {JSON.parse(val.exams).map((val, key) => {
                        return (
                          <Typography key={key}>
                            {key + 1}. {val}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>

                  <Divider
                    variant="middle"
                    sx={{ my: 1, mx: 5, display: { md: "none" } }}
                  />

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>ช่องทางการชำระ</Typography>
                    <Typography>{val.payment_method}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>ราคา</Typography>
                    <Typography>{val.net_amount} บาท</Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {val.status == "pending" ? (
                      <Button
                        onClick={() => {
                          rePaymentForm(
                            val.transaction,
                            val.exams,
                            val.net_amount
                          );
                        }}
                        sx={{
                          backgroundColor: '#ff9800',
                          borderRadius: 3,
                          textTransform: "capitalize",
                          color: "white",
                          fontSize:"1rem"
                        }}
                      >
                        ชำระเงิน
                      </Button>
                    ) : (
                      null // <Button>ใบกำกับภาษี</Button>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })} */}

          {slipDetail?.map((val, key) => {
            // val.status == "pending" ?
            return (
              <Accordion
                expanded={expanded === `panel${key}`}
                onChange={handleChange(`panel${key}`)}
                sx={{ borderRadius: 3 }}
              >
                <AccordionSummary
                  sx={{
                    backgroundColor: `${BackGroundColor(val.status)}`,
                    borderRadius: 3,
                  }}
                >
                  <Box
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                    }}
                  >
                    {/* <Chip
                    label={`รหัสอ้างอิง ${val.transaction}`}
                    sx={{
                      backgroundColor: "unset",
                      borderRadius: 3,
                      textTransform: "capitalize",
                      color: "black",
                      fontSize:"1rem",
                      paddingRight:{xs:"10px", sm:"50px", md:"90px", lg:"375px"},
                    }}
                  /> */}

                    <Stack
                      direction="row"
                      justifyContent="center"
                      flexDirection={{
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        flex={6}
                      >
                        <Box>
                          <Chip
                            label={`วันที่ ${formatDate(val.upload_at)}`}
                            sx={{
                              backgroundColor: "unset",
                              borderRadius: 3,
                              textTransform: "capitalize",
                              color: "black",
                              fontSize: "1rem",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        flex={6}
                      >
                        <Chip
                          label={"สถานะ"}
                          sx={{
                            backgroundColor: "unset",
                            borderRadius: 3,
                            textTransform: "capitalize",
                            color: "black",
                            fontSize: "1rem",
                          }}
                        />
                        <Chip
                          label={val.status}
                          sx={{
                            backgroundColor: `${BGColor(val.status)}`,
                            borderRadius: 3,
                            textTransform: "capitalize",
                            color: "white",
                            fontSize: "1rem",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{marginLeft:"2rem"}}>
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography align="center">รายการ</Typography>
                    <Box>
                      {JSON.parse(val.exams).map((val, key) => {
                        return (
                          <Typography key={key}>
                            {key + 1}. {getNameExam(val)}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>

                  <Divider
                    variant="middle"
                    sx={{ my: 1, mx: 5, display: { md: "none" } }}
                  />
                  <Box
                    component="a"
                    href={val.image_src}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box
                      src={val.image_src}
                      component="img"
                      width={{ xs: "160px", sm: "180px", md: "200px" }}
                      sx={{
                        "&:hover": {
                          outline: "3px #FC2947 solid",
                        },
                      }}
                    />
                  </Box>
                    <Divider
                      variant="middle"
                      sx={{ my: 1, mx: 5, display: { md: "none" } }}
                    />
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>ราคาเต็ม</Typography>
                      <Typography>{val.amount} บาท</Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{color:"#F44336"}}>ส่วนลด</Typography>
                      <Typography sx={{color:"#F44336"}}>{(val.amount - val.net_amount)? (val.amount - val.net_amount): 0 } บาท</Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between"}}
                    >
                      <Typography sx={{fontWeight:"600",color:"#124f65"}}>ราคารวม</Typography>
                      <Typography sx={{fontWeight:"600",color:"#124f65"}}>{val.net_amount} บาท</Typography>
                    </Box>

                  {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {
                      val.status == "pending" ? (
                        <Button
                          // onClick={() => {
                          //   rePaymentForm(
                          //     val.transaction,
                          //     val.exams,
                          //     val.net_amount
                          //   );
                          // }}
                          sx={{
                            backgroundColor: "#ff9800",
                            borderRadius: 3,
                            textTransform: "capitalize",
                            color: "white",
                            fontSize: "1rem",
                          }}
                        >
                          ชำระเงิน
                        </Button>
                      ) : null // <Button>ใบกำกับภาษี</Button>
                    }
                  </Box> */}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default ProfilePayment;
