import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box'
import { Button, Typography } from '@mui/material'
import HomeFlickity from './HomeFlickity'
import officialpic from '../../asset/officialpic.png'
import sidepicMini from '../../asset/sidepic2.png'
import prop from '../../asset/prop1.png'
import { motion } from "framer-motion";
import { makeStyles } from '@mui/styles';
import Axios from "axios";



const useStyles = makeStyles((theme) => ({
    textGradient: {
        backgroundImage:
            "linear-gradient(180deg, rgba(255,255,255,1) 8%, rgba(255,186,0,1) 50%)",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        color: "transparent"
    }
}));

function HomeBanner() {

    const classes = useStyles();
    const [AuthToken, setAuthToken] = useState(null);

    const setActiveContent = () => {
        localStorage.setItem('ActiveContent', 'profile-myexam')
        window.location = '/profile'
      };
    

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken')
        Axios.post('https://testapi.jkorpor.com/authsignin', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        })
            .then((res) => {
                if (res.data.status === 'ok') {
                    setAuthToken(true)

                } else {
                    setAuthToken(false)
                }
            })
    }, [])

    return (
        <>
            <Box
                sx={{
                    background: 'linear-gradient(0deg, rgb(44 54 57) 0%, rgb(27 30 30) 100%)',
                    paddingBottom: '3rem'
                }}>
                <Box component={motion.div}
                    initial={{ y: -15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <Box component='img' src={officialpic} alt="designed by jcomp - Freepik.com"
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'flex' },
                            maxWidth: { xs: 'none', sm: 'none', md: '500px' },
                            position: 'absolute',
                            right: { md: '2%', lg: '12.5%' },

                        }}
                    />

                    {/* <Box component='img' src={prop}
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'flex' },
                            maxWidth: { xs: 'none', sm: 'none', md: '175px' },
                            position: 'absolute',
                            right: { md: '2%', lg: '11%' },
                        }}
                    /> */}

                </Box>

                <Box component={motion.div}
                    initial={{ x: 20, y: '-1.2em', opacity: 0 }}
                    animate={{ x: 0, y: '-1.2rem', opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <Box component='img' src={sidepicMini}
                        sx={{
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                            maxWidth: { xs: '250px', sm: '300px', md: 'none', lg: 'none' },
                            position: 'absolute',
                            right: '0',
                            top: '-1rem',
                        }}
                    />
                </Box>

                <Box
                    component={motion.div}
                    initial={{ x: -15, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.7 }}
                >
                    <Box
                        position='relative'
                        sx={{
                            display: 'flex', flexDirection: 'column',
                            pt: { xs: "25px", sm: "50px", lg: "50px" },
                            px: { xs: "5%", sm: "7%", lg: "15%" },

                        }}>
                        <Typography className={classes.textGradient}>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.2rem", sm: "1.75rem" },
                                    fontWeight: 600,
                                }}
                            >
                                สนามสอบจำลอง
                            </Typography>

                            <Typography
                                sx={{
                                    marginTop: '-1rem',
                                    fontSize: { xs: "3.3rem", sm: "5rem", md: '5.5rem' },
                                    fontWeight: 700,
                                }}
                            >
                                J KORPOR
                            </Typography>

                            <Typography
                                sx={{
                                    marginTop: { xs: '-1.5rem', sm: '-2.5rem' },
                                    // color: '#ffba00',
                                    fontSize: { xs: "3.3rem", sm: "5rem", md: '5.5rem' },
                                    fontWeight: 700

                                }}
                            >
                                TEST {new Date().getFullYear()}
                            </Typography>

                        </Typography>

                        <Typography color='#fff'
                            sx={{
                                fontSize: { xs: "1.3rem", sm: "2rem" },
                            }}
                        >
                            สนามจำลองการสอบ ก.พ. ออนไลน์ <br />
                            ที่ครบและรอบด้านที่สุดในประเทศไทย
                        </Typography>

                        <Typography color='#fff'
                            sx={{
                                fontSize: { xs: "1rem", sm: "1.2rem" },
                            }}
                        >
                            ให้ผู้สอบแข่งขันได้ฝึกการสอบเสมือนจริงเตรียมความพร้อมก่อนลงสนามสอบ
                        </Typography>

                        <Button
                            variant="contained"
                            color="error"
                            href= {AuthToken === true? ('/profile'): ('/register')}
                            onClick={setActiveContent}
                            sx={{
                                mt: '10px',
                                borderRadius: 5,
                                fontSize: '1.4rem',
                                width: { xs: '100%', sm: '50%' },
                                "&:hover": {
                                    transition: "transform .3s",
                                    transform: "scale(1.06)"
                                }
                            }}>
                           
                            {AuthToken === true? ( 
                            <Typography  sx={{
                                fontSize: { xs: "1rem", sm: "1.2rem",md:"1.3rem" },
                            }}>
                                {"เริ่มทำข้อสอบ"}
                            </Typography>): 
                            (<Typography>
                                {"สมัครสอบ"}
                            </Typography>)}
                        </Button>

                    </Box>
                </Box>

                <Box
                    component={motion.div}
                    initial={{ y: 15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                    mt={4}
                >
                    <HomeFlickity />
                </Box>
            </Box>

        </>
    )
}

export default HomeBanner