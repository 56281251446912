import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import HomeBanner from "../components/home-components/HomeBanner";
import Appbar from "../components/Appbar";
import Footer from "../components/home-components/Footer";
import Box from "@mui/material/Box";
import Flickity from 'react-flickity-component'
import { Button, Typography, Container } from '@mui/material'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  CardActionArea,
  CardActions,
} from "@mui/material";
import sidepic from '../asset/trial-test.png'
import sidepicMini from '../asset/sidepic2.png'
import prop from '../asset/prop1.png'
import { motion } from "framer-motion";
import { makeStyles } from '@mui/styles';
import Stack from "@mui/material/Stack";
import answerkey from "../asset/answerkey.png";
import analysis from "../asset/analysis.png";
import testblueprint from "../asset/testblueprint.png";
import countdowntimer from "../asset/countdowntimer.png";
import successful from "../asset/successful.png";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BallotIcon from "@mui/icons-material/Ballot";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import './ContentFlickity.css'

const useStyles = makeStyles((theme) => ({
  textGradient: {
      backgroundImage:
          "linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(0,170,180,1) 70%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      color: "transparent"
  }
}));

const flickityOptions = {
  initialIndex: 2,
  selectedAttraction: 0.01,
  friction: 0.15,
  contain: true,
  pageDots: false,
  wrapAround: true,
  //autoPlay: true,
  autoPlay: 3000, // Time interval
}

const trynowscroll = () => {
  document.getElementById("TryNow").scrollIntoView({
    behavior: "smooth",
  });
};

function TrialPageContentKORPOR() {
  const classes = useStyles();
  const [examList, setExamList] = useState([]);
  useEffect(() => {
      Axios.get(`https://testapi.jkorpor.com/getExamTrialKORPOR/`
      ).then((res) => {
        console.log(res.data);
        setExamList(res.data)
      })
  }, [])
  return (
    <>
      <Box
        sx={{
          background:
            "linear-gradient(0deg, rgba(1,83,82,1) 0%, rgba(0,12,38,1) 100%)",
          paddingBottom: "1rem",
        }}
      >
        <Box
          component={motion.div}
          initial={{ y: -15, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Box
            component="img"
            src={sidepic}
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              maxWidth: { xs: "none", sm: "none", md: "550px" },
              position: "absolute",
              right: { md: "2%", lg: "17.5%" },
              top: { md: "2%", lg: "15%" },
            }}
          />

          <Box
            component="img"
            src={prop}
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              maxWidth: { xs: "none", sm: "none", md: "175px" },
              position: "absolute",
              right: { md: "2%", lg: "11%" },
            }}
          />
        </Box>

        <Box
          component={motion.div}
          initial={{ x: 20, y: "-1.2em", opacity: 0 }}
          animate={{ x: 0, y: "-1.2rem", opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Box
            component="img"
            src={sidepicMini}
            sx={{
              display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
              maxWidth: { xs: "250px", sm: "300px", md: "none", lg: "none" },
              position: "absolute",
              right: "0",
              top: "-1rem",
            }}
          />
        </Box>

        <Box
          component={motion.div}
          initial={{ x: -15, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.7 }}
        >
          <Box
            position="relative"
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: { xs: "25px", sm: "50px", lg: "50px" },
              px: { xs: "5%", sm: "7%", lg: "15%" },
            }}
          >
            <Typography className={classes.textGradient}>
              <Typography
                sx={{
                  fontSize: { xs: "1.2rem", sm: "1.75rem" },
                  fontWeight: 600,
                }}
              >
                สนามสอบจำลอง
              </Typography>

              <Typography
                sx={{
                  marginTop: "-1rem",
                  fontSize: { xs: "2.8rem", sm: "4.4rem", md: "5rem" },
                  fontWeight: 700,
                }}
              >
                J TCAS TEST
              </Typography>

              <Typography
                sx={{
                  marginTop: { xs: "-1.5rem", sm: "-2.5rem" },
                  // color: '#ffba00',
                  fontSize: { xs: "3.3rem", sm: "5rem", md: "5.5rem" },
                  fontWeight: 700,
                }}
              >
                {new Date().getFullYear()} ฟรี
              </Typography>
            </Typography>

            <Typography
              color="#fff"
              sx={{
                fontSize: { xs: "1.3rem", sm: "2rem" },
              }}
            >
              สนามจำลองสอบ TCAS และ NETSAT <br />
              ที่ครบและรอบด้านที่สุดในประเทศไทย
            </Typography>

            <Typography
              color="#fff"
              sx={{
                fontSize: { xs: "1rem", sm: "1.2rem" },
              }}
            >
              ให้น้องๆ ได้ฝึกการสอบเสมือนจริงเตรียมความพร้อมก่อนลงสนามสอบ
            </Typography>
            <Button
              variant="contained"
              // color="error"
              // href= {AuthToken === true? ('/profile'): ('/register')}
              onClick={() => trynowscroll()}
              sx={{
                mt: "10px",
                borderRadius: 5,
                fontSize: "1.4rem",
                width: { xs: "100%", sm: "50%" },
                "&:hover": {
                  transition: "transform .3s",
                  transform: "scale(1.06)",
                },
                backgroundColor: "#00BCC7",
                "&.MuiButtonBase-root.MuiButton-root:hover": {
                  backgroundColor: "#0098A1",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.3rem" },
                }}
              >
                {"ทดลองสอบฟรี"}
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box
          component={motion.div}
          initial={{ y: 15, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          mt={4}
        ></Box>
      </Box>
      <Box
        className="container"
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        sx={{
          background:
            "linear-gradient(0deg, rgba(1,83,82,0), rgba(75,75,75,0.05))",
        }}
      >
        
        <Container>
          <Box
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { y: 0, opacity: 1, scale: 1 },
              hidden: { y: 0, opacity: 0.5, scale: 0.95 },
            }}
          >
            <Typography
              gutterBottom
              component="div"
              paddingTop={5}
              paddingX={2}
              color="#0dbbc5"
              fontSize={'2rem'}
              fontWeight={"800"}
            >
              สนามจำลองการสอบ TCAS ออนไลน์
              <Box component="br" />
              By J Knowledge
            </Typography>
          </Box>
          <Stack
          direction="row"
          justifyContent="center"
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "row",
          }}
        >
          <Flickity
            className={"flickity-carousel"}
            elementType={"div"}
            options={flickityOptions}
            reloadOnUpdate
            static
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={3}
              maxWidth={'360px'}
            >
              <Stack
                direction="row"
                justifyContent="center"
                flexDirection={{ xs: "column", sm: "column", md: "column" }}
                paddingBottom={5}
              >
                <Box
                  className="content-t"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                >
                  <Box
                    component="img"
                    src={testblueprint}
                    sx={{
                      display: { xs: "flex", sm: "flex", md: "flex" },
                      maxWidth: { xs: "175px", sm: "200px", md: "150px" },
                      right: { md: "2%", lg: "10%" },
                    }}
                  />
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                  padding={2}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    โครงสร้างข้อสอบและความยาก-ง่ายข้อสอบ ใกล้เคียงกับข้อสอบจริง
                  </Typography>
                  <Typography gutterBottom component="div">
                    ข้อสอบ ออกโดยผู้เชี่ยวชาญ ด้วยประสบการณ์การสอน
                    และออกข้อสอบมากกว่า 20 ปี
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={3}
              maxWidth={'360px'}
            >
              <Stack
                direction="row"
                justifyContent="center"
                flexDirection={{ xs: "column", sm: "column", md: "column" }}
                paddingBottom={5}
              >
                <Box
                  className="content-t"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                >
                  <Box
                    component="img"
                    src={countdowntimer}
                    sx={{
                      display: { xs: "flex", sm: "flex", md: "flex" },
                      maxWidth: { xs: "175px", sm: "200px", md: "150px" },
                      right: { md: "2%", lg: "10%" },
                    }}
                  />
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                  padding={2}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    ระบบจับเวลา ส่งคำตอบอัตโนมัติเมื่อหมดเวลา
                  </Typography>
                  <Typography gutterBottom component="div">
                    นับถอยหลังแบบ Real-Time และส่งคำตอบอัตโนมัติเมื่อหมดเวลา
                    เวลาตรงตามโครงสร้างข้อสอบจริง ทุกวิชา
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={3}
              maxWidth={'360px'}
            >
              <Stack
                direction="row"
                justifyContent="center"
                flexDirection={{ xs: "column", sm: "column", md: "column" }}
                paddingBottom={5}
              >
                <Box
                  className="content-t"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                >
                  <Box
                    component="img"
                    src={answerkey}
                    sx={{
                      display: { xs: "flex", sm: "flex", md: "flex" },
                      maxWidth: { xs: "175px", sm: "200px", md: "150px" },
                      right: { md: "2%", lg: "10%" },
                    }}
                  />
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                  padding={2}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    ระบบตรวจคำตอบ แสดงผลสอบพร้อมเฉลย
                  </Typography>
                  <Typography gutterBottom component="div">
                    ตรวจคำตอบทันทีหลังส่งข้อสอบ สรุปคะแนนที่ได้
                    พร้อมแสดงผลการทำข้อสอบ เฉลยรายข้อ และวิดิโอเฉลยอย่างละเอียด
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={3}
              maxWidth={'360px'}
            >
              <Stack
                direction="row"
                justifyContent="center"
                flexDirection={{ xs: "column", sm: "column", md: "column" }}
                paddingBottom={5}
              >
                <Box
                  className="content-t"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                >
                  <Box
                    component="img"
                    src={analysis}
                    sx={{
                      display: { xs: "flex", sm: "flex", md: "flex" },
                      maxWidth: { xs: "175px", sm: "200px", md: "150px" },
                      right: { md: "2%", lg: "10%" },
                    }}
                  />
                </Box>
                <Box
                  className="content-b"
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  flex={6}
                  padding={2}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    การวิเคราะห์ผลคะแนน ลบจุดอ่อน เสริมจุดแข็ง
                  </Typography>
                  <Typography gutterBottom component="div">
                    ด้วยกราฟเรดาร์ (Radar Chart) แสดงผลคะแนนแยกด้าน รู้จุดอ่อน
                    จุดเด่นของการสอบในแต่ละครั้ง
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Flickity>
        </Stack>
        </Container>

        <Container>
          <Stack
            direction="row"
            justifyContent="center"
            flexDirection={{
              xs: "column",
              sm: "column",
              md: "row",
            }}
            paddingBottom={5}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={6}
            >
              <Box
                className="content-l"
                component={motion.div}
                initial="hidden"
                whileInView="visible"
                // animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                variants={{
                  visible: { x: 16, opacity: 1, scale: 1 },
                  hidden: { x: 0, opacity: 0.5, scale: 1 },
                }}
              >
                <Box
                  component="img"
                  src={successful}
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    maxWidth: { xs: "250px", sm: "300px", md: "350px" },
                    right: { md: "2%", lg: "10%" },
                  }}
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
              flex={6}
            >
              <Box className="content-r">
                <Typography
                  gutterBottom
                  fontSize={'2rem'}
                  component="div"
                  color="#0dbbc5"
                  fontWeight={"800"}
                >
                  ช่วยเพิ่มโอกาส…สอบติด
                </Typography>
                <Typography gutterBottom component="div">
                  สอบเสมือนจริง เตรียมความพร้อมก่อนลงสนามสอบทดสอบได้
                  ไม่จำกัดจำนวนครั้ง ในช่วงเวลาที่กำหนด
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  flexDirection={{ xs: "column", sm: "column", md: "column" }}
                  paddingBottom={5}
                >
                  <Box marginLeft={3}>
                    <Box
                      className="content-b"
                      display={"flex"}
                      justifyContent={"left"}
                      alignItems={"left"}
                      flexDirection={"row"}
                      flex={6}
                      sx={{
                        backgroundColor: "#00968838",
                        borderRadius: 2,
                        padding: 1,
                        margin: 1,
                      }}
                      component={motion.div}
                      initial="hidden"
                      whileInView="visible"
                      // animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.8, delay: 0 }}
                      variants={{
                        visible: { y: 16, opacity: 1, scale: 1 },
                        hidden: { x: -16, opacity: 0.5, scale: 1 },
                      }}
                    >
                      <ApartmentIcon
                        sx={{
                          display: "flex",
                          color: "#000000",
                          paddingRight: 1,
                        }}
                      />
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        sx={{ marginBottom: 0 }}
                      >
                        ช่วยวิเคราะห์ผลคะแนนได้ตรงและแม่นยำ
                      </Typography>
                    </Box>
                    <Box
                      className="content-b"
                      display={"flex"}
                      justifyContent={"left"}
                      flexDirection={"row"}
                      alignItems={"left"}
                      flex={6}
                      sx={{
                        backgroundColor: "#00968838",
                        borderRadius: 2,
                        padding: 1,
                        margin: 1,
                      }}
                      component={motion.div}
                      initial="hidden"
                      whileInView="visible"
                      // animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.8, delay: 0.1 }}
                      variants={{
                        visible: { y: 16, opacity: 1, scale: 1 },
                        hidden: { x: -16, opacity: 0.5, scale: 1 },
                      }}
                    >
                      <AccessAlarmIcon
                        sx={{
                          display: "flex",
                          color: "#000000",
                          paddingRight: 1,
                        }}
                      />
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        sx={{ marginBottom: 0,textAlign:"left"}}
                      >
                        สามารถนำผลสอบ เทียบกับคะแนนสอบปีล่าสุดกับคณะ สาขาที่สนใจ
                        วิเคราะห์แนวโน้มคะแนนและโอกาสสอบติด
                      </Typography>
                    </Box>
                    <Box
                      className="content-b"
                      display={"flex"}
                      justifyContent={"left"}
                      flexDirection={"row"}
                      alignItems={"left"}
                      flex={6}
                      sx={{
                        backgroundColor: "#00968838",
                        borderRadius: 2,
                        padding: 1,
                        margin: 1,
                      }}
                      component={motion.div}
                      initial="hidden"
                      whileInView="visible"
                      // animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.8, delay: 0.2 }}
                      variants={{
                        visible: { y: 16, opacity: 1, scale: 1 },
                        hidden: { x: -16, opacity: 0.5, scale: 1 },
                      }}
                    >
                      <BallotIcon
                        sx={{
                          display: "flex",
                          color: "#000000",
                          paddingRight: 1,
                        }}
                      />
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        sx={{ marginBottom: 0 }}
                      >
                        วางแผนการเตรียมตัว อ่านหนังสือได้ตรงจุด
                      </Typography>
                    </Box>
                    <Box
                      className="content-b"
                      display={"flex"}
                      justifyContent={"left"}
                      flexDirection={"row"}
                      alignItems={"left"}
                      flex={6}
                      sx={{
                        backgroundColor: "#00968838",
                        borderRadius: 2,
                        padding: 1,
                        margin: 1,
                      }}
                      component={motion.div}
                      initial="hidden"
                      whileInView="visible"
                      // animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.8, delay: 0.3 }}
                      variants={{
                        visible: { y: 16, opacity: 1, scale: 1 },
                        hidden: { x: -16, opacity: 0.5, scale: 1 },
                      }}
                    >
                      <ListAltIcon
                        sx={{
                          display: "flex",
                          color: "#000000",
                          paddingRight: 1,
                        }}
                      />
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        sx={{ marginBottom: 0 }}
                      >
                        เพิ่มประสบการณ์ เพิ่มความมั่นใจ ในการทำข้อสอบมากยิ่งขึ้น
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Container>
        
        <Container>
        <Box
            component={motion.div}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { y: 0, opacity: 1, scale: 1 },
              hidden: { y: 0, opacity: 0.5, scale: 0.95 },
            }}
          >
            <Typography
              gutterBottom
              component="div"
              id={"TryNow"}
              paddingTop={1}
              paddingX={2}
              color="#0dbbc5"
              fontSize={'2rem'}
              fontWeight={"800"}
            >
              ทดลองสอบฟรี
            </Typography>
          </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: { xs: 1, md: 2 },
            marginBottom: "80px",
            marginTop: 2,
            justifyContent:"center"
          }}
        >
          {examList?.map((val, key) => {
            return (
              <Box
                component={motion.div}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}>
                <Card
                  sx={{ width: { xs: 160, md: 175, },height: { xs: 240, md: 250, }, borderRadius: 7 }}
                  key={key}
                >
                  <CardActionArea href={`/trial-tests/${val.exam_slug}`}>
                    <CardMedia
                      component="img"
                      height="70px"
                      image={val.pic}
                      alt={val.name}
                    />
                    <CardContent sx={{paddingY:"0.3rem", height:"100px"}}>
                      <Typography
                        //noWrap
                        sx={{ fontSize: "1.1rem", fontWeight: 600 }}
                      >
                        {val.name}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{
                          height: "20px",
                          fontSize: ".85rem",
                        }}
                      >
                        {val.detail}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Stack spacing={1}>

                      <Button
                        variant="contained"
                        color="warning"
                        sx={{
                          borderRadius: 3,
                          fontSize: { xs: ".8rem", md: "" },
                          padding:"0.3rem"
                        }}
                        href={`/trial-tests/${val.exam_slug}`}
                        // onClick={() => handleAnalysis(val.exam_id, val.name)}
                      >
                        ทดลองสอบฟรี
                      </Button>
                    </Stack>
                  </CardActions>
                </Card>
              </Box>
            );
          })}
        </Box>
        </Container>
        
      </Box>
    </>
  );
}

export default TrialPageContentKORPOR;
