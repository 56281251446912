import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: "'Prompt', sans-serif",
    },
    mainColor: {
        main: '#7ad2d4',
        second: '#ffba00'
    },
    palette: {
        secondary: {
            main: '#ffba00',
        },
        warningOrange: {
            main: '#f57c00'
        },
        greenNeon: {
            main: '#ffba00'
        },
        backButton: {
            main: '#0081B4'
        },
        greyButton: {
            main: '#D8D8D8'
        }
    },

});

export default theme